import DonutChart from 'charts/donut/DonutChart';
import ChartContainer from '../ChartContainer';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import { getViewsByCategory } from 'analytics/googleAnalytics/api';
import './ViewsByCategory.scss';
import groupBy from "lodash/groupBy";

const prepareData = data => {
  const rows = data.rows;
  const groupedByContentType = Object.entries(groupBy(rows, 'dimensionValues.1.value'));

  return groupedByContentType.map(([label, values]) => ({
    label: label,
    value: values.reduce((sum, cur) => sum + +cur.metricValues[0].value, 0),
  }));
};

const ViewsByCategory = () => {
  return (
    <ChartContainer title="Views by category" api={getViewsByCategory} dataAlter={prepareData}>
      <ResponsiveContainer>
        <DonutChart />
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default ViewsByCategory;
