import { arc, pie } from 'd3-shape';
import { useIncludedPatientsData } from './utils';
import NoDataDonut from './NoDataDonut';

const height = 200;
const margin = { top: 0, right: 0, bottom: 0, left: 0 };
const pieGenerator = pie()
  .value(d => d.value)
  .sort(a => (a.key === 'unique' ? -1 : 1));

const Chart = ({ patients }) => {
  const side = height;
  const canvas = { width: side - margin.right - margin.left, height: side - margin.bottom - margin.top };
  const center = [canvas.width / 2 + margin.left, canvas.height / 2 + margin.top];
  const outerRadius = Math.min(canvas.width, canvas.height) / 2;
  const innerRadius = outerRadius * 0.45;

  if (patients.total < 1) {
    return <NoDataDonut center={center} outerRadius={outerRadius} innerRadius={innerRadius} side={side} />;
  }

  const data = [
    { key: 'total', value: patients.total - patients.unique },
    { key: 'unique', value: patients.unique },
  ];
  const arcs = pieGenerator(data);
  const arcGenerator = arc().innerRadius(innerRadius).outerRadius(outerRadius).cornerRadius(4).padAngle(0.02);
  const uniqueLabel = arcGenerator.centroid(arcs[1]);

  return (
    <svg width={side} height={side}>
      <g className="sectors">
        {arcs.map(a => (
          <path
            d={arcGenerator(a)}
            transform={`translate(${center[0]},${center[1]})`}
            className={`sector sector-${a.data.key}`}
            key={a.data.key}
          >
            <title>{a.data.key}</title>
          </path>
        ))}
      </g>
      <text className="label label-total-patients-value" x={center[0]} y={center[1]}>
        {patients.total}
      </text>
      <text className="label label-total-patients-text" x={center[0]} y={center[1] + 3}>
        Patients
      </text>
      {patients.unique > 0 && (
        <g className="sector-labels labels labels-unique">
          <text
            className="label label-value"
            x={uniqueLabel[0]}
            y={uniqueLabel[1]}
            transform={`translate(${center[0]},${center[1]})`}
          >
            {patients.unique}
          </text>
          <text
            className="label label-percents"
            x={uniqueLabel[0]}
            y={uniqueLabel[1] + 3}
            transform={`translate(${center[0]},${center[1]})`}
          >
            {patients.unique_percents}%
          </text>
        </g>
      )}
    </svg>
  );
};

const IncludedPatients = () => {
  const patients = useIncludedPatientsData();

  return (
    <div className="chart step included-patients">
      <h5 className="chart-title">Included patients</h5>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', width: height }}>
        <Chart patients={patients} />
      </div>
      <div className="legend mt-3">
        <table className="table table-hover table-sm caption-top">
          <thead>
            <tr>
              <th className="text-center">Color</th>
              <th>Name</th>
              <th>Value</th>
              <th>Percent</th>
            </tr>
          </thead>
          <tbody className="table-light">
            <tr className="legend-item legend-unique-included-patients">
              <td className="text-center">
                <span className="item-color" />
              </td>
              <td className="item-name">Unique patients</td>
              <td className="item-value">{patients.unique}</td>
              <td className="item-percent">{patients.unique_percents}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IncludedPatients;
