import { backend } from 'api/clients';

export const getFeedbackItems = () => backend.get('api/v1/feedback/list?_format=json');

export const getFeedbackItem = id => backend.get(`feedback/${id}?_format=json`);

export const getFeedbackImage = url => {
  const { pathname } = new URL(url);
  return backend.get(pathname, { responseType: 'blob' });
};

export const changeFeedbackStatus = (id, status) => {
  return backend.patch(`feedback/${id}?_format=json`, { status: [status] });
};
