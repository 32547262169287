import { Navigate, Outlet } from 'react-router-dom';
import { useHasAccess } from 'user/context/Session';
import SideMenu from './sideMenu/SideMenu';
import Directory from './directory/Directory';
import PermanentLinks from './links/PermanentLinks';
import './healthDrive.scss';

export const healthDriveConfig = {
  ROOT_PATH: '/files',
  ACCESS: ['health_drive'],
};

export const healthDriveRoutes = [
  {
    index: true,
    element: <Navigate to="my" />,
  },
  {
    path: 'my/*',
    element: <Directory />,
  },
  {
    path: 'spaces/*',
    element: <Directory />,
  },
  {
    path: 'shared/*',
    element: <Directory />,
  },
  {
    path: 'links/permanent',
    element: <PermanentLinks />,
  },
];

const HealthDrive = () => {
  const isUserHasAccess = useHasAccess('health_drive');

  if (!isUserHasAccess) {
    throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
  }

  return (
    <div className="row">
      <div className="col-3">
        <SideMenu />
      </div>
      <div className="col-9">
        <Outlet />
      </div>
    </div>
  );
};

export default HealthDrive;
