import orderBy from 'lodash/orderBy';
import { useTimeframeContext } from '../TimeframeContext';
import { getReducedData } from '../utils';
import { getPercent } from './utils';

const names = {
  review_and_feedback: 'Review and feedback',
  ab_more_than_14_days: 'AB more than 14 days',
  possible_severe_infection: 'Possible severe infections',
  reserved_antibiotics_alert: 'Reserved antibiotics alert',
  s_aureus_bacteremia: 'S. Aureus bacteremia',
  aminoglycosides_alert: 'Aminoglycosides alert',
  iv_switch: 'IV switch',
  glycopeptides_alert: 'Glycopeptides_alert',
};

const AlertsType = () => {
  const { data } = useTimeframeContext();
  const report = getReducedData(data);
  const totalSuspicious = report.suspicious;
  const alerts = Object.entries(report.types_of_alerts);
  const orderedAlerts = orderBy(alerts, ['1', '0'], ['desc', 'asc']).map(a => [
    ...a,
    getPercent(a[1], totalSuspicious),
  ]);

  return (
    <div className="chart alerts-type">
      <h5 className="chart-title">Alerts type</h5>
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Alert</th>
                <th>Patients</th>
                <th>Percent</th>
              </tr>
            </thead>
            <tbody className="table-light">
              {orderedAlerts.map(([key, value, percent], index) => (
                <tr style={{ opacity: value > 0 ? '1' : '0.4' }} key={key}>
                  <td>{index + 1}.</td>
                  <td>{names[key] ?? key}</td>
                  <td>{value}</td>
                  <td>{percent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AlertsType;
