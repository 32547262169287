import { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import kebabCase from 'lodash/kebabCase';
import './DonutChart.scss';

const height = 200;
const margin = {
  top: 0,
  right: 15,
  bottom: 15,
  left: 15,
};
const innerRadius = 30;

const DonutChart = ({ container, data }) => {
  const { width } = container;
  const donutGroupRef = useRef();
  const minDimension = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom);
  const outerRadius = minDimension / 2;

  const pieGenerator = pie().value(d => d.value);
  const arcs = pieGenerator(data);

  useEffect(() => {
    const donutGroup = donutGroupRef.current;
    if (!donutGroup) return;

    const arcGenerator = arc().innerRadius(innerRadius).outerRadius(outerRadius).cornerRadius(5).padAngle(0.01);

    select(donutGroup)
      .selectAll('.sector')
      .data(arcs)
      .join('path')
      .attr('d', arcGenerator)
      .attr('class', d => `sector ${kebabCase(d.data.label)}`);

    select(donutGroup)
      .selectAll('.arc-label')
      .data(arcs)
      .join('text')
      .attr('class', 'arc-label')
      .attr('x', d => arcGenerator.centroid(d)[0])
      .attr('y', d => arcGenerator.centroid(d)[1])
      .text(d => {
        const centroidArcLenght = (d.endAngle - d.startAngle) * ((outerRadius - innerRadius) / 2);
        if (centroidArcLenght < 12) return '';
        return d.value;
      });
  }, [arcs, outerRadius, donutGroupRef]);

  return (
    <div className="donut-chart">
      <svg width={width} height={height}>
        <g ref={donutGroupRef} transform={`translate(${width / 2} ${height / 2})`}></g>
      </svg>
      <div className="legend">
        {data.map(item => (
          <span className={`legend-item ${kebabCase(item.label)}`} key={item.label}>
            <span className="item-color"></span>
            <span className="item-name">{item.label}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DonutChart;
