import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useRequest } from 'utility/hooks';
import { getTokens } from './api';
import { saveTokens, addTimestamps } from './context/Tokens';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const UserToken = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const code = urlParams.get('code');
  const { isLoading, error, data } = useRequest(getTokens, [code], { execute: !!code });

  useEffect(() => {
    if (!data) return;
    saveTokens(addTimestamps(data));
    // Use redirect with reload to initiate user data refetching.
    // Maybe there is a more nice solution, but for now it works perfectly.
    window.location.replace('/');
  }, [data]);

  if (isLoading) return <IndicatorWithText text="Loading tokens..." />;
  if (error)
    return (
      <div>
        Error! <Link to="/user/login">Go to login page.</Link>
      </div>
    );

  return null;
};

export default UserToken;
