import orderBy from 'lodash/orderBy';
import { useTimeframeContext } from '../TimeframeContext';
import { getReducedData } from '../utils';
import { getPercent } from './utils';

const names = {
  other: 'Other',
  stopMedication: 'Stop medication',
  requestForStopDate: 'Request for stop date',
  changeMedication: 'Change medication',
  requestDiagnostics: 'Request diagnostics',
  changeDosageOrFrequency: 'Change dosage or frequency',
  startNewMedication: 'Start new medication',
  requestDiagnosticsForSerumLevel: 'Request diagnostics for serum level',
};

const AdjustmentsType = () => {
  const { data } = useTimeframeContext();
  const report = getReducedData(data);
  const totalSuspicious = report.changed_strategy;
  const adjustments = Object.entries(report.types_of_adjustment);
  const orderedAdjustments = orderBy(adjustments, ['1', '0'], ['desc', 'asc']).map(a => [
    ...a,
    getPercent(a[1], totalSuspicious),
  ]);

  return (
    <div className="chart adjustments-type">
      <h5 className="chart-title">Medication adjustments type</h5>
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Adjustment</th>
                <th>Patients</th>
                <th>Percent</th>
              </tr>
            </thead>
            <tbody className="table-light">
              {orderedAdjustments.map(([key, value, percent], index) => (
                <tr style={{ opacity: value > 0 ? '1' : '0.4' }} key={key}>
                  <td>{index + 1}.</td>
                  <td>{names[key] ?? key}</td>
                  <td>{value}</td>
                  <td>{percent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentsType;
