import { useRef, useEffect } from 'react';
import groupBy from 'lodash/groupBy';
import kebabCase from 'lodash/kebabCase';
import { select } from 'd3-selection';
import { format } from 'd3-format';
import { arc, pie } from 'd3-shape';
import ResponsiveContainer from 'idmReports/chartParts/responsiveContainer/responsiveContainer';
import { useDataInFrame } from '../DataInFrameProvider';
import { useInFocus } from '../InFocusProvider';
import { useTooltip } from 'idmReports/tooltip/Tooltip';
import './virusesRatio.scss';

const height = 280;
const margin = {
  top: 15,
  right: 15,
  bottom: 15,
  left: 15,
};

const Tooltip = ({ data }) => (
  <section className="report-tooltip">
    <table className="table table-sm m-0">
      <tbody>
        <tr>
          <th scope="row" className="px-3">
            Virus
          </th>
          <td className="pe-3">
            <span className={`legend-mark ${kebabCase(data.data.name)}`} />
            {data.data.name}
          </td>
        </tr>
        <tr>
          <th scope="row" className="px-3">
            Orders
          </th>
          <td className="pe-3">
            {data.value} ({format('.2%')(data.value / data.sum)})
          </td>
        </tr>
      </tbody>
    </table>
  </section>
);

const Chart = ({ container }) => {
  const data = useDataInFrame();
  const { setContent, setVisibility, setCoords } = useTooltip();
  const [, setFocus] = useInFocus();
  const donutRef = useRef();
  const { width } = container;
  const innerRadius = 50;
  const minDimension = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom);
  const outerRadius = minDimension / 2;

  const flatData = data.flatMap(r => r.groups.map(i => ({ name: i.key, value: i.value })));
  const grouped = groupBy(flatData, 'name');
  const results = [];

  for (const virus in grouped) {
    results.push({
      name: virus,
      value: grouped[virus].reduce((sum, cur) => sum + cur.value, 0),
    });
  }

  const sum = results.reduce((acc, cur) => acc + cur.value, 0);
  const getPercentage = v => format('.0%')(v / sum);

  const pieGenerator = pie()
    .value(d => d.value)
    .sort((a, b) => a.name.localeCompare(b.name));
  const arcs = pieGenerator(results);

  useEffect(() => {
    const donut = donutRef.current;
    if (!donut) return;

    const arcGenerator = arc().innerRadius(innerRadius).outerRadius(outerRadius).cornerRadius(5).padAngle(0.01);

    select(donut)
      .selectAll('.sector')
      .data(arcs)
      .join('path')
      .attr('d', arcGenerator)
      .attr('class', d => `sector ${kebabCase(d.data.name)}`)
      .on('mouseover', (e, d) => {
        setFocus(d.data.name);
        setVisibility(true);
        setCoords({ x: e.pageX, y: e.pageY });
        setContent(<Tooltip data={{ ...d, sum }} />);
      })
      .on('mousemove', e => {
        setCoords({ x: e.pageX, y: e.pageY });
      })
      .on('mouseout', () => {
        setFocus(null);
        setVisibility(false);
      });

    select(donut)
      .selectAll('.arc-label')
      .data(arcs)
      .join('text')
      .attr('class', 'arc-label')
      .attr('x', d => arcGenerator.centroid(d)[0])
      .attr('y', d => arcGenerator.centroid(d)[1])
      .text(d => {
        const centroidArcLenght = (d.endAngle - d.startAngle) * ((outerRadius - innerRadius) / 2);
        if (centroidArcLenght < 8) return '';
        return getPercentage(d.value);
      });
  }, [donutRef, arcs, outerRadius, setFocus, setVisibility, setCoords, setContent]); // eslint-disable-line

  return (
    <svg className="chart viruses-ratio" width={width} height={height}>
      <g ref={donutRef} className="donut" transform={`translate(${width / 2} ${height / 2})`}></g>
      <text x={width / 2} y={height / 2} className="sum">
        Total: {sum}
      </text>
    </svg>
  );
};

const VirusesRatio = () => {
  return (
    <section className="mt-4">
      <h6>Viruses ratio:</h6>
      <div>
        <ResponsiveContainer>
          <Chart />
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default VirusesRatio;
