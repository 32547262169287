import { Outlet } from 'react-router-dom';
import { useHasAccess } from 'user/context/Session';
import Viruses from './viruses/Viruses';
import Patients from './patients/Patients';
import ReportsList from './ReportsList';

export const idmReportsRoutes = [
  {
    index: true,
    element: <ReportsList />,
  },
  {
    path: 'viruses',
    element: <Viruses />,
  },
  {
    path: 'patients',
    element: <Patients />,
  },
];

const IdmReports = () => {
  const isUserHasAccess = useHasAccess('idm_reports');

  if (!isUserHasAccess) {
    throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
  }

  return <Outlet />;
};

export default IdmReports;
