import TotalViews from './TotalViews';
import ViewsDynamics from './viewsDynamics/ViewsDynamics';
import ViewsByCategory from './viewsByCategory/ViewsByCategory';
import MostViewedInCategories from './MostViewedInCategories';
import ViewsByAdviceType from './viewsByAdviceType/ViewsByAdviceType';
import TopTherapySubcategories from './TopTherapySubcategories';
import TopProphylaxisSubcategories from './TopProphylaxisSubcategories';
import TopAdviceNodes from './TopAdviceNodes';
import TopPageNodes from './TopPageNodes';
import TopMedicationNodes from './TopMedicationNodes';

const Charts = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-4 mb-4">
          <TotalViews />
        </div>
        <div className="col-12 col-lg-8 mb-4">
          <ViewsDynamics />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4 mb-4">
          <ViewsByCategory />
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <MostViewedInCategories />
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <ViewsByAdviceType />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          <TopTherapySubcategories />
        </div>
        <div className="col-12 col-lg-6 mb-4">
          <TopProphylaxisSubcategories />
        </div>
      </div>
      <div className="row mb-5 pb-5">
        <div className="col-12 col-lg-4">
          <TopAdviceNodes />
        </div>
        <div className="col-12 col-lg-4">
          <TopPageNodes />
        </div>
        <div className="col-12 col-lg-4">
          <TopMedicationNodes />
        </div>
      </div>
    </>
  );
};

export default Charts;
