import { useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toastr from 'toastr';
import { useRemotePath } from 'healthDrive/hooks';
import { createDirectory } from 'healthDrive/api';

const CreateDirectoryButton = ({ className = '' }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const path = useRemotePath();
  const queryClient = useQueryClient();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const createDirectoryMutation = useMutation(({ path, dirName }) => createDirectory(path, dirName), {
    onSuccess: (_, args) => {
      queryClient.invalidateQueries(['listDirectory', args.path]);
      toastr.success('Directory has been created.');
      closeModal();
    },
    onError: (error, variables) => {
      toastr.error('Directory creation failed.');
      console.dir(error);
      closeModal();
    },
  });

  const onFormSubmit = ev => {
    ev.preventDefault();
    const dirName = ev.target.new_dir_name.value;

    if (!dirName) {
      toastr.error('Directory name can not be empty.', 'Error');
      return;
    }

    createDirectoryMutation.mutate({ path, dirName });
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="idm-drive-modal create-directory-modal">
        <div className="card">
          <div className="card-header">Create new directory</div>
          <div className="card-body">
            <form onSubmit={onFormSubmit}>
              <label htmlFor="new_dir_name" className="form-label">
                Directory name:
              </label>
              <input
                type="text"
                name="new_dir_name"
                id="new_dir_name"
                autoComplete="off"
                className="form-control"
                required
              />
              <div className="actions mt-2">
                <button type="submit" className="btn btn-success me-1">
                  Create
                </button>
                <button type="button" onClick={closeModal} className="btn btn-danger">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <button onClick={openModal} className={`btn btn-light ${className}`}>
        Create directory
      </button>
    </>
  );
};

export default CreateDirectoryButton;
