import { useState, useEffect, Children, cloneElement } from 'react';
import Spinner from 'sharedComponents/bootstrap/Spinner';
import { getReport } from './api';

const reportId = process.env.REACT_APP_VIRUSES_REPORT_ID;

const DataLoader = ({ children }) => {
  const [report, setReport] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getReport(reportId)
      .then(res => {
        setReport(res.data.pop());
        setLoading(false);
        setError(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="idm-reports viruses mb-5">
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="idm-reports viruses mb-5">
        <div className="alert alert-danger">
          <h5 className="m-0">Data loading failed!</h5>
        </div>
      </div>
    );
  }

  if (!report?.length) {
    return (
      <div className="idm-reports viruses mb-5">
        <div className="alert alert-warning">
          <h5 className="m-0">No data!</h5>
        </div>
      </div>
    );
  }

  return <div className="idm-reports viruses mb-5">{Children.map(children, el => cloneElement(el, { report }))}</div>;
};

export default DataLoader;
