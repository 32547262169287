import { scaleTime, scaleSequential } from 'd3-scale';
import { interpolateRdPu } from 'd3-scale-chromatic';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

export const getScales = (data, xRange) => {
  const xDomain = [minBy(data, 'key')?.date, maxBy(data, 'key')?.date];
  const valuesDomain = [minBy(data, 'value')?.value, maxBy(data, 'value')?.value];

  return [scaleTime(xDomain, xRange), scaleSequential(interpolateRdPu).domain(valuesDomain)];
};
