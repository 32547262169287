import { Children, cloneElement } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getReport } from './api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';
import ErrorMessage from 'sharedComponents/errorMessage/ErrorMessage';

const DataLoader = ({ children }) => {
  const { isLoading, isError, data: response } = useQuery(['idm-reports'], getReport, { staleTime: 3600000 });

  if (isLoading) return <IndicatorWithText text="Loading data..." />;

  if (isError) return <ErrorMessage>Can not retrieve data.</ErrorMessage>;

  return Children.map(children, el => cloneElement(el, { data: response.data }));
};

export default DataLoader;
