import { backend } from 'api/clients';
import { formatDate } from './utils';

export const METRIC_EVENT_COUNT = 'eventCount';
export const METRIC_PAGEVIEWS = 'screenPageViews';
export const DIMENSION_EVENT_NAME = 'eventName';
export const DIMENSION_COUNTRY = 'Country';
export const DIMENSION_HOSTNAME = 'hostName';
export const DIMENSION_DATE = 'date';
export const DIMENSION_PAGE_TYPE = 'customEvent:content_type';
export const DIMENSION_ADVICE_TYPE = 'customEvent:advice_group_type';
export const DIMENSION_PAGE_TITLE = 'customEvent:node_title';
export const DIMENSION_MENU_POSITION = 'customEvent:node_menu_position';
export const ADVICE_NODE_CATEGORY = 'Advice Group';
export const PAGE_NODE_CATEGORY = 'Page';
export const MEDICATION_NODE_CATEGORY = 'Medication';
export const DEFAULT_DATE_RANGE = { startDate: '7daysAgo', endDate: 'yesterday' };

const getDefaultRequestBody = (propertyId, domain, dateRange) => ({
  propertyId: propertyId,
  reportRequests: {
    dateRanges: { startDate: formatDate(dateRange.startDate), endDate: formatDate(dateRange.endDate) },
    keepEmptyRows: true,
    metrics: [
      {
        name: METRIC_PAGEVIEWS,
      },
    ],
    dimensions: [
      {
        name: DIMENSION_HOSTNAME,
      },
    ],
    dimensionFilter: {
      andGroup: {
        expressions: [
          {
            filter: {
              fieldName: DIMENSION_HOSTNAME,
              inListFilter: {
                values: [
                  ...domain.split(',').map(d => (
                    d.trim()
                  ))
                ],
              },
            },
          },
          {
            notExpression: {
              filter: {
                fieldName: DIMENSION_COUNTRY,
                stringFilter: {
                  value: 'Ukraine',
                },
              },
            },
          },
        ],
      },
    },
  },
});

export const getReport = (parameters = {}) => backend.post('/api/v3/analytics', parameters);

export const getTotalViews = (propertyId, domain, dateRange = DEFAULT_DATE_RANGE) => {
  const parameters = getDefaultRequestBody(propertyId, domain, dateRange);
  delete parameters.reportRequests.dimensions;

  return getReport(parameters);
};

export const getTotalViewsDynamic = (propertyId, domain, dateRange = DEFAULT_DATE_RANGE) => {
  const parameters = getDefaultRequestBody(propertyId, domain, dateRange);

  parameters.reportRequests.dimensions.push({ name: DIMENSION_DATE });
  parameters.reportRequests.orderBys = {
    dimension: {
      dimensionName: DIMENSION_DATE
    }
  };

  return getReport(parameters);
};

export const getViewsByCategory = (propertyId, domain, dateRange = DEFAULT_DATE_RANGE) => {
  const parameters = getDefaultRequestBody(propertyId, domain, dateRange);

  parameters.reportRequests.metrics = [{
    name: METRIC_EVENT_COUNT,
  }];
  parameters.reportRequests.dimensions.push({ name: DIMENSION_PAGE_TYPE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_EVENT_NAME });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_EVENT_NAME,
      stringFilter: {
        matchType: 'EXACT',
        value: 'custom',
      },
    },
  });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    notExpression: {
      filter: {
        fieldName: DIMENSION_PAGE_TYPE,
        inListFilter: {
          values: [
            '',
            '(not set)',
          ],
        },
      },
    },
  });

  return getReport(parameters);
};

export const getMostViewedInCategories = (propertyId, domain, dateRange = DEFAULT_DATE_RANGE) => {
  const parameters = getDefaultRequestBody(propertyId, domain, dateRange);

  parameters.reportRequests.metrics = [{
    name: METRIC_EVENT_COUNT,
  }];
  parameters.reportRequests.limit = 30;
  parameters.reportRequests.dimensions.push({ name: DIMENSION_PAGE_TYPE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_PAGE_TITLE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_EVENT_NAME });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_EVENT_NAME,
      stringFilter: {
        matchType: 'EXACT',
        value: 'custom',
      },
    },
  });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    notExpression: {
      filter: {
        fieldName: DIMENSION_PAGE_TYPE,
        inListFilter: {
          values: [
            '',
            '(not set)',
          ],
        },
      },
    },
  });
  parameters.reportRequests.orderBys = {
    dimension: {
      dimensionName: METRIC_EVENT_COUNT,
      orderType: 'ALPHANUMERIC',
    },
    desc: true,
  };

  return getReport(parameters);
};

export const getViewsByAdviceType = (viewId, domain, dateRange = DEFAULT_DATE_RANGE) => {
  const parameters = getDefaultRequestBody(viewId, domain, dateRange);

  parameters.reportRequests.metrics = [{
    name: METRIC_EVENT_COUNT,
  }];
  parameters.reportRequests.dimensions.push({ name: DIMENSION_ADVICE_TYPE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_EVENT_NAME });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_EVENT_NAME,
      stringFilter: {
        matchType: 'EXACT',
        value: 'custom',
      },
    },
  });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    notExpression: {
      filter: {
        fieldName: DIMENSION_ADVICE_TYPE,
        inListFilter: {
          values: [
            '',
            '(not set)',
          ],
        },
      },
    },
  });

  return getReport(parameters);
};

export const getTopTherapySubcategories = (viewId, domain, dateRange = DEFAULT_DATE_RANGE, amount = 5) => {
  const parameters = getDefaultRequestBody(viewId, domain, dateRange);

  parameters.reportRequests.metrics = [{
    name: METRIC_EVENT_COUNT,
  }];
  parameters.reportRequests.dimensions.push({ name: DIMENSION_ADVICE_TYPE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_MENU_POSITION });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_EVENT_NAME });
  parameters.reportRequests.orderBys = {
    dimension: {
      dimensionName: METRIC_EVENT_COUNT,
      orderType: 'ALPHANUMERIC',
    },
    desc: true,
  };
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_EVENT_NAME,
      stringFilter: {
        matchType: 'EXACT',
        value: 'custom',
      },
    },
  });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_ADVICE_TYPE,
      stringFilter: {
        matchType: 'EXACT',
        value: 'Therapy',
      },
    },
  });
  parameters.reportRequests.limit = amount * 3;

  return getReport(parameters);
};

export const getTopProphylaxisSubcategories = (viewId, domain, dateRange = DEFAULT_DATE_RANGE, amount = 5) => {
  const parameters = getDefaultRequestBody(viewId, domain, dateRange);

  parameters.reportRequests.metrics = [{
    name: METRIC_EVENT_COUNT,
  }];
  parameters.reportRequests.dimensions.push({ name: DIMENSION_ADVICE_TYPE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_MENU_POSITION });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_EVENT_NAME });
  parameters.reportRequests.orderBys = {
    dimension: {
      dimensionName: METRIC_EVENT_COUNT,
      orderType: 'ALPHANUMERIC',
    },
    desc: true,
  };
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_EVENT_NAME,
      stringFilter: {
        matchType: 'EXACT',
        value: 'custom',
      },
    },
  });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_ADVICE_TYPE,
      stringFilter: {
        matchType: 'EXACT',
        value: 'Prophylaxis',
      },
    },
  });
  parameters.reportRequests.limit = amount * 3;

  return getReport(parameters);
};

export const getTopNodes = (viewId, domain, dateRange = DEFAULT_DATE_RANGE, amount = 5, pageType = 'Advice Group') => {
  const parameters = getDefaultRequestBody(viewId, domain, dateRange);

  parameters.reportRequests.metrics = [{
    name: METRIC_EVENT_COUNT,
  }];
  parameters.reportRequests.dimensions.push({ name: DIMENSION_PAGE_TYPE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_PAGE_TITLE });
  parameters.reportRequests.dimensions.push({ name: DIMENSION_EVENT_NAME });
  parameters.reportRequests.orderBys = {
    dimension: {
      dimensionName: METRIC_EVENT_COUNT,
      orderType: 'ALPHANUMERIC',
    },
    desc: true,
  };
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_EVENT_NAME,
      stringFilter: {
        matchType: 'EXACT',
        value: 'custom',
      },
    },
  });
  parameters.reportRequests.dimensionFilter.andGroup.expressions.push({
    filter: {
      fieldName: DIMENSION_PAGE_TYPE,
      stringFilter: {
        matchType: 'EXACT',
        value: pageType,
      },
    },
  });
  parameters.reportRequests.limit = amount * 3;

  return getReport(parameters);
};
