import { ActiveSpaceProvider } from './ActiveSpace';
import { ActiveSpaceInfoProvider } from './ActiveSpaceInfo';
import { SpaceAppearanceProvider } from './SpaceAppearance';
import { ApplicationProvider } from './Application';
import { MySpacesProvider } from './MySpaces';

export const SpacesProvider = ({ children }) => (
  <ActiveSpaceProvider>
    <ActiveSpaceInfoProvider>
      <SpaceAppearanceProvider>
        <ApplicationProvider>
          <MySpacesProvider>{children}</MySpacesProvider>
        </ApplicationProvider>
      </SpaceAppearanceProvider>
    </ActiveSpaceInfoProvider>
  </ActiveSpaceProvider>
);

export { useActiveSpace } from './ActiveSpace';
export { useActiveSpaceInfo } from './ActiveSpaceInfo';
export { useSpaceAppearance } from './SpaceAppearance';
export { useApplication } from './Application';
export { useMySpaces } from './MySpaces';
