import SpaceItem from './SpaceItem';

const SpaceList = ({ spaces = [] }) => {
  if (!spaces.length) return null;

  return (
    <div className="app-dashboard">
      <div className="container-fluid">
        {spaces.map(space => (
          <SpaceItem data={space} key={space.uuid} />
        ))}
      </div>
    </div>
  );
};

export default SpaceList;
