import { Link } from 'react-router-dom';

const AddCovidOrderForm = () => {
  return (
    <section>
      <header>
        <Link to="..">{'<'} Go to orders list</Link>
        <h2>Request form COVID-19 diagnostics</h2>
      </header>
      <main>
        <form>
          <div className="row mt-4">
            <div className="col-12">
              <h5>Personal info</h5>
            </div>
            <div className="col-md-4">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input type="text" name="name" id="name" className="form-control" />
            </div>
            <div className="col-md-4">
              <label htmlFor="spouse" className="form-label">
                Married name
              </label>
              <input type="text" name="spouse" id="spouse" className="form-control" />
            </div>
            <div className="col-md-4">
              <label htmlFor="initials" className="form-label">
                Initials
              </label>
              <input type="text" name="initials" id="initials" className="form-control" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <h5>Gender</h5>
              <div className="form-check">
                <input type="radio" className="form-check-input" name="gender" id="male" value="male" />
                <label htmlFor="male" className="form-check-lable">
                  Male
                </label>
              </div>
              <div className="form-check">
                <input type="radio" className="form-check-input" name="gender" id="female" value="female" />
                <label htmlFor="female" className="form-check-lable">
                  Female
                </label>
              </div>
            </div>
          </div>
        </form>
      </main>
    </section>
  );
};

export default AddCovidOrderForm;
