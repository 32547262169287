import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import kebabCase from 'lodash/kebabCase';
import { useDataInFrame } from '../DataInFrameProvider';
import './mostFrequentVirus.scss';

const MostFrequentVirus = () => {
  const data = useDataInFrame();
  const flatData = data.flatMap(r => r.groups.map(g => ({ name: g.key, ordered: g.value })));
  const grouped = groupBy(flatData, 'name');
  const results = [
    {
      name: 'None',
      ordered: 0,
    },
  ];

  for (const virus in grouped) {
    results.push({
      name: virus,
      ordered: grouped[virus].reduce((sum, cur) => sum + cur.ordered, 0),
    });
  }

  const topOneVirus = maxBy(results, 'ordered');

  return (
    <section className={`most-frequent-virus ${kebabCase(topOneVirus.name)} mt-4`}>
      <h6>Most frequently discovered:</h6>
      <div className="virus">{topOneVirus.name}</div>
      <div className="description">
        Found <span className="orders-number">{topOneVirus.ordered}</span> times within the time frame.
      </div>
    </section>
  );
};

export default MostFrequentVirus;
