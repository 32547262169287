import { Children, cloneElement, useState, useRef, useEffect } from 'react';

const ResponsiveContainer = ({ children }) => {
  const containerRef = useRef(null);
  const [rect, setRect] = useState(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      setRect(entry.contentRect);
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, [containerRef]);

  if (!rect) {
    return <div ref={containerRef} />;
  }

  return (
    <div ref={containerRef} className="responsive-container">
      {Children.map(children, el => cloneElement(el, { container: rect }))}
    </div>
  );
};

export default ResponsiveContainer;
