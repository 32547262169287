import { arc, pie } from 'd3-shape';
import classnames from 'classnames';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import { HighlightContextProvider, useHighlightContext } from './HighlightContex';
import { useIncludedSpecialtiesData } from './utils';
import NoDataDonut from './NoDataDonut';

const margin = { top: 15, right: 0, bottom: 0, left: 0 };
const height = 215;
const innerRadius = 50;
const outerRadius = 100;

const radToDeg = rad => (rad * 180) / Math.PI;
const targetAngle = 180;

const Donut = ({ data, position }) => {
  const { hoveredSpecialty, selectedSpecialty, setSelectedSpecialty } = useHighlightContext();

  if (data.specialties.every(s => s[1] === 0)) {
    return <NoDataDonut center={position} outerRadius={outerRadius} innerRadius={innerRadius} side={height} />;
  }

  const pieGenerator = pie()
    .value(d => d[1])
    .sort((a, b) => (a[1] > b[1] ? 1 : -1));
  const arcs = pieGenerator(data.specialties.filter(([, value]) => value));
  const arcGenerator = arc().innerRadius(innerRadius).outerRadius(outerRadius).cornerRadius(4).padAngle(0.01);

  let rotate = 0;
  const selectedArc = selectedSpecialty
    ? arcs.filter(a => a.data[0] === selectedSpecialty).pop()
    : arcs.filter(a => a.data[0] === hoveredSpecialty).pop();

  if (selectedArc) {
    const start = radToDeg(selectedArc.startAngle);
    const end = radToDeg(selectedArc.endAngle);
    const mid = (end - start) / 2 + start;
    rotate = targetAngle - mid;
  }

  const onArcClick = specialty => {
    if (selectedSpecialty && selectedSpecialty === specialty) {
      setSelectedSpecialty('');
    } else {
      setSelectedSpecialty(specialty);
    }
  };

  return (
    <g className="donut" transform={`translate(${position[0]},${margin.top + outerRadius})`}>
      <text className="label total-patients-value">{data.total_patients}</text>
      <text y="5" className="label total-patients-text">
        Patients
      </text>
      <g className="arcs" style={{ transform: `rotate(${rotate}deg)` }}>
        {arcs.map(a => {
          const [specialty, value, percent, color] = a.data;
          return (
            <path
              d={arcGenerator(a)}
              key={specialty}
              fill={color}
              onClick={() => {
                onArcClick(specialty);
              }}
            >
              <title>
                {specialty} - {value} patients ({percent}%)
              </title>
            </path>
          );
        })}
      </g>
    </g>
  );
};

const Chart = ({ container, data, colorScale }) => {
  const { width } = container;
  const canvas = { width: width - margin.right - margin.left, height: height - margin.bottom - margin.top };
  const center = [canvas.width / 2 + margin.left, canvas.height / 2 + margin.top];

  return (
    <svg width={width} height={height}>
      <Donut data={data} position={center} colorScale={colorScale} />
    </svg>
  );
};

const Legend = ({ specialties, isLeft = true }) => {
  const { hoveredSpecialty, selectedSpecialty, setHoveredSpecialty, setSelectedSpecialty } = useHighlightContext();
  const onSpecialtyClick = specialty => {
    if (selectedSpecialty && selectedSpecialty === specialty) {
      setSelectedSpecialty('');
    } else {
      setSelectedSpecialty(specialty);
      setHoveredSpecialty('');
    }
  };

  return (
    <div>
      <table>
        <tbody>
          {specialties.map(([name, value, percents, color]) => {
            const rowClassNames = classnames({
              empty: value < 1,
              highlighted: selectedSpecialty === name || hoveredSpecialty === name,
            });
            return (
              <tr
                className={rowClassNames}
                key={name}
                onMouseEnter={() => {
                  if (!value) return;
                  if (selectedSpecialty) return;
                  setHoveredSpecialty(name);
                }}
                onMouseLeave={() => {
                  if (!value) return;
                  if (selectedSpecialty) return;
                  setHoveredSpecialty('');
                }}
                onClick={() => {
                  onSpecialtyClick(name);
                }}
              >
                {!isLeft && (
                  <>
                    <td>
                      <span className="color" style={{ backgroundColor: value > 0 ? color : '' }}></span>
                    </td>
                    <td>{percents}%</td>
                  </>
                )}
                <td>{name}</td>
                {isLeft && (
                  <>
                    <td>{percents}%</td>
                    <td>
                      <span className="color" style={{ backgroundColor: value > 0 ? color : '' }}></span>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ActiveSpecialty = ({ data }) => {
  const { hoveredSpecialty, selectedSpecialty } = useHighlightContext();
  const specialty = selectedSpecialty
    ? data.specialties.filter(s => s[0] === selectedSpecialty).pop()
    : data.specialties.filter(s => s[0] === hoveredSpecialty).pop();

  if (!specialty || !specialty[1]) {
    return (
      <div className="highlighted-specialty-wrapper">
        <div className="highlighted-specialty no-active">
          Move your cursor over an item in the list to show additional information.
        </div>
      </div>
    );
  }

  const [name, patients, percent, color] = specialty;

  return (
    <div className="highlighted-specialty-wrapper">
      <div className="highlighted-specialty" style={{ borderColor: color }}>
        <div className="name">{name}</div>
        <div>
          <span>
            {patients} patients - {percent}%
          </span>
        </div>
      </div>
      <div className="pointer" style={{ backgroundColor: color }}></div>
    </div>
  );
};

const IncludedSpecialties = () => {
  const data = useIncludedSpecialtiesData();
  const middleDataIndex = Math.ceil(data.specialties.length / 2);

  return (
    <div className="chart included-specialties">
      <h5 className="chart-title">Specialty included patients</h5>
      <div className="parts">
        <HighlightContextProvider data={data}>
          <div className="part legend legend-left">
            <Legend specialties={data.specialties.slice(0, middleDataIndex)} />
          </div>
          <div className="part">
            <div>
              <ResponsiveContainer>
                <Chart data={data} />
              </ResponsiveContainer>
            </div>
            <ActiveSpecialty data={data} />
          </div>
          <div className="part legend legend-right">
            <Legend specialties={data.specialties.slice(middleDataIndex, data.specialties.length + 1)} isLeft={false} />
          </div>
        </HighlightContextProvider>
      </div>
    </div>
  );
};

export default IncludedSpecialties;
