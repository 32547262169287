import { useMutation, useQueryClient } from '@tanstack/react-query';
import toastr from 'toastr';
import { deleteFile } from 'healthDrive/api';

export const DeleteFile = ({ path, className = '', text = 'Remove', iconClass = 'fas fa-trash-alt' }) => {
  const queryClient = useQueryClient();
  const mutationFn = ({ path }) => deleteFile(path);
  const mutation = useMutation(mutationFn, {
    onSuccess: (_, variables) => {
      const parentDir = variables.path.split('/').slice(0, -1).join('/');
      queryClient.invalidateQueries(['listDirectory', parentDir]);
      toastr.warning(path, 'File has been removed!');
    },
  });
  const onDeleteButtonClick = () => {
    if (window.confirm(`Do you really want to delete the file: ${path}?`)) {
      mutation.mutate({ path });
    }
  };

  return (
    <button className={className} onClick={onDeleteButtonClick}>
      <i className={iconClass} /> {text}
    </button>
  );
};
