import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { sub } from 'date-fns';
import { useGoogleAnalyticsSettings } from './GoogleAnalytics';
import { formatDate } from './utils';

const Options = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dateRange } = useGoogleAnalyticsSettings();
  const [startDate, setStartDate] = useState(dateRange.startDate);
  const [endDate, setEndDate] = useState(dateRange.endDate);

  const onStartDateChange = date => {
    setStartDate(date);
  };

  const onEndDateChange = date => {
    setEndDate(date);
  };

  const onSetPeriodClick = () => {
    const params = new URLSearchParams(location.search);
    params.set('start', formatDate(startDate));
    params.set('end', formatDate(endDate));
    const urlQuery = '?' + params.toString();
    location.search = urlQuery;
    navigate(location, { replace: true });
  };

  return (
    <div className="row gx-2">
      <div className="col-12 col-lg-4">
        <label className="input-label" htmlFor="startDate">
          From:
        </label>
        <DatePicker
          selected={startDate}
          onChange={onStartDateChange}
          dateFormat="yyyy-MM-dd"
          maxDate={endDate}
          id="startDate"
          className="form-control"
        />
      </div>
      <div className="col-12 col-lg-4">
        <label className="input-label" htmlFor="endDate">
          To:
        </label>
        <DatePicker
          selected={endDate}
          onChange={onEndDateChange}
          dateFormat="yyyy-MM-dd"
          minDate={startDate}
          maxDate={sub(new Date(), { days: 1 })}
          id="endDate"
          className="form-control"
        />
      </div>
      <div className="col-12 col-lg-4 align-self-end">
        <button className="btn btn-primary" onClick={onSetPeriodClick}>
          Set period
        </button>
      </div>
    </div>
  );
};

export default Options;
