import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { getDownloadTokens } from 'healthDrive/api';
import RemovePermanentLink from 'healthDrive/directory/actions/RemovePermanentLink';
import CopyPermanentLink from 'healthDrive/directory/actions/CopyPermanentLink';

const LinkItem = ({ link }) => {
  const filePath = decodeURIComponent(link.file);
  const filePathComponents = filePath.split('/');
  const fileName = filePathComponents.pop();
  const dirPath = filePathComponents.join('/');

  return (
    <tr>
      <td className="lh-sm">
        <div className="fw-bold">
          <Link to={`/files${dirPath}`}>{fileName}</Link>
        </div>
        <div className="text-muted">
          {dirPath}/{fileName}
        </div>
      </td>
      <td>
        <CopyPermanentLink
          item={{ downloadToken: link.token }}
          title="Copy link to clipboard"
          className="btn btn-sm btn-light me-1"
        />
        <RemovePermanentLink
          item={{ path: link.file, downloadToken: link.token }}
          title="Remove permanent link"
          className="btn btn-sm btn-light"
        />
      </td>
    </tr>
  );
};

const PermanentLinks = () => {
  const { isLoading, isRefetching, error, data } = useQuery(['download-tokens'], () =>
    getDownloadTokens().then(res => res.data)
  );

  if (isLoading || isRefetching) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    throw new Response(error.response.name, {
      status: error.response?.status,
      statusText: error.response?.data?.error,
    });
  }

  if (!data.length) {
    return (
      <div>
        <h2 className="fs-4 mt-3">You have not created any permanent links yet</h2>
        <p>
          If you want to share a file with someone, you can create a permanent link. <br />A permanent link is a link
          that allows you to directly download a file. <br />A file will be accessible by the link until you delete it.
        </p>
        <p>
          To create a link to a file you need to open file's actions menu (triangle at the right of a file)
          <br /> and click "Get permanent link". Permanent link will be created and copied to your clipboard.
        </p>
      </div>
    );
  }

  return (
    <div>
      <table className="table table-hover align-middle">
        <thead className="table-light">
          <tr>
            <th>File</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map(i => (
            <LinkItem link={i} key={i.token} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermanentLinks;
