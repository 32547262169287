import { useMutation, useQueryClient } from '@tanstack/react-query';
import toastr from 'toastr';
import { getDownloadToken, getDownloadLink } from 'healthDrive/api';

const CreatePermanentLink = ({ path, hasPermanentLink = false, className = '' }) => {
  const queryClient = useQueryClient();

  const createPermanentLinkMutation = useMutation(({ path }) => getDownloadToken(path, 1), {
    onSuccess: ({ data }, { path }) => {
      const dirPath = path.split('/').slice(0, -1).join('/');
      navigator.clipboard.writeText(getDownloadLink(data.token));
      queryClient.invalidateQueries(['listDirectory', dirPath]);
      toastr.success('Link created and copied to clipboard.');
    },
    onError: error => {
      toastr.error('Failed to create permanent link.');
      console.dir(error);
    },
  });

  const onButtonClick = e => {
    e.preventDefault();
    createPermanentLinkMutation.mutate({ path });
  };

  return (
    <button onClick={onButtonClick} className={className} disabled={hasPermanentLink}>
      <i className="fas fa-link" /> Get permanent link
    </button>
  );
};

export default CreatePermanentLink;
