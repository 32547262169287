import { useState } from 'react';
import { useUser } from 'user/context/Session';
import { sendFeedback } from './api';

export const useWidgetOpen = (initialOpen = false) => {
  const [isWidgetOpen, setWidgetOpen] = useState(initialOpen);
  const openWidget = () => {
    setWidgetOpen(true);
  };
  const closeWidget = () => {
    setWidgetOpen(false);
  };

  return { isWidgetOpen, openWidget, closeWidget };
};

export const useSendFeedback = () => {
  const user = useUser();
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const send = ({ message, attachments, extra_data }) => {
    setIsSending(true);
    setError(null);
    setIsSuccess(false);
    sendFeedback({ user: user.name, message, attachments, extra_data })
      .then(res => {
        if (!res.ok) {
          throw new Error(res);
        }
      })
      .then(() => setIsSuccess(true))
      .catch(err => setError(err))
      .finally(() => setIsSending(false));
  };

  return { isSending, error, setError, isSuccess, send };
};
