import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';
import Layout from 'layout/Layout';
import { useIsAnonymous, useUser } from 'user/context/Session';

import RouteError from './components/RouteError';

import User, { userRoutes } from 'user/User';
import UserLogin from 'user/UserLogin';
import Applications from 'applications/Applications';
import Analytics, { analyticsRoutes } from 'analytics/Analytcis';
import Orders from 'orders/Orders';
import IdmReports, { idmReportsRoutes } from 'idmReports/IdmReports';
import HealthDrive, { healthDriveRoutes } from 'healthDrive/HealthDrive';
import Feedback, { feedbackRoutes } from 'feedback/Feedback';

/**
 * Temporary workaround for case when user has access only to IDM Reports application.
 * @TODO: need to implement this functionality in a more nice and general way.
 */
const getDefaultRoute = user => {
  const spacesIds = Object.keys(user.data.spaces);

  /**
   * If a user has multiple spaces, then redirect to the application select screen.
   */
  if (spacesIds.length > 1) {
    return '/applications';
  }

  const applications = user.data.active_space.access;
  const appNames = Object.keys(applications);

  /**
   * If a user has more than one application, redirect to the application select screen.
   * And if a user has no applications at all, redirect to the same screen.
   */
  if (appNames.length > 1 || appNames.length === 0) {
    return '/applications';
  }

  /**
   * At this step, we assume that a user has only one application available.
   * And if this application is not 'IDM Reports' - redirect to the application select screen.
   */
  if (appNames[0] !== 'idm_reports') {
    return '/applications';
  }

  /**
   * At this step, we assume that a user has only the 'IDM Reports' application available.
   */
  const idmReportsAccessRules = Object.keys(applications.idm_reports);

  /**
   * But there might be several reports available.
   * In this case - redirect to the reports list screen.
   */
  if (idmReportsAccessRules.length > 1) {
    return '/idm-reports';
  }

  /**
   * If only one report is available - redirect a user to this report.
   */
  if (idmReportsAccessRules[0] === 'view_report_patients') {
    return '/idm-reports/patients';
  }

  if (idmReportsAccessRules[0] === 'view_report_viruses') {
    return '/idm-reports/viruses';
  }

  /**
   * Unreachable return statement, but let it be. Just for unexpected cases.
   */
  return '/idm-reports';
};

const RootRoutes = () => {
  const isAnonymous = useIsAnonymous();
  const user = useUser();
  const defaultNotAnonymousRoute = getDefaultRoute(user);
  const defaultPath = isAnonymous ? '/user/login' : defaultNotAnonymousRoute;

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      errorElement: <RouteError />,
      children: [
        {
          index: true,
          element: <Navigate to={defaultPath} />,
        },
        {
          path: 'analytics',
          element: <Analytics />,
          children: [...analyticsRoutes],
        },
        {
          path: 'applications',
          element: <Applications />,
        },
        {
          path: 'files',
          element: <HealthDrive />,
          children: [...healthDriveRoutes],
        },
        {
          path: 'idm-reports',
          element: <IdmReports />,
          children: [...idmReportsRoutes],
        },
        {
          path: 'feedback',
          element: <Feedback />,
          children: [...feedbackRoutes],
        },
        {
          path: 'orders/*',
          element: <Orders />,
        },
        {
          path: 'user',
          element: <User />,
          children: [...userRoutes],
        },
      ],
    },
    {
      path: '/user/login',
      element: <UserLogin />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RootRoutes;
