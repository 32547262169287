// Common helpers.
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

// Months.
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

// Quarters.
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import addQuarters from 'date-fns/addQuarters';

// Years.
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addYears from 'date-fns/addYears';

import { useTimeframeContext } from 'idmReports/patients/TimeframeContext';

const isOutOfDomain = (start, end, domain) => isBefore(start, domain[0]) || isAfter(end, domain[1]);

const setThisMonthPeriod = (now, setTimeframe) => {
  const firstDay = startOfMonth(now);
  setTimeframe({ start: firstDay, end: now });
};

const setThisQuarterPeriod = (now, setTimeframe) => {
  const firstDay = startOfQuarter(now);
  setTimeframe({ start: firstDay, end: now });
};

const setThisYearPeriod = (now, setTimeframe) => {
  const firstDay = startOfYear(now);
  setTimeframe({ start: firstDay, end: now });
};

const setLastMonthPeriod = (now, setTimeframe) => {
  const thisMonthFirstDay = startOfMonth(now);
  const lastMonthFirstDay = addMonths(thisMonthFirstDay, -1);
  const endOfLastMonth = endOfMonth(lastMonthFirstDay);
  setTimeframe({ start: lastMonthFirstDay, end: endOfLastMonth });
};

const setLastQuarterPeriod = (now, setTimeframe) => {
  const thisQuarterFirstDay = startOfQuarter(now);
  const lastQuarterFirstDay = addQuarters(thisQuarterFirstDay, -1);
  const lastQuarterLastDay = endOfQuarter(lastQuarterFirstDay);
  setTimeframe({ start: lastQuarterFirstDay, end: lastQuarterLastDay });
};

const setLastYearPeriod = (now, setTimeframe) => {
  const thisYearFirstDay = startOfYear(now);
  const lastYearFirstDay = addYears(thisYearFirstDay, -1);
  const lastYearLastDay = endOfYear(lastYearFirstDay);
  setTimeframe({ start: lastYearFirstDay, end: lastYearLastDay });
};

const shiftMonth = (numberOfMonths, timeframe, setTimeframe, domain) => {
  const startingPoint = numberOfMonths < 0 ? timeframe.start : timeframe.end;
  const shiftedDate = addMonths(startingPoint, numberOfMonths);
  const newStart = startOfMonth(shiftedDate);
  const newEnd = endOfMonth(shiftedDate);

  if (isOutOfDomain(newStart, newEnd, domain)) {
    return;
  }

  setTimeframe({ start: newStart, end: newEnd });
};

const shiftQuarter = (numberOfQuarters, timeframe, setTimeframe, domain) => {
  const startingPoint = numberOfQuarters < 0 ? timeframe.start : timeframe.end;
  const shiftedDate = addQuarters(startingPoint, numberOfQuarters);
  const newStart = startOfQuarter(shiftedDate);
  const newEnd = endOfQuarter(shiftedDate);

  if (isOutOfDomain(newStart, newEnd, domain)) {
    return;
  }

  setTimeframe({ start: newStart, end: newEnd });
};

const shiftYear = (numberOfYears, timeframe, setTimeframe, domain) => {
  const shiftedDate = addYears(timeframe.start, numberOfYears);
  const newStart = startOfYear(shiftedDate);
  const newEnd = endOfYear(shiftedDate);

  if (isOutOfDomain(newStart, newEnd, domain)) {
    return;
  }

  setTimeframe({ start: newStart, end: newEnd });
};

const selectWholeYear = (timeframe, setTimeframe, domain) => {
  const newStart = startOfYear(timeframe.start);
  const newEnd = endOfYear(timeframe.start);
  const start = isBefore(newStart, domain[0]) ? domain[0] : newStart;
  const end = isAfter(newEnd, domain[1]) ? domain[1] : newEnd;

  setTimeframe({ start, end });
};

const selectWholeQuarter = (timeframe, setTimeframe, domain) => {
  const newStart = startOfQuarter(timeframe.start);
  const newEnd = endOfQuarter(timeframe.start);
  const start = isBefore(newStart, domain[0]) ? domain[0] : newStart;
  const end = isAfter(newEnd, domain[1]) ? domain[1] : newEnd;

  setTimeframe({ start, end });
};

const selectWholeMonth = (timeframe, setTimeframe, domain) => {
  const newStart = startOfMonth(timeframe.start);
  const newEnd = endOfMonth(timeframe.start);
  const start = isBefore(newStart, domain[0]) ? domain[0] : newStart;
  const end = isAfter(newEnd, domain[1]) ? domain[1] : newEnd;

  setTimeframe({ start, end });
};

const QuickPeriods = () => {
  const now = new Date();
  const { timeframe, setTimeframe, domain } = useTimeframeContext();

  return (
    <div className="btn-toolbar my-2">
      <div className="btn-group me-3">
        <div className="input-group input-group-sm">
          <span className="input-group-text fw-bold">Select this</span>
          <button
            onClick={() => setThisMonthPeriod(now, setTimeframe)}
            className="btn btn-outline-secondary"
            title="Select current month"
          >
            Month
          </button>
          <button
            onClick={() => setThisQuarterPeriod(now, setTimeframe)}
            className="btn btn-outline-secondary"
            title="Select current quarter"
          >
            Quarter
          </button>
          <button
            onClick={() => setThisYearPeriod(now, setTimeframe)}
            className="btn btn-outline-secondary"
            title="Select current year"
          >
            Year
          </button>
        </div>
      </div>
      <div className="btn-group me-4">
        <div className="input-group input-group-sm">
          <span className="input-group-text fw-bold">Select last</span>
          <button
            onClick={() => setLastMonthPeriod(now, setTimeframe)}
            className="btn btn-outline-secondary"
            title="Select last month"
          >
            Month
          </button>
          <button
            onClick={() => setLastQuarterPeriod(now, setTimeframe)}
            className="btn btn-outline-secondary"
            title="Select last quarter"
          >
            Quarter
          </button>
          <button
            onClick={() => setLastYearPeriod(now, setTimeframe)}
            className="btn btn-outline-secondary"
            title="Select last year"
          >
            Year
          </button>
        </div>
      </div>
      <div className="btn-group me-3">
        <div className="input-group input-group-sm">
          <button
            onClick={() => shiftMonth(-1, timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary"
            title="Shift one month back"
          >
            {'<'}
          </button>
          <button
            onClick={() => selectWholeMonth(timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary fw-bold"
            title="Select a whole month (based on the current period start date)"
          >
            Month
          </button>
          <button
            onClick={() => shiftMonth(1, timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary"
            title="Shift one month forward"
          >
            {'>'}
          </button>
        </div>
      </div>
      <div className="btn-group me-3">
        <div className="input-group input-group-sm">
          <button
            onClick={() => shiftQuarter(-1, timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary"
            title="Shift one quarter back"
          >
            {'<'}
          </button>
          <button
            onClick={() => selectWholeQuarter(timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary fw-bold"
            title="Select a whole quarter (based on the current period start date)"
          >
            Quarter
          </button>
          <button
            onClick={() => shiftQuarter(1, timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary"
            title="Shift one quarter forward"
          >
            {'>'}
          </button>
        </div>
      </div>
      <div className="btn-group me-2">
        <div className="input-group input-group-sm">
          <button
            onClick={() => shiftYear(-1, timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary"
            title="Shift one year back"
          >
            {'<'}
          </button>
          <button
            onClick={() => selectWholeYear(timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary fw-bold"
            title="Select a whole year (based on the current period start date)"
          >
            Year
          </button>
          <button
            onClick={() => shiftYear(1, timeframe, setTimeframe, domain)}
            className="btn btn-outline-secondary"
            title="Shift one year forward"
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickPeriods;
