import groupBy from 'lodash/groupBy';
import ChartContainer from './ChartContainer';
import { getTopNodes } from 'analytics/googleAnalytics/api';
import { getMetricsSum } from '../utils';
import TopTable from './TopTable';

const amount = 5;
const NODE_TYPE_MEDICATION = 'Medication';

const prepareData = data => {
  const rows = data.rows;
  const grouped = groupBy(rows, 'dimensionValues[2].value');
  const calculated = Object.values(grouped).map(g => ({
    title: g[0].dimensionValues[2].value,
    views: getMetricsSum(g),
  }));

  return calculated.slice(0, amount);
};

const Chart = ({ data }) => {
  return <TopTable rows={data} />;
};

const TopMedicationNodes = () => {
  return (
    <ChartContainer
      title={`Top ${amount} medication`}
      api={getTopNodes}
      dataAlter={prepareData}
      parameters={[amount, NODE_TYPE_MEDICATION]}
    >
      <Chart />
    </ChartContainer>
  );
};

export default TopMedicationNodes;
