import { Link, useLocation } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { DeleteFile } from './Actions';
import RemoveDirectory from './actions/RemoveDirectory';
import DownloadFile from './actions/DownloadFile';
import PreviewFile from './actions/PreviewFile';
import CreatePermanentLink from './actions/CreatePermanentLink';
import RemovePermanentLink from './actions/RemovePermanentLink';
import CopyPermanentLink from './actions/CopyPermanentLink';
import { useParentFolder, useRemotePath } from 'healthDrive/hooks';
import { timestampToFormatedDate, formatFileSize } from 'healthDrive/utils';

export const TableHeader = () => (
  <thead>
    <tr>
      <th className="file-type-icon"></th>
      <th className="file-name">Name</th>
      <th className="file-upload-date">Uploaded</th>
      <th className="file-size">Size</th>
    </tr>
  </thead>
);

const RowBackToParent = () => {
  const parentPath = useParentFolder();

  if (parentPath === '/files') return null;

  return (
    <tr className="row-back-to-parent">
      <td></td>
      <td>
        <Link to={parentPath}>
          <i className="fas fa-reply" /> Back
        </Link>
      </td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

const ItemType = ({ type }) => {
  const iconClassesByType = {
    directory: 'fas fa-folder',
    file: 'far fa-file',
  };

  return <i className={iconClassesByType[type]} style={{ opacity: 0.3 }} />;
};

const FileNameButton = ({ file }) => {
  const remotePath = useRemotePath();
  const { name, downloadToken } = file;

  return (
    <div className="btn-group">
      <span className="p-0">{decodeURIComponent(name)}</span>
      <button
        type="button"
        className="btn btn-link dropdown-toggle dropdown-toggle-split py-0"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu">
        <li>
          <h6 className="dropdown-header">Actions</h6>
        </li>
        <li>
          <PreviewFile path={`${remotePath}/${name}`} className="dropdown-item" />
        </li>
        <li>
          <DownloadFile path={`${remotePath}/${name}`} className="dropdown-item" />
        </li>
        <li>
          <CreatePermanentLink
            path={`${remotePath}/${name}`}
            hasPermanentLink={downloadToken}
            className="dropdown-item"
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <DeleteFile path={`${remotePath}/${name}`} className="dropdown-item text-danger" />
        </li>
      </ul>
    </div>
  );
};

const DirectoryNameButton = ({ directory }) => {
  const { pathname } = useLocation();
  const remotePath = useRemotePath();
  const { name, downloadToken } = directory;

  if (remotePath === '/spaces') {
    return (
      <div className="btn-group">
        <Link to={`${pathname}/${name}`} className="btn btn-link p-0">
          {decodeURIComponent(name)}
        </Link>
      </div>
    );
  }

  return (
    <div className="btn-group">
      <Link to={`${pathname}/${name}`} className="btn btn-link p-0">
        {decodeURIComponent(name)}
      </Link>
      <button
        type="button"
        className="btn btn-link dropdown-toggle dropdown-toggle-split py-0"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu">
        <li>
          <h6 className="dropdown-header">Actions</h6>
        </li>
        <li>
          <DownloadFile path={`${remotePath}/${name}`} className="dropdown-item" />
        </li>
        <li>
          <CreatePermanentLink
            path={`${remotePath}/${name}`}
            hasPermanentLink={downloadToken}
            className="dropdown-item"
          />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <RemoveDirectory path={`${remotePath}/${name}`} className="dropdown-item text-danger" />
        </li>
      </ul>
    </div>
  );
};

const ItemName = ({ item }) => {
  return item.type === 'directory' ? <DirectoryNameButton directory={item} /> : <FileNameButton file={item} />;
};

const PermanentLinkIndicator = ({ item }) => {
  if (!item.downloadToken) return null;

  return (
    <span className="float-end">
      <div className="dropdown">
        <button
          className="btn btn-sm btn-light"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title="File has permanent link (click to see available actions)"
        >
          <i class="fas fa-link" />
        </button>
        <ul className="dropdown-menu">
          <li>
            <h6 className="dropdown-header">Permanent link</h6>
          </li>
          <li>
            <CopyPermanentLink item={item} text="Copy to clipboard" className="dropdown-item" />
          </li>
          <li>
            <RemovePermanentLink item={item} text="Remove link" className="dropdown-item" />
          </li>
        </ul>
      </div>
    </span>
  );
};

const Table = ({ content }) => {
  const orderedContent = orderBy(content, ['type', i => i.name.toLocaleLowerCase()]);

  const rows =
    content.length > 0 ? (
      orderedContent.map(item => (
        <tr key={item.name}>
          <td className="file-type-icon">
            <ItemType type={item.type} />
          </td>
          <td>
            <ItemName item={item} />
            <PermanentLinkIndicator item={item} />
          </td>
          <td>{timestampToFormatedDate(item.mtime)}</td>
          <td>{formatFileSize(item.size)}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={4} className="text-center">
          <i className="far fa-folder text-secondary" /> The directory is empty.
        </td>
      </tr>
    );

  return (
    <table className="directory-table">
      <TableHeader />
      <tbody>
        <RowBackToParent />
        {rows}
      </tbody>
    </table>
  );
};

export default Table;
