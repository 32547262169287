import { useActiveSpace } from 'user/context/Session';
import isEmpty from 'lodash/isEmpty';
import AppItem from './AppItem';

const SpaceItem = ({ data }) => {
  const activeSpace = useActiveSpace();

  if (isEmpty(data.applications)) return null;

  const isSpaceActive = activeSpace.uuid === data.uuid;

  return (
    <div className="row mb-3">
      <div className="col-12">
        <h3 className="space-name">
          {data.label}
          {isSpaceActive && (
            <span className="active-space-mark" title="Active space">
              {' '}
              ◈
            </span>
          )}
        </h3>
      </div>
      <div className="col-12">
        <div className="row">
          {data.applications.map(app => (
            <div className="col-6 col-sm-3 col-lg-2" key={app.id}>
              <AppItem data={app} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpaceItem;
