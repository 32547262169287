import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { useGetOrders } from './api';

const OrderListItem = ({ order }) => (
  <tr>
    <td>{order.id}</td>
    <td>
      <Link to={`${order.id}`}>{order.name}</Link>
    </td>
    <td>{order.address}</td>
    <td>{format(new Date(order.date), 'MMM dd yyyy HH:mm')}</td>
  </tr>
);

const OrdersList = () => {
  const { isLoading, error, data } = useGetOrders();

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error!</div>;

  return (
    <div className="order_covid--wrapper">
      <div className="my-4">
        <Link className="btn btn-primary" to="add">
          Add new order
        </Link>
      </div>

      <table className="table table-bordered table-striped table-light">
        <thead>
          <tr>
            <th>Order</th>
            <th>Patient name</th>
            <th>Address</th>
            <th>Created date</th>
          </tr>
        </thead>
        <tbody>
          {data.map(order => (
            <OrderListItem key={order.id} order={order} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersList;
