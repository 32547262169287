export const MessageField = ({ message, setMessage }) => {
  return (
    <div className="field-message">
      <label htmlFor="message" className="form-label">
        Message
      </label>
      <textarea
        onChange={ev => {
          setMessage(ev.target.value);
        }}
        name="message"
        id="message"
        rows="3"
        cols={50}
        className="form-control"
        value={message}
      ></textarea>
    </div>
  );
};
