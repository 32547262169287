import { createContext, useState } from 'react';
import intervalToDuration from 'date-fns/intervalToDuration';
import sub from 'date-fns/sub';
import isBefore from 'date-fns/isBefore';
import { createUseContextHook } from 'utility/utils';
import { getTimeframeDates } from './DataDistribution/utils';
import { filterDataByDate } from './utils';

const TimeframeContext = createContext();

export const useTimeframeContext = createUseContextHook(
  TimeframeContext,
  'useTimeframeContext can be used only within TimeframeContextProvider.'
);

export const TimeframeContextProvider = ({ data, children }) => {
  const { domain, dates } = getTimeframeDates(data);
  const [timeframe, setTimeframe] = useState({ start: domain[0], end: domain[1] });
  const framedData = filterDataByDate(data, timeframe);
  const prevTimeframeEnd = timeframe.start;
  const prevTimeframe = { end: prevTimeframeEnd, start: sub(prevTimeframeEnd, intervalToDuration(timeframe)) };
  const prevData = isBefore(prevTimeframe.start, domain[0]) ? framedData : filterDataByDate(data, prevTimeframe);

  return (
    <TimeframeContext.Provider value={{ data: framedData, prevData, timeframe, setTimeframe, domain, dates }}>
      {children}
    </TimeframeContext.Provider>
  );
};
