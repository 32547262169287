import { useState, useEffect } from 'react';
import orders from './mockData/orders.json';

export const getOrders = () => Promise.resolve({ data: orders });

export const getOrder = id => {
  const order = orders.filter(o => o.id === id);

  return new Promise((resolve, reject) => {
    if (order.length === 0) {
      reject({
        status: 404,
        statusText: 'Order not found!',
      });
    }

    resolve({ data: order.pop() });
  });
};

export const useGetOrders = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getOrders()
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return { isLoading, error, data };
};

export const useGetOrder = id => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    setLoading(true);
    getOrder(id)
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, [id]);

  return { isLoading, error, data };
};
