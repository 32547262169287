import Timeframe from './Timeframe';
import PeriodSelector from './PeriodSelector/PeriodSelector';
import PeriodLabel from './PeriodLabel';
import './dataDistribution.scss';

const SectionDescription = () => (
  <details>
    <summary>What is it?</summary>
    <div className="content">
      <p>
        This chart allows you to manually select time period for datasets to include. Only data from datasets within
        selected time period will be used for report generation (chart, tables, etc).
      </p>
      <p>
        A vertical blue line represents individual dataset and its horizontal position correlates with date the dataset
        was created. As an extra information you can see time periods when datasets were created more or less often and
        how regular they were created.
      </p>
      <h6>How to control time period (timeframe)?</h6>
      <p>
        <strong>Shift timeframe</strong>. You can shift timeframe without changing its duration. To do it, you need to
        hover your cursor over the timeframe (lighter area with blue border), press and hold the left mouse button and
        move the cursor to the left or right. All changes will apply after you release the mouse button.
      </p>
      <p>
        <strong>Change start date</strong>. You can increase or decrease the time period’s start date. To do it, you
        need to hover your cursor over the left edge of the timeframe, press the left mouse button and drag the edge to
        the needed direction.
      </p>
      <p>
        <strong>Change end date</strong>. You can increase or decrease the time period’s end date. To do it, you need to
        hover your cursor over the right edge of the timeframe, press the left mouse button and drag the edge to the
        needed direction.
      </p>
    </div>
  </details>
);

const DataDistribution = () => {
  return (
    <>
      <SectionDescription />
      <div>
        <Timeframe />
      </div>
      <div>
        <span>Selected time period: </span>
        <span className="badge bg-primary fs-6">
          <PeriodLabel />
        </span>
      </div>
      <PeriodSelector />
    </>
  );
};

export default DataDistribution;
