import { useTimeframe } from './TimeframeProvider';
import { formatDate } from './calculations';

const Timeframe = () => {
  const [timeframe] = useTimeframe();

  return (
    <div>
      <h5>Time frame</h5>
      <div>
        {formatDate(timeframe.start)} - {formatDate(timeframe.end)}
      </div>
    </div>
  );
};

export default Timeframe;
