import { useState, useRef } from 'react';
import classnames from 'classnames';

export const AttachmentsField = ({ files, setFiles }) => {
  const fileInputRef = useRef();
  const dropzoneRef = useRef();
  const [isDragOver, setDragOver] = useState(false);

  const onDropzoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileInput = ev => {
    const files = Array.from(ev.target.files);
    setFiles(files.slice(0, 5));
  };

  const onDragEnter = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    setDragOver(false);
  };

  const onDragOver = ev => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  const onDrop = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    setDragOver(false);
    const dt = ev.dataTransfer;
    const files = Array.from(dt.files);
    setFiles(files.slice(0, 5));
  };

  const dropzoneClassnames = classnames({
    dropzone: true,
    'drag-over': isDragOver,
  });

  return (
    <div className="field-attachments mt-3">
      <label htmlFor="attachments" className="form-label">
        Screenshots
      </label>
      <input
        type="file"
        name="attachments"
        id="attachments"
        onInput={onFileInput}
        ref={fileInputRef}
        accept=".jpg,.jpeg,.gif,.png"
        multiple
      />
      <div
        className={dropzoneClassnames}
        onClick={onDropzoneClick}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        ref={dropzoneRef}
      >
        <i className="fas fa-image" />
        <br />
        Drag and drop your screenshots here or click to select files manually...
        <br />
        Accept formats: png, jpg, gif. Up to 5 files.
      </div>
      <div className="thumbnails">
        {files.map(f => (
          <span className="thumbnail" key={f.name}>
            <img src={URL.createObjectURL(f)} alt={f.name} />
            <button
              className="thumbnail-remove"
              onClick={() => {
                setFiles(files => files.filter(file => file.name !== f.name));
              }}
            >
              &times;
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};
