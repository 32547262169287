import { createContext, useState } from 'react';
import { createUseContextHook } from 'utility/utils';

const HighlightContext = createContext();

export const useHighlightContext = createUseContextHook(
  HighlightContext,
  'useHighlightContext can be used only within HighlightContextProvider.'
);

export const HighlightContextProvider = ({ data, children }) => {
  const [hoveredSpecialty, setHoveredSpecialty] = useState('');
  const [selectedSpecialty, setSelectedSpecialty] = useState('');

  return (
    <HighlightContext.Provider
      value={{ hoveredSpecialty, setHoveredSpecialty, selectedSpecialty, setSelectedSpecialty }}
    >
      {children}
    </HighlightContext.Provider>
  );
};
