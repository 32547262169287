import { Routes, Route, Navigate } from 'react-router-dom';
import OrdersList from './OrdersList';
import Order from './order/Order';
import AddCovidOrderForm from './order/form/AddCovidOrderForm';
import { notFoundConfig } from 'routes/components/NotFound';

const Orders = () => (
  <Routes>
    <Route index element={<Navigate to="covid_19" />} />
    <Route path="covid_19">
      <Route index element={<OrdersList />} />
      <Route path=":id" element={<Order />} />
      <Route path="add" element={<AddCovidOrderForm />} />
    </Route>
    <Route path="*" element={<Navigate to={notFoundConfig.PATH} />} />
  </Routes>
);

export default Orders;
