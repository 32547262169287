import kebabCase from 'lodash/kebabCase';
import { useDataInFrame } from 'idmReports/viruses/DataInFrameProvider';
import { getVirusesList } from './calculations';

const Legend = () => {
  const data = useDataInFrame();
  const viruses = getVirusesList(data);

  return (
    <div className="legend">
      <ul>
        {viruses.sort().map(v => (
          <li key={v} className={`legend-item ${kebabCase(v)}`}>
            <span className="symbol" />
            <span className="name">{v}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legend;
