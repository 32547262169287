import { useRemoteDirectory, useRemotePath } from 'healthDrive/hooks';
import Breadcrumbs from 'healthDrive/breadcrumbs/Breadcrumbs';
import UploadFileButton from 'healthDrive/upload/UploadFileButton';
import CreateDirectoryButton from './actions/CreateDirectory';
import Table from './Table';

const Directory = () => {
  const { isLoading, isRefetching, error, content } = useRemoteDirectory();
  const path = useRemotePath();

  if (isLoading || isRefetching)
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="alert alert-danger">
        <h4 className="alert-heading">Ooops!</h4>
        <hr />
        Error. Something went wrong.
      </div>
    );

  return (
    <div className="directory">
      <div className="row">
        <div className="col">
          <div className="dir-header">
            <Breadcrumbs />
            {path !== '/spaces' &&
              <div className="actions">
                <CreateDirectoryButton className="me-1" />
                <UploadFileButton />
              </div>
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Table content={content} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default Directory;
