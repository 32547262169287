import { useWidgetOpen } from './hooks';
import { OpenWidgetButton } from './OpenWidgetButton';
import { FormController } from './forms/FormController';
import './FeedbackWidget.scss';

const WidgetHeader = ({ closeWidget }) => {
  return (
    <header className="feedback-widget-header">
      <span className="widget-title">Send feedback</span>
      <button type="button" onClick={closeWidget} className="close-widget-button">
        &times;
      </button>
    </header>
  );
};

export const FeedbackWidget = () => {
  const { isWidgetOpen, openWidget, closeWidget } = useWidgetOpen();

  if (!isWidgetOpen) {
    return <OpenWidgetButton onClick={openWidget} />;
  }

  return (
    <section className="feedback-widget-wrapper">
      <WidgetHeader closeWidget={closeWidget} />
      <main className="feedback-widget-body">
        <FormController />
      </main>
    </section>
  );
};
