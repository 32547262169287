import kebabCase from 'lodash/kebabCase';

const Tooltip = ({ data }) => (
  <section className="report-tooltip">
    <table className="table table-sm m-0">
      <tbody>
        <tr>
          <th scope="row" className="px-3">
            Date
          </th>
          <td className="pe-3">{data.dateStr}</td>
        </tr>
        <tr>
          <th scope="row" className="px-3">
            Virus
          </th>
          <td className="pe-3">
            <span className={`legend-mark ${kebabCase(data.key)}`} />
            {data.key}
          </td>
        </tr>
        <tr>
          <th scope="row" className="px-3">
            Orders
          </th>
          <td className="pe-3">{data.value}</td>
        </tr>
      </tbody>
    </table>
  </section>
);

export default Tooltip;
