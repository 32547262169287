import { useMutation, useQueryClient } from '@tanstack/react-query';
import toastr from 'toastr';
import { removeDirectory } from 'healthDrive/api';

const RemoveDirectory = ({ path, className = '', text = 'Remove', iconClass = 'fas fa-trash-alt' }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ path }) => removeDirectory(path), {
    onSuccess: (_, variables) => {
      const parentDir = variables.path.split('/').slice(0, -1).join('/');
      queryClient.invalidateQueries(['listDirectory', parentDir]);
      toastr.warning(path, 'Directory has been removed!');
    },
    onError: () => {
      toastr.error('Directory removing failed.');
    },
  });

  const onDeleteButtonClick = () => {
    if (window.confirm(`Do you really want to delete the directory: ${decodeURIComponent(path)}?`)) {
      mutation.mutate({ path });
    }
  };

  return (
    <button className={className} onClick={onDeleteButtonClick}>
      <i className={iconClass} /> {text}
    </button>
  );
};

export default RemoveDirectory;
