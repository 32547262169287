import { Link } from 'react-router-dom';

const links = [{ text: 'Overview', url: '#overview' }];

const Tabs = () => {
  if (links.length < 2) return null;

  return (
    <nav>
      <h6 className="m-0">Jump to:</h6>
      <ul className="nav">
        {links.map(l => (
          <li className="nav-item" key={l.text}>
            <Link to={l.url} className="nav-link">
              {l.text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Tabs;
