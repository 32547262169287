import { arc, pie } from 'd3-shape';
import { useTimeframeContext } from '../TimeframeContext';
import NoDataDonut from './NoDataDonut';
import { getReducedData } from '../utils';
import { getPercent } from './utils';
import TrendIndicator from './TrendIndicator';

const height = 200;
const margin = { top: 0, right: 0, bottom: 0, left: 0 };
const pieGenerator = pie()
  .value(d => d.value)
  .sort(a => (a.key === 'unique' ? -1 : 1));

const Chart = ({ data }) => {
  const side = height;
  const canvas = { width: side - margin.right - margin.left, height: side - margin.bottom - margin.top };
  const center = [canvas.width / 2 + margin.left, canvas.height / 2 + margin.top];
  const outerRadius = Math.min(canvas.width, canvas.height) / 2;
  const innerRadius = outerRadius * 0.45;

  if (data.every(d => d.value < 1)) {
    return <NoDataDonut center={center} outerRadius={outerRadius} innerRadius={innerRadius} side={side} />;
  }

  const arcs = pieGenerator(data);
  const arcGenerator = arc().innerRadius(innerRadius).outerRadius(outerRadius).cornerRadius(4).padAngle(0.02);

  return (
    <svg width={side} height={side}>
      <g className="sectors">
        {arcs.map(a => (
          <path
            d={arcGenerator(a)}
            transform={`translate(${center[0]},${center[1]})`}
            className={`sector sector-${a.data.key}`}
            key={a.data.key}
          >
            <title>
              {a.data.title} - {a.data.value} ({a.data.percent}%)
            </title>
          </path>
        ))}
      </g>
      <text className="label label-total-patients-value" x={center[0]} y={center[1]}>
        {data[0].total}
      </text>
      <text className="label label-total-patients-text" x={center[0]} y={center[1] + 3}>
        Patients
      </text>
      {arcs.map(a => (
        <g className={`sector-labels labels labels-${a.data.key}`} key={a.data.key}>
          <text
            className="label label-value"
            x={arcGenerator.centroid(a)[0]}
            y={arcGenerator.centroid(a)[1]}
            transform={`translate(${center[0]},${center[1]})`}
          >
            {a.data.value}
          </text>
          <text
            className="label label-percents"
            x={arcGenerator.centroid(a)[0]}
            y={arcGenerator.centroid(a)[1] + 3}
            transform={`translate(${center[0]},${center[1]})`}
          >
            {a.data.percent}%
          </text>
        </g>
      ))}
    </svg>
  );
};

const Consultancy = () => {
  const { data: dt, prevData: pdt } = useTimeframeContext();
  const report = getReducedData(dt);
  const prevReport = getReducedData(pdt);
  const data = [
    {
      key: 'not_changed_strategy',
      value: report.not_changed_strategy,
      title: 'Not changed medication strategy',
      percent: getPercent(report.not_changed_strategy, report.suspicious),
      total: report.suspicious,
    },
    {
      key: 'changed_strategy',
      value: report.changed_strategy,
      title: 'Changed medication strategy',
      percent: getPercent(report.changed_strategy, report.suspicious),
      total: report.suspicious,
      prevValue: prevReport.changed_strategy,
    },
  ];

  return (
    <div className="chart consultancy">
      <h5 className="chart-title">Consultancy</h5>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', width: height }}>
        <Chart data={data} />
      </div>
      <div className="legend mt-3">
        <table className="table table-hover table-sm caption-top">
          <thead>
            <tr>
              <th className="text-center">Color</th>
              <th>Name</th>
              <th>Value</th>
              <th>Percent</th>
            </tr>
          </thead>
          <tbody className="table-light">
            {data.map(i => (
              <tr className={`legend-item legend-${i.key}`} key={i.key}>
                <td className="text-center">
                  <span className="item-color" />
                </td>
                <td className="item-name">{i.title}</td>
                <td className="item-value">
                  {i.value}
                  <TrendIndicator value={i.value} prevValue={i.prevValue} />
                </td>
                <td className="item-percent">{i.percent}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Consultancy;
