const { REACT_APP_PORTAL_BACKEND_API } = process.env;

const uploadFiles = (attachments = []) => {
  const filesPromises = attachments.map(file =>
    fetch(`${REACT_APP_PORTAL_BACKEND_API}/file/upload/feedback/feedback/attachments?_format=json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `file; filename="${file.name}"`,
      },
      body: file,
    }).then(res => res.json())
  );

  return Promise.all(filesPromises).then(filesResponses =>
    filesResponses.map(fileResponse => ({ target_id: fileResponse.fid[0].value }))
  );
};

export const sendFeedback = ({ user = '', message = '', attachments = [], extra_data }) => {
  const url = window.location.href;

  return uploadFiles(attachments).then((attachments = []) => {
    const data = {
      url: [url],
      user: [user],
      message: [message],
      extra_data: extra_data ? [JSON.stringify(extra_data)] : [],
      attachments,
    };

    return fetch(`${REACT_APP_PORTAL_BACKEND_API}/entity/feedback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  });
};
