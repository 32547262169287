import { oauth, backend } from 'api/clients';

const { REACT_APP_HOSTNAME, REACT_APP_OAUTH_API } = process.env;

export const AUTHORIZE_URL = '/oauth/authorize';
export const TOKEN_URL = '/api/v1/token';
export const RESPONSE_TYPE = 'code';
export const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code';
export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
export const CLIETN_ID = 'idmc_portal';
export const REDIRECT_URL = `${REACT_APP_HOSTNAME}/user/login`;
export const SCOPE = 'profile openid offline_access';
const IDM_ACCESS_URL = `${REACT_APP_OAUTH_API}/api/v1/idm_access`;

export const getAuthorizationUrl = state => {
  const url = new URL(AUTHORIZE_URL, REACT_APP_OAUTH_API);
  url.searchParams.append('response_type', RESPONSE_TYPE);
  url.searchParams.append('client_id', CLIETN_ID);
  url.searchParams.append('redirect_url', REDIRECT_URL);
  url.searchParams.append('scope', SCOPE);
  url.searchParams.append('state', state);

  return url.toString();
};

export const getTokens = code => {
  const payload = {
    code,
    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    client_id: CLIETN_ID,
    state: '',
  };

  return backend.post(TOKEN_URL, payload);
};

export const refreshToken = refreshToken => {
  const payload = {
    refresh_token: refreshToken,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    client_id: CLIETN_ID,
    scope: SCOPE,
  };

  return backend.post(TOKEN_URL, payload);
};

export const getUserInfo = () => oauth.get('/oauth/userInfo');
export const getApplicationContext = () => oauth.get('/api/v1/idm_access/app-context');

export const getActiveSpace = () => oauth.get(`${IDM_ACCESS_URL}/active-space`);
export const getMySpaces = () => oauth.get(`${IDM_ACCESS_URL}/my-spaces`);
export const changeActiveSpace = id => oauth.post(`${IDM_ACCESS_URL}/active-space?active_space=${id}`);
export const getSpaceAppearance = () => oauth.get(`${IDM_ACCESS_URL}/space-appearance`);
export const getActiveSpaceInfo = () => oauth.get(`${IDM_ACCESS_URL}/active-space-info`);
