import { getAccessToken, resetTokens } from 'user/context/Tokens';

const LogoutLink = ({ className = '' }) => {
  const token = getAccessToken();

  if (!token) return null;

  const logOutHandler = e => {
    e.preventDefault();
    resetTokens();
    window.location.replace(`${process.env.REACT_APP_OAUTH_API}/user/logout`);
  };

  return (
    <a onClick={logOutHandler} className={className} href="/user/logout">
      Log out
    </a>
  );
};

export default LogoutLink;
