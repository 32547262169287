import { parse } from 'date-fns';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import groupBy from 'lodash/groupBy';
import { scaleTime, scaleLinear } from 'd3-scale';

export const parseData = data => {
  if (!data.rows) {
    return [];
  }

  const { rows } = data;
  const groupedByDate = Object.entries(groupBy(rows, 'dimensionValues.1.value'));

  return groupedByDate.map(([dateStr, values]) => ({
    dateStr,
    date: parse(dateStr, 'yyyyMMdd', new Date()),
    value: values.reduce((sum, cur) => sum + parseInt(cur.metricValues[0].value), 0),
  }));
};

export const getScales = (data, xRange, yRange) => {
  const xDomain = [minBy(data, 'date').date, maxBy(data, 'date').date];
  const yDomain = [minBy(data, 'value').value, maxBy(data, 'value').value];

  return [scaleTime(xDomain, xRange), scaleLinear(yDomain, yRange).nice()];
};
