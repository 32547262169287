import { useState } from 'react';
import { MessageField } from './fields/MessageField';
import { AttachmentsField } from './fields/AttachmentsField';

export const GeneralFeedback = ({ onFormSubmit, onFormCancel }) => {
  const [message, setMessage] = useState('');
  const [images, setImages] = useState([]);

  const onSubmit = ev => {
    ev.preventDefault();
    onFormSubmit({ message, attachments: images, extra_data: { message_type: 'plain_text' } });
  };

  return (
    <form className="general-form" onSubmit={onSubmit}>
      <MessageField message={message} setMessage={setMessage} />
      <AttachmentsField files={images} setFiles={setImages} />
      <div className="form-actions">
        <button type="button" onClick={onFormCancel} className="button button-cancel">
          Cancel
        </button>
        <button type="submit" className="button button-submit">
          Send
        </button>
      </div>
    </form>
  );
};
