import { Link } from 'react-router-dom';
import { useHasPermission } from 'user/context/Session';
import DataLoader from './DataLoader';
import { TimeframeContextProvider } from './TimeframeContext';
import PeriodLabel from './DataDistribution/PeriodLabel';
import Tabs from './Tabs';
import ReportSection from './ReportSection';
import DataDistribution from './DataDistribution/DataDistribution';
import QuickOverview from './QuickOverview/QuickOverview';
import AsWorkflow from './AsWorkflow/AsWorkflow';
import './patients.scss';

const Patients = () => (
  <div className="report patients-report">
    <header className="mb-5">
      <Link to="..">{'<'} Go to all reports</Link>
      <h2 className="mb-0">IDM Reports: Antimicrobial stewardship overview</h2>
      <p className="lead mb-2">
        For period: <PeriodLabel />
      </p>
      <p>
        Allows to see various information about antimicrobial stewardship process (AS) and its results for certain time
        period. It uses IDM Alert’s finalised AS datasets as a data source.
      </p>
      <Tabs />
    </header>
    <main>
      <ReportSection id="datasets_distribution" title="Datasets distribution" className="timeframe mb-5">
        <DataDistribution />
      </ReportSection>
      <ReportSection id="quick_overview" title="Quick overview" className="quick-overview mb-5">
        <QuickOverview />
      </ReportSection>
      <ReportSection id="as_workflow" title="Antimicrobial stewardship workflow" className="as-workflow">
        <AsWorkflow />
      </ReportSection>
    </main>
  </div>
);

const Container = () => {
  const hasPermission = useHasPermission('idm_reports', 'view_report_patients');

  if (!hasPermission) {
    throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
  }

  return (
    <DataLoader>
      <TimeframeContextProvider>
        <Patients />
      </TimeframeContextProvider>
    </DataLoader>
  );
};

export default Container;
