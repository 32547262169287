import { createContext, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { sendLogs } from './api';

const levels = {
  DEBUG: 7,
  INFO: 6,
  NOTICE: 5,
  WARNING: 4,
  ERROR: 3,
  CRITICAL: 2,
  ALERT: 1,
  EMERGENCY: 0,
};

const LoggerContext = createContext();

export const useLogger = () => {
  const context = useContext(LoggerContext);
  if (context === undefined) {
    throw new Error('useLogger can be used only within LoggerProvier.');
  }
  return context;
};

const getMessage = message => {
  return `${message} (timestamp: ${Math.floor(Date.now() / 1000)}, location: ${document.location.toString()})`;
};

export const LoggerProvider = ({ children }) => {
  const logQueue = useRef([]);

  const logger = type => ({
    debug: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.DEBUG,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    info: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.INFO,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    notice: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.NOTICE,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    warning: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.WARNING,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    error: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.ERROR,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    critical: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.CRITICAL,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    alert: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.ALERT,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
    emergency: (message, options = {}) => {
      logQueue.current.push({
        id: uuid(),
        level: levels.EMERGENCY,
        message: getMessage(message),
        type,
      });

      if (options.forceFlush) {
        const logs = logQueue.current;
        logQueue.current = [];

        return sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    },
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (!logQueue.current.length) {
        return;
      }
      const logs = logQueue.current;
      logQueue.current = [];
      sendLogs(logs).catch(() => {
        logQueue.current = [...logQueue.current, ...logs];
      });
    }, process.env.REACT_APP_LOGS_SEND_INTERVAL_MS || 10000);

    const visibilityChangeListener = document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden' && logQueue.current.length) {
        const logs = logQueue.current;
        logQueue.current = [];
        sendLogs(logs).catch(e => {
          logQueue.current = [...logQueue.current, ...logs];
        });
      }
    });

    return () => {
      clearInterval(timer);
      document.removeEventListener(visibilityChangeListener);
    };
  }, []);

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};
