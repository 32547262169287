import axios from 'axios';

const baseURL = process.env.REACT_APP_PORTAL_BACKEND_API;

const backend = axios.create({ baseURL });

export const sendLogs = logs => {
  const options = { headers: {} };

  return backend.post('/api/v1/logger', logs, options);
};
