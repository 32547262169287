import { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
import { axisTop, axisBottom, axisLeft } from 'd3-axis';

const axes = {
  top: axisTop,
  bottom: axisBottom,
  left: axisLeft,
};

const DEFAULT_POSITION = 'bottom';

const Axis = ({ scale, position, offset = { top: 0, left: 0 }, options = {} }) => {
  const groupRef = useRef();
  const axisGenerator = position in axes ? axes[position] : axes[DEFAULT_POSITION];
  const translate = { left: offset.left || 0, top: offset.top || 0 };

  useEffect(() => {
    const group = groupRef.current;
    if (!group) return;

    const axis = axisGenerator(scale);

    if (options.ticks) {
      axis.ticks(options.ticks);
    }

    if (options.tickFormat) {
      axis.tickFormat(options.tickFormat);
    }

    select(group).call(axis);
  }, [groupRef, scale, axisGenerator, options.ticks, options.tickFormat]);

  return (
    <g
      ref={groupRef}
      className={`axis axis-${position}`}
      transform={`translate(${translate.left} ${translate.top})`}
    ></g>
  );
};

export default Axis;
