import classnames from 'classnames';
import { useApplicationFocus } from './Applications';
import AppLink from './AppLink';

const Sidebar = () => {
  const { applicationInFocus, setApplicationFocus } = useApplicationFocus();
  const sidebarClasses = classnames('app-desc', { 'active-app': applicationInFocus });

  return (
    <div className={sidebarClasses}>
      <div className="content">
        {applicationInFocus?.title && (
          <div className="app-desc__header">
            <h3>{applicationInFocus.title}</h3>
            <button onClick={() => setApplicationFocus(null)}>
              <i className="fas fa-times" />
            </button>
          </div>
        )}
        {applicationInFocus ? (
          <div className="app-desc__body">
            <p>{applicationInFocus.description}</p>
            <AppLink data={applicationInFocus}>Go to application</AppLink>
          </div>
        ) : (
          <div className="app-desc__news">
            <h5>Welcome to your Application Dashboard!</h5>
            <p>
              Here you can find all the spaces you are participating in. Each space contains the applications you have
              permission to access.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
