import React from 'react';
import MessageBox from '../messageBox/MessageBox';
import './ErrorMessage.scss';

const ErrorMessage = ({ children }) => {
  return (
    <MessageBox>
      <div className="message-box--error">
        <span className="error-label">Error!</span>
        <div className="error-message">{children}</div>
      </div>
    </MessageBox>
  );
};

export default ErrorMessage;
