import { useContext } from 'react';

export const toMachineName = string => {
  return string.toLocaleLowerCase().replace(new RegExp(/\s/g), '_').replace('-', '_');
};

export const createUseContextHook = (context, errorMessage) => () => {
  const ctx = useContext(context);
  if (ctx === undefined) {
    throw new Error(errorMessage);
  }
  return ctx;
};

export const getAnalyticsDomain = domains => domains.split(',').pop().trim();

export const objectToFormData = object => {
  const contentTypeHeader = { 'Content-Type': 'multipart/form-data' };
  const data = new FormData();

  for (const key in object) {
    data.append(key, object[key]);
  }

  return [data, contentTypeHeader];
};
