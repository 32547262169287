import { Link } from 'react-router-dom';
import { useFeedbackItems } from './hooks';

const FeedbackList = () => {
  const { isLoading, error, data } = useFeedbackItems();

  if (isLoading) {
    return (
      <div className="feedback-list">
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="feedback-list">
        <div>Error.</div>
      </div>
    );
  }

  return (
    <div className="feedback-list">
      <table className="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Title</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {data.map(feedback => (
            <tr key={feedback.id}>
              <td>{feedback.status}</td>
              <td>
                <Link to={feedback.id}>
                  #{feedback.id} - {feedback.user}
                </Link>
              </td>
              <td>{feedback.created}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeedbackList;
