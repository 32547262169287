import snakeCase from 'lodash/snakeCase';
import classnames from 'classnames';
import { useApplicationFocus } from './Applications';
import AppLink from './AppLink';

const AppItem = ({ data }) => {
  const { applicationInFocus } = useApplicationFocus();

  const styles = {
    backgroundImage: data.logo ? `url(${data.logo})` : 'none',
  };

  const wrapperClasses = classnames('app-item--wrapper', {
    'active-link': applicationInFocus?.id === data.id && applicationInFocus.spaceId === data.spaceId,
  });

  return (
    <div className={wrapperClasses}>
      <AppLink data={data} className={`app-item app-${snakeCase(data.id)}`}>
        <div className="app-image" style={styles} />
        <div className="app-name">{data.title}</div>
      </AppLink>
    </div>
  );
};

export default AppItem;
