const NoDataMessage = () => {
  return (
    <div data-cy="no-chart-data" className="no-data-placeholder">
      <div>
        <i className="no-data-placeholder-icon fas fa-chart-pie" />
        <br />
        Not enough data to construct this chart.
      </div>
    </div>
  );
};

export default NoDataMessage;
