import classnames from 'classnames';

export const DateField = ({ label = '', id = '', description = '', className = '', required = false }) => {
  const fieldClassNames = classnames('field field-type-date', {
    [className]: className.length > 0,
    required: required,
  });

  return (
    <div className={fieldClassNames}>
      <label htmlFor={id} className="field-label">
        {label}
      </label>
      <input type="date" id={id} name={id} className="field-input" required={required} />
      {description.length > 0 && <p className="field-description">{description}</p>}
    </div>
  );
};
