import ChartContainer from './ChartContainer';
import { getTotalViews } from 'analytics/googleAnalytics/api';

const prepareData = data => data.rows[0]?.metricValues[0]?.value || 0;

const Chart = ({ data }) => {
  return (
    <div className="total-views-wrapper">
      <div className="total-views">
        <h3 className="total-views-value">{data}</h3>
        <div className="total-views-title">views</div>
      </div>
    </div>
  );
};

const TotalViews = () => {
  return (
    <ChartContainer title="Total site views" api={getTotalViews} dataAlter={prepareData}>
      <Chart />
    </ChartContainer>
  );
};

export default TotalViews;
