import { Link } from 'react-router-dom';
import { useIsAnonymous } from 'user/context/Session';

const LoginLink = ({ text = 'Log in', className = '' }) => {
  const isAnonymous = useIsAnonymous();
  if (!isAnonymous) return null;

  return (
    <Link to="/user/login" className={className}>
      {text}
    </Link>
  );
};

export default LoginLink;
