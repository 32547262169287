import { GeneralFeedback } from './generalFeedback/GeneralFeedback';
import { DataValidationFeedback } from './dataValidationFeedback/DataValidationFeedback';

const availableForms = [
  {
    id: 'general',
    title: 'General',
    description: 'Send general feedback.',
    component: GeneralFeedback,
  },
  {
    id: 'data_validation',
    title: 'Data validation',
    description: 'Send feedback about error in data.',
    component: DataValidationFeedback,
  },
];

export const FormSelector = ({ setForm = () => {} }) => {
  return (
    <div className="feedback-form-wrapper">
      <nav className="feedback-form-selector">
        <ul className="form-list">
          {availableForms.map(formConfig => (
            <li key={formConfig.id} className="form-item">
              <button type="button" onClick={() => setForm(formConfig)} className="form-button">
                <span className="form-name">{formConfig.title}</span>
                <span className="form-description">{formConfig.description}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
