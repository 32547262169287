import { useState } from 'react';
import Modal from 'react-modal';
import toastr from 'toastr';
import Dropzone from './Dropzone';

const UploadFileButton = () => {
  const [isModalOpen, setModelOpen] = useState(false);

  const openModal = () => {
    setModelOpen(true);
  };

  const closeModal = () => {
    setModelOpen(false);
  };

  const onUploadSuccess = () => {
    closeModal();
    toastr.success('File has been uploaded.');
  };

  const onUploadFailure = (_, { files }) => {
    closeModal();
    toastr.error(`Can not upload the file: ${files[0]?.name}`, 'File failed to upload');
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="idm-drive-modal file-upload-modal">
        <div className="card">
          <div className="card-header">
            <h5 className="m-0">Upload a file</h5>
          </div>
          <div className="card-body">
            <Dropzone onUploadSuccess={onUploadSuccess} onUploadFailure={onUploadFailure} />
          </div>
        </div>
      </Modal>
      <button className="btn btn-success" onClick={openModal} title="Upload a file">
        Upload file
      </button>
    </>
  );
};

export default UploadFileButton;
