import { Outlet } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { FeedbackWidget } from 'feedbackWidget/FeedbackWidget';
import './Layout.scss';

const Layout = () => {
  return (
    <div className="app__wrapper">
      <div className="app__header__wrapper">
        <div className="container-fluid app__header">
          <header className="row">
            <div className="col">
              <Header />
            </div>
          </header>
        </div>
      </div>

      <main className="app__main">
        <div className="container-fluid d-flex flex-column">
          <div className="row flex-grow-1">
            <div className="col pt-3">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </main>
      <FeedbackWidget />
    </div>
  );
};

export default Layout;
