import { Link } from 'react-router-dom';
import { useAppPermissions } from 'user/context/Session';

const reports = [
  {
    title: 'Viruses',
    description: 'Shows orders distribution with given viruses over the time.',
    url: '/idm-reports/viruses',
    permission: ['view_report_viruses'],
  },
  {
    title: 'Antimicrobial stewardship overview',
    description:
      'Allows to see various information about antimicrobial stewardship process (AS) and its results for certain time period. It uses IDM Alert’s finalised AS datasets as a data source.',
    url: '/idm-reports/patients',
    permission: ['view_report_patients'],
  },
];

const List = ({ reports }) => (
  <nav>
    <ul className="list-group">
      {reports.map(report => (
        <li className="list-group-item" key={report.title}>
          <h3 className="fs-5 m-0">
            <Link to={report.url}>{report.title}</Link>
          </h3>
          <p className="text-secondary mb-0">{report.description}</p>
        </li>
      ))}
    </ul>
  </nav>
);

const ReportsList = () => {
  const permissions = Object.keys(useAppPermissions('idm_reports'));
  const filteredReports = reports.filter(r => r.permission.every(p => permissions.includes(p)));

  return (
    <section>
      <header>
        <h2>IDM Reports</h2>
      </header>
      <main>
        {filteredReports.length > 0 ? <List reports={filteredReports} /> : <div>You have no available reports.</div>}
      </main>
    </section>
  );
};

export default ReportsList;
