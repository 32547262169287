import { useRef, useEffect, useState } from 'react';
import { select } from 'd3-selection';
import { scaleTime } from 'd3-scale';
import { brushX } from 'd3-brush';
import { parseDate, formatDate } from './utils';
import { timeFormat } from 'd3-time-format';
import { timeMonth } from 'd3-time';
import { useTimeframeContext } from '../TimeframeContext';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import Axis from 'idmReports/chartParts/axis/Axis';

const margin = {
  top: 30,
  right: 15,
  bottom: 30,
  left: 15,
};
const barHeight = 20;
const height = margin.top + barHeight + margin.bottom;
const assumedDateLabelWidth = 65;

/**
 * Renders overlay to shade datasets that outside of timeframe.
 */
const TimeframeOverlay = ({ width, timeframe }) => {
  const [start, end] = timeframe;
  return (
    <g className="timeframe-overlay">
      <rect x={margin.left} y={margin.top} width={start - margin.left} height={barHeight} className="overlay-left" />
      <rect x={end} y={margin.top} width={width - end - margin.right} height={barHeight} className="overlay-right" />
    </g>
  );
};

const Chart = ({ container }) => {
  const { width } = container;
  const { timeframe, setTimeframe, domain, dates } = useTimeframeContext();
  const { start, end } = timeframe;
  const brushRef = useRef();
  const xScale = scaleTime()
    .domain(domain)
    .range([margin.left, width - margin.right]);
  const [timeframeSelection, setTimeframeSelection] = useState(() => [xScale(timeframe.start), xScale(timeframe.end)]);

  const brush = brushX()
    .extent([
      [margin.left, margin.top - 2],
      [width - margin.right, margin.top + barHeight + 2],
    ])
    .on('brush', ({ selection }) => {
      if (!selection) return;
      setTimeframeSelection(selection);
    })
    .on('end', ({ selection, sourceEvent }) => {
      if (!selection || !sourceEvent) return;
      const [start, end] = selection;
      setTimeframe({ start: xScale.invert(start), end: xScale.invert(end) });
    });

  useEffect(() => {
    const brushGroup = brushRef.current;
    if (!brushGroup) {
      return;
    }

    const brushSelection = select(brushGroup).call(brush);
    brushSelection
      .select('rect.selection')
      .attr('fill', 'none')
      .attr('stroke-width', '2px')
      .attr('stroke', '#016483')
      .attr('shape-rendering', 'crispEdges');
    brushSelection.select('rect.overlay').style('display', 'none');
    brush.move(brushSelection, [xScale(start), xScale(end)]);
  }, [brushRef, width, start, end]); // eslint-disable-line

  const startDateLabelX = Math.max(timeframeSelection[0], assumedDateLabelWidth);
  const endDateLabelX = Math.min(timeframeSelection[1], width - assumedDateLabelWidth);

  return (
    <svg width={width} height={height}>
      <Axis scale={xScale} position="top" offset={{ top: margin.top - 1 }} />
      <Axis
        scale={xScale}
        position="bottom"
        offset={{ top: margin.top + barHeight }}
        options={{ ticks: timeMonth.every(3), tickFormat: timeFormat('Q%q') }}
      />
      <rect
        x={margin.left}
        y={margin.top}
        width={width - margin.left - margin.right}
        height={barHeight}
        className="background"
      />
      <g className="datasets">
        {dates.map(d => (
          <line
            x1={xScale(parseDate(d))}
            x2={xScale(parseDate(d))}
            y1={margin.top}
            y2={height - margin.bottom}
            className="dataset"
            key={d}
          />
        ))}
      </g>
      <TimeframeOverlay width={width} timeframe={timeframeSelection} />
      <line
        x1={timeframeSelection[0]}
        x2={timeframeSelection[0]}
        y1={11}
        y2={margin.top}
        className="timeframe-label-line label-line-start"
      />
      <text x={startDateLabelX} y={1} className="timeframe-label label-start">
        {formatDate(xScale.invert(timeframeSelection[0]))}
      </text>
      <line
        x1={timeframeSelection[1]}
        x2={timeframeSelection[1]}
        y1={margin.top + barHeight}
        y2={margin.top + barHeight + 18}
        className="timeframe-label-line label-line-start"
      />
      <text x={endDateLabelX} y={margin.top + barHeight + 18} className="timeframe-label label-end">
        {formatDate(xScale.invert(timeframeSelection[1]))}
      </text>
      <g ref={brushRef} className="brush" />
    </svg>
  );
};

const Timeframe = () => (
  <ResponsiveContainer>
    <Chart />
  </ResponsiveContainer>
);

export default Timeframe;
