import IncludedPatients from './IncludedPatients';
import ReviewedByAlgorithm from './ReviewedByAlgorithm';
import Consultancy from './Consultancy';
import IncludedSpecialties from './IncludedSpecialties';
import AlertsType from './AlertsType';
import AdjustmentsType from './AdjustmentsType';
import './QuickOverview.scss';

const QuickOverview = () => {
  return (
    <div className="charts">
      <IncludedPatients />
      <ReviewedByAlgorithm />
      <Consultancy />
      <IncludedSpecialties />
      <AlertsType />
      <AdjustmentsType />
    </div>
  );
};

export default QuickOverview;
