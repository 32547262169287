import { Outlet } from 'react-router-dom';
import { useHasPermission } from 'user/context/Session';
import FeedbackList from './FeedbackList';
import FeedbackItem from './FeedbackItem';

export const feedbackRoutes = [
  {
    index: true,
    element: <FeedbackList />,
  },
  {
    path: ':feedbackId',
    element: <FeedbackItem />,
  },
];

const Feedback = () => {
  const isUserHasPermission = useHasPermission('feedback', 'manager');

  if (!isUserHasPermission) {
    throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
  }

  return <Outlet />;
};

export default Feedback;
