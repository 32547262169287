import { useIsAnonymous } from 'user/context/Session';
import LoginLink from './LoginLink';
import LogoutLink from './LogoutLink';
import userLogo from 'img/user_icon.png';

const Dropdown = ({ username }) => {
  const isAnonymous = useIsAnonymous();

  return (
    <div className="user-dropdown dropdown">
      <div className="user-menu-logo">
        <img src={userLogo} alt="user_logo" />
      </div>

      <div className="user-menu-button--wrapper">
        <button
          className="user-menu-button btn btn-link dropdown-toggle"
          id="userMenuToggler"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {username}
        </button>

        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="userMenuToggler">
          {!isAnonymous && (
            <a
              className="dropdown-item"
              href={process.env.REACT_APP_OAUTH_API}
              target="_blank"
              rel="noopener noreferrer"
            >
              Manage account (OAuth)
            </a>
          )}
          <LoginLink className="dropdown-item" />
          <LogoutLink className="dropdown-item" />
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
