import { useMutation, useQueryClient } from '@tanstack/react-query';
import toastr from 'toastr';
import { deleteDownloadToken } from 'healthDrive/api';

const RemovePermanentLink = ({ item, text = '', title = '', className = '' }) => {
  const queryClient = useQueryClient();
  const { path, downloadToken: token } = item;

  const removePermanentLinkMutation = useMutation(({ token, path }) => deleteDownloadToken(token), {
    onSuccess: (_, { path }) => {
      const decodedPath = decodeURIComponent(path);
      const dirPath = decodedPath.split('/').slice(0, -1).join('/');
      queryClient.invalidateQueries(['listDirectory', dirPath]);
      queryClient.invalidateQueries(['download-tokens']);
      toastr.success('Permanent link has been removed.');
    },
    onError: error => {
      toastr.error('Failed to remove permanent link.');
      console.dir(error);
    },
  });

  const onButtonClick = e => {
    e.preventDefault();
    removePermanentLinkMutation.mutate({ token, path });
  };

  return (
    <button onClick={onButtonClick} title={title} className={className}>
      <i className="fas fa-unlink" /> {text}
    </button>
  );
};

export default RemovePermanentLink;
