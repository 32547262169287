import { useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadFile } from 'healthDrive/api';

const UploadProgress = ({ progress, isUploading }) => {
  if (!isUploading) return null;

  const { filename, total, loaded } = progress;
  const loadedInPercents = ((loaded * 100) / total).toFixed(1);

  return (
    <div className="upload-file-progress">
      <h6 className="mb-1">{filename}:</h6>
      <div className="progress mb-2">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={{ width: `${loadedInPercents}%` }}
          aria-valuenow={loadedInPercents}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {loadedInPercents}%
        </div>
      </div>
    </div>
  );
};

const Dropzone = ({ onUploadSuccess = () => {}, onUploadFailure = () => {} }) => {
  const { pathname } = useLocation();
  const [progress, setProgress] = useState({
    filename: '',
    total: 0,
    loaded: 0,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(({ files, path, onUploadProgress }) => uploadFile(files[0], path, onUploadProgress), {
    onSuccess: (_, args) => {
      queryClient.invalidateQueries(['listDirectory', args.path]);
      onUploadSuccess();
    },
    onError: (error, variables) => {
      onUploadFailure(error, variables);
    },
  });

  const onUploadProgress = files => ev => {
    setProgress({ filename: files?.[0].name, total: ev.total, loaded: ev.loaded });
  };

  const onDropAccepted = files => {
    const path = pathname.replace('/files', '');
    mutation.mutate({ files, path, onUploadProgress: onUploadProgress(files) });
  };

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted,
    multiple: false,
    maxSize: 2048 * 1024 * 1024,
    disabled: mutation.isLoading,
  });

  const style = useMemo(() => {
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    };

    const focusedStyle = {
      borderColor: '#2196f3',
    };

    const acceptStyle = {
      borderColor: '#00e676',
    };

    const rejectStyle = {
      borderColor: '#ff1744',
    };

    return {
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
  }, [isFocused, isDragAccept, isDragReject]);

  return (
    <div className="react-dropzone-container">
      <UploadProgress progress={progress} isUploading={mutation.isLoading} />
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
      </div>
    </div>
  );
};

export default Dropzone;
