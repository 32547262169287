import { Link, useParams } from 'react-router-dom';
import { useGetOrder } from '../api';

import PersonalInfo from './sections/PersonalInfo';
import Location from './sections/Location';

const Order = () => {
  const { id } = useParams();
  const { isLoading, error, data } = useGetOrder(id);

  if (isLoading) return <div>Loading...</div>;

  if (error)
    return (
      <div>
        <Link to="..">{'<'} Go to orders list</Link>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );

  return (
    <div className="row">
      <div className="col-12">
        <Link to="..">{'<'} Go to orders list</Link>
      </div>
      <div className="col-12">
        <PersonalInfo data={data} />
      </div>
      <div className="col-12">
        <Location data={data} />
      </div>
    </div>
  );
};

export default Order;
