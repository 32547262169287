import { createContext } from 'react';
import { createUseContextHook } from 'utility/utils';
import { getAccessToken } from 'user/context/Session';
import { useRequest } from 'utility/hooks';
import { getActiveSpace } from 'user/api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const ActiveSpaceContext = createContext();

export const useActiveSpace = createUseContextHook(
  ActiveSpaceContext,
  'useActiveSpace can be used only within ActiveSpaceProvider.'
);

export const ActiveSpaceProvider = ({ children }) => {
  const token = getAccessToken();
  const { isLoading, data } = useRequest(getActiveSpace, [], { execute: !!token, initialLoading: !!token });

  if (isLoading) return <IndicatorWithText text="Loading active space..." />;

  return <ActiveSpaceContext.Provider value={data}>{children}</ActiveSpaceContext.Provider>;
};
