import { NavLink } from 'react-router-dom';
import { useRemoteDirectory } from '../hooks';

const SideMenu = () => {
  const { isLoading, isRefetching, error, content } = useRemoteDirectory('/');

  const mapping = new Map([
    ['my', 'My Files'],
    ['spaces', 'Spaces'],
    ['shared', 'Shared with me'],
  ]);

  if (isLoading || isRefetching)
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="alert alert-danger">
        <h4 className="alert-heading">Ooops!</h4>
        <hr />
        Error. Something went wrong.
      </div>
    );

  return (
    <nav>
      <ul className="nav nav-pills flex-column">
        {content.map(dir => (
          <li key={dir.name}>
            <NavLink to={dir.name} className="nav-link">
              {mapping.get(dir.name) ?? dir.name}
            </NavLink>
          </li>
        ))}
        <li>
          <hr className="my-1" />
        </li>
        <li>
          <NavLink to="links/permanent" className="nav-link">
            Permanent links
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SideMenu;
