import { Fragment } from 'react';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import { getReducedData } from 'idmReports/patients/utils';
import { calculateWaterfallLayout, getGradientSettings } from './utils';
import Steps from './Steps';
import StepDetails from './StepDetails';
import './AsWorkflow.scss';
import { useTimeframeContext } from '../TimeframeContext';

/**
 * Defines gradients fill for links.
 */
const Gradients = () => {
  const gradients = getGradientSettings();

  return (
    <defs>
      {gradients.map(g => (
        <linearGradient id={g.id} x1="0" x2="0" y1="0" y2="1" key={g.id}>
          <stop offset="0%" stopColor={g.from} />
          <stop offset="100%" stopColor={g.to} />
        </linearGradient>
      ))}
    </defs>
  );
};

/**
 * Defines lines to separate steps.
 */
const StepLines = ({ lines }) => (
  <g className="step-lines">
    {lines.map(l => (
      <line x1={l.x1} x2={l.x2} y1={l.y1} y2={l.y2} className="step-line" key={l.y1} />
    ))}
  </g>
);

/**
 * Defines links between nodes.
 */
const Links = ({ links }) => (
  <>
    <g className="links">
      {links.map(l => (
        <Fragment key={l.id}>
          <path d={l.geometry} fill="white" />
          <path d={l.geometry.path} fill={`url(#${l.id})`} className={`link ${l.id}`}>
            <title>
              From "{l.source.title}" ({l.source.value} patients) to "{l.target.title}" ({l.target.value} patients,{' '}
              {((l.target.value * 100) / l.source.value).toFixed()}%)
            </title>
          </path>
        </Fragment>
      ))}
    </g>
    <g className="links-labels">
      {links.map(l => {
        const linkPercentageLabelLegth = `${((l.target.value * 100) / l.source.value).toFixed()}%`.length * 8;

        if (linkPercentageLabelLegth > l.geometry.sourceWidth) {
          return null;
        }

        return (
          <text
            x={l.geometry.sourceMidX}
            y={l.source.y1 + 10}
            className={`link-percentage-label label-${l.target.id} label`}
            key={`${l.source.id}-${l.target.id}`}
          >
            {((l.target.value * 100) / l.source.value).toFixed()}%
          </text>
        );
      })}
    </g>
  </>
);

/**
 * Defines waterfall nodes.
 */
const Nodes = ({ nodes }) => (
  <>
    <g className="nodes">
      {nodes.map(node => (
        <rect
          x={node.x0}
          y={node.y0}
          width={node.x1 - node.x0}
          height={node.y1 - node.y0}
          className={`node node-${node.id}`}
          fill={node.color}
          key={node.title}
        >
          <title>
            {node.title} - {node.value} patients
          </title>
        </rect>
      ))}
    </g>
    <g className="node-labels">
      {nodes.map(n => {
        const nodeNameLength = n.title.length * 8;
        const nodeValueLength = n.value.toString().length * 8;

        return (
          <Fragment key={n.id}>
            {nodeValueLength < n.width && (
              <text x={n.midX} y={n.midY + 2} key={n.id} className={`value-label label-${n.id} label`}>
                {n.value}
              </text>
            )}
            {nodeNameLength < n.width && (
              <text x={n.midX} y={n.y0 - 10} className={`node-name-label label-${n.id} label`}>
                {n.title}
              </text>
            )}
          </Fragment>
        );
      })}
    </g>
  </>
);

/**
 * Defines a chart for no data scenario.
 */
const EmptyWaterfallChart = ({ width, height, lines, titleX = 0, titleY = 0 }) => (
  <svg width={width} height={height}>
    <g className="step-lines">
      {lines.map(l => (
        <line x1={l.x1} x2={l.x2} y1={l.y1} y2={l.y2} className="step-line" key={l.y1} />
      ))}
    </g>
    <text x={titleX} y={titleY} className="no-data-message">
      No data
    </text>
  </svg>
);

/**
 * Defines AsWorkflow chart.
 */
const AsWorkflowChart = ({ container, data }) => {
  const { width } = container;
  const { height, nodes, links, lines } = calculateWaterfallLayout(data, width);

  if (!nodes.length) {
    return <EmptyWaterfallChart width={width} height={height} lines={lines} titleX={width / 2} titleY={30} />;
  }

  return (
    <svg width={width} height={height}>
      <Gradients />
      <Links links={links} />
      <StepLines lines={lines} />
      <Nodes nodes={nodes} />
    </svg>
  );
};

const SectionDescription = () => (
  <details>
    <summary>What is it?</summary>
    <div className="content">
      <p>
        This chart shows an overall antimicrobial stewardship workflow from automatic patients selection and filtering
        to manual review process.
      </p>
      <p>
        Here you can see three columns. The first column contains a waterfall chart that shows how many patients on each
        step were selected and how many of them went to next steps. The second column contains descriptions for each
        step. The third column contains charts that show some extra statistics about data within a step.
      </p>
    </div>
  </details>
);

/**
 * Defines AsWorkflow container.
 */
const AsWorkflow = () => {
  const { data } = useTimeframeContext();
  const report = getReducedData(data);

  return (
    <>
      <SectionDescription />
      <div className="columns">
        <div className="column chart">
          <ResponsiveContainer>
            <AsWorkflowChart data={report} />
          </ResponsiveContainer>
        </div>
        <Steps />
        <StepDetails data={report} />
      </div>
    </>
  );
};

export default AsWorkflow;
