import './unexpectedErrorFallback.scss';

const UnexpectedErrorFallback = () => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <section className="unexpected-error__container">
          <h2 className="unexpected-error__header">Something went wrong.</h2>
          <p className="unexpected-error__message">
            We are notified, and we are on it. We’ll fix it as soon as possible. <br /> Thank you for understanding!
          </p>
          <p>
            <a href="/">Go to main page</a>
          </p>
        </section>
      </div>
    </div>
  </div>
);

export default UnexpectedErrorFallback;
