import classnames from 'classnames';

const TrendIndicator = ({ value, prevValue, reverse = false }) => {
  if (value === undefined || prevValue === undefined) return null;

  const isUpward = value > prevValue;
  const isDownward = value < prevValue;
  const isNotChanged = value === prevValue;

  const indicatorClassnames = classnames({
    'text-danger': isUpward && !reverse,
    'text-success': isDownward && !reverse,
    'text-warning': isNotChanged,
  });

  const text = isNotChanged ? '' : isUpward ? '▲' : '▼';
  const titleStatus = isNotChanged ? 'not changed' : isUpward ? 'increased' : 'decreased';

  return (
    <span
      className={indicatorClassnames}
      title={`Value is ${titleStatus} compared to previous period. Value for the previous period - ${prevValue}`}
      style={{ fontSize: '0.8em', marginInlineStart: '0.2em' }}
    >
      {text}
    </span>
  );
};

export default TrendIndicator;
