import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { listDirectory } from './api';
import { healthDriveConfig } from './HealthDrive';

/**
 * Maps current location to remote path.
 */
export const useRemotePath = () => {
  const { pathname } = useLocation();
  return pathname.replace(healthDriveConfig.ROOT_PATH, '');
};

export const useRemoteDirectory = remotePath => {
  const urlBasedPath = useRemotePath();
  const path = remotePath ?? urlBasedPath;
  const query = useQuery(['listDirectory', path], () => listDirectory(path));

  return { ...query, content: query.data?.data || [] };
};

export const useParentFolder = () => {
  const { pathname } = useLocation();
  const pathArray = pathname.split('/').slice(0, -1);

  return pathArray.join('/');
};
