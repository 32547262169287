import { subMonths, isValid, format } from 'date-fns';

const isPeriodEmpty = period => {
  if (!period) return true;
  if (!period.start || !period.end) return true;
  return false;
};

export const getInitialTimeframe = dataPeriod => {
  if (isPeriodEmpty(dataPeriod)) {
    const now = new Date();
    return {
      start: subMonths(now, 5),
      end: now,
    };
  }

  return { start: subMonths(dataPeriod.end, 5), end: dataPeriod.end };
};

export const formatDate = date => (isValid(date) ? format(date, 'dd MMMM yyyy') : '[invalid-date]');
