const NoDataDonut = ({ center, outerRadius = 100, innerRadius = 30, side = 100 }) => {
  return (
    <svg width={side} height={side}>
      <g>
        <circle cx={center[0]} cy={center[1]} r={outerRadius} fill="#ddd" />
        <circle cx={center[0]} cy={center[1]} r={innerRadius} fill="#f5f5f5" />
      </g>
      <text x={center[0]} y={center[1]} className="label" style={{ dominantBaseline: 'middle' }}>
        No data
      </text>
    </svg>
  );
};

export default NoDataDonut;
