import { Link } from 'react-router-dom';
import { useHasPermission } from 'user/context/Session';
import DataLoader from './DataLoader';
import TooltipProvider from 'idmReports/tooltip/Tooltip';
import DataDistribution from './dataDistribution/DataDistribution';
import TimeframeProvider from './timeframe/TimeframeProvider';
import Timeframe from './timeframe/Timeframe';
import DataInFrameProvider from './DataInFrameProvider';
import InFocusProvider from './InFocusProvider';
import VirusesDynamics from './virusesDynamics/VirusesDynamics';
import OrdersDynamics from './ordersDynamics/OrdersDynamics';
import MostFrequentVirus from './mostFrequentVirus/MostFrequentVirus';
import VirusesRatio from './virusesRatio/VirusesRatio';
import { prepareData, getDataTimePeriod } from './calculations';
import './viruses.scss';

const Viruses = ({ report }) => {
  const data = prepareData(report);
  const dataTimePeriod = getDataTimePeriod(data);

  return (
    <>
      <div className="mb-2">
        <Link to="..">{'<'} Go to all reports</Link>
      </div>
      <header>
        <h2>IDM Reports: Viruses</h2>
        <p>This report shows orders distribution with given viruses over the time.</p>
      </header>
      <main className="row">
        <TimeframeProvider dataTimePeriod={dataTimePeriod}>
          <DataInFrameProvider data={data}>
            <InFocusProvider>
              <TooltipProvider>
                <div className="col-9">
                  <DataDistribution data={data} />
                  <Timeframe />
                  <VirusesDynamics />
                  <OrdersDynamics />
                </div>
                <div className="col-3">
                  <h4>Quick insights</h4>
                  <MostFrequentVirus />
                  <VirusesRatio />
                </div>
              </TooltipProvider>
            </InFocusProvider>
          </DataInFrameProvider>
        </TimeframeProvider>
      </main>
    </>
  );
};

const Container = () => {
  const hasPermission = useHasPermission('idm_reports', 'view_report_viruses');

  if (!hasPermission) {
    throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
  }

  return (
    <DataLoader>
      <Viruses />
    </DataLoader>
  );
};

export default Container;
