import { useNavigate } from 'react-router-dom';
import { useLogger } from 'logger/LoggerProvider';
import { useApplicationFocus } from './Applications';
import { useActiveSpace, useUser } from 'user/context/Session';
import { changeActiveSpace } from 'user/api';

const AppLink = ({ data, children, className = '' }) => {
  const logger = useLogger()('portal_applications');
  const user = useUser();
  const navigate = useNavigate();
  const { applicationInFocus, setApplicationFocus, setSpaceSwitching } = useApplicationFocus();
  const activeSpace = useActiveSpace();
  const activeSpaceId = activeSpace.id;
  const isRelative = data.url[0] === '/';

  const getLogMessage = (appTitle, appId, userName) =>
    `The "${appTitle}" (${appId}) application has been run by "${userName}"
     from the Applications Select Screen within the "${activeSpace.name}" space.`;

  const onRelativeLinkClick = e => {
    e.preventDefault();

    if (!isRelative) {
      // We actually do not switch space. It's just to show loading spinner while sending logs.
      setSpaceSwitching(true);
      logger.info(getLogMessage(data.title, data.id, user.name), { forceFlush: true }).finally(() => {
        document.location.assign(data.url);
      });
      return;
    }

    if (data.spaceId === activeSpaceId) {
      logger.info(getLogMessage(data.title, data.id, user.name));
      navigate(data.url);
      return;
    }

    setSpaceSwitching(true);
    changeActiveSpace(data.spaceId)
      .then(() => {
        logger.info(getLogMessage(data.title, data.id, user.name), { forceFlush: true }).finally(() => {
          document.location.assign(data.url);
        });
      })
      .catch(err => {
        console.dir(err);
        setSpaceSwitching(false);
      });
  };

  const linkHandler = (e, spaceId) => {
    e.preventDefault();

    if (applicationInFocus?.id === data.id && applicationInFocus?.spaceId === data.spaceId) {
      onRelativeLinkClick(e, spaceId);
    } else {
      setApplicationFocus(data);
    }
  };

  return (
    <a href={data.url} className={className} onClick={linkHandler}>
      {children}
    </a>
  );
};

export default AppLink;
