import classnames from 'classnames';

export const CheckboxField = ({
  label = '',
  id = '',
  description = '',
  className = '',
  checked = false,
  onChange = () => {},
}) => {
  const fieldClassNames = classnames('field field-type-checkbox', {
    [className]: className.length > 0,
    checked: checked,
  });

  return (
    <div className={fieldClassNames}>
      <input type="checkbox" name={id} id={id} onChange={onChange} className="field-input" checked={checked} />
      <label htmlFor={id} className="field-label">
        {label}
      </label>
      {description.length > 0 && <p className="field-description">{description}</p>}
    </div>
  );
};
