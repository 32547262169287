import kebabCase from 'lodash/kebabCase';
import { line, curveCardinal } from 'd3-shape';
import classnames from 'classnames';
import ResponsiveContainer from 'idmReports/chartParts/responsiveContainer/responsiveContainer';
import { useTimeframe } from '../timeframe/TimeframeProvider';
import { useDataInFrame } from '../DataInFrameProvider';
import { useInFocus } from '../InFocusProvider';
import { getScales, groupByVirusName, isPointDefined } from './calculations';
import Axis from 'idmReports/chartParts/axis/Axis';
import Legend from 'idmReports/chartParts/legend/Legend';
import Tooltip from './Tooltip';
import { getDurationLength } from '../calculations';
import { useTooltip } from 'idmReports/tooltip/Tooltip';
import './virusesDynamics.scss';

const height = 300;
const margin = {
  top: 15,
  right: 15,
  bottom: 30,
  left: 30,
};

const Series = ({ data, xScale, yScale }) => {
  const { setContent, setVisibility, setCoords } = useTooltip();
  const [inFocus] = useInFocus();
  const [seriesName, seriesData] = data;
  const lineGenerator = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.value))
    .defined(isPointDefined)
    .curve(curveCardinal.tension(0.3));

  const seriesClasses = classnames('series', kebabCase(seriesName), {
    'in-focus': inFocus === seriesName,
    'not-in-focus': inFocus && inFocus !== seriesName,
  });

  const dayWidth = getDurationLength(xScale, { days: 1 });
  const pointXRadius = (dayWidth - dayWidth * 0.3) / 2;
  const pointYRadius = (yScale(1) - yScale(2)) / 2;
  const pointRadius = Math.max(Math.min(pointXRadius, pointYRadius), 1);

  const mouseOverHandler = (e, d) => {
    setContent(<Tooltip data={d} />);
    setCoords({ x: e.pageX, y: e.pageY });
    setVisibility(true);
  };

  const mouseOutHandler = () => {
    setVisibility(false);
  };

  return (
    <g className={seriesClasses}>
      <path d={lineGenerator(seriesData)} className="line" />
      {seriesData.map(p => (
        <circle
          key={`${p.name}-${p.timestamp}`}
          cx={xScale(p.date)}
          cy={yScale(p.value)}
          r={pointRadius}
          className="point"
          onMouseOver={e => {
            mouseOverHandler(e, p);
          }}
          onMouseOut={mouseOutHandler}
        />
      ))}
    </g>
  );
};

const Chart = ({ container }) => {
  const [timeframe] = useTimeframe();
  const data = useDataInFrame();
  const { width } = container;
  const xRange = [margin.left, width - margin.right];
  const yRange = [height - margin.bottom, margin.top];
  const [xScale, yScale] = getScales(data, [timeframe.start, timeframe.end], xRange, yRange);
  const virusesData = groupByVirusName(data);

  return (
    <svg height={height} width={width} className="chart viruses-dynamics">
      <defs>
        <clipPath id="chartContent">
          <rect
            x={margin.left}
            y={margin.top}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
          />
        </clipPath>
      </defs>
      <Axis position="left" scale={yScale} offset={{ left: margin.left }} />
      <Axis position="bottom" scale={xScale} offset={{ top: height - margin.bottom }} />
      <g className="content" clipPath="url('#chartContent')">
        {virusesData.map(v => (
          <Series key={v[0]} data={v} xScale={xScale} yScale={yScale} />
        ))}
      </g>
    </svg>
  );
};

const VirusesDynamics = () => {
  return (
    <section className="section viruses-dynamics mt-5">
      <header>
        <h4>Viruses dynamics</h4>
        <p>Allows to track each virus dynamics over time.</p>
      </header>
      <main className="content">
        <ResponsiveContainer>
          <Chart />
        </ResponsiveContainer>
        <Legend />
      </main>
    </section>
  );
};

export default VirusesDynamics;
