import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const crumbs = pathname.split('/').slice(1);
  const appBasePath = crumbs.shift();
  const currentDirectory = crumbs.pop();
  const separatorSymbol = '‣';

  return (
    <div className="breadcrumbs">
      <i className="fas fa-folder-open opacity-25 fs-6" />{' '}
      {crumbs.map((crumb, index, arr) => {
        const path = crumbs.slice(0, index + 1).join('/');
        return (
          <Fragment key={crumb}>
            <Link to={`/${appBasePath}/${path}`}>{decodeURIComponent(crumb)}</Link>
            {index !== arr.length - 1 && <span className="folders-separator"> {separatorSymbol} </span>}
          </Fragment>
        );
      })}
      <span>
        {crumbs.length > 0 && <span className="folders-separator"> {separatorSymbol} </span>}
        {decodeURIComponent(currentDirectory)}
      </span>
    </div>
  );
};

export default Breadcrumbs;
