import { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
import { brushX } from 'd3-brush';
import throttle from 'lodash/throttle';
import { format } from 'date-fns';
import ResponsiveContainer from 'idmReports/chartParts/responsiveContainer/responsiveContainer';
import { useTimeframe } from '../timeframe/TimeframeProvider';
import { useInFocus } from '../InFocusProvider';
import { getScales } from './calculations';
import Axis from 'idmReports/chartParts/axis/Axis';
import { getDurationLength } from '../calculations';
import './dataDistribution.scss';

const height = 90;
const margin = {
  left: 20,
  top: 20,
  right: 20,
  bottom: 38,
};
const timeframeBoundaryTextWidth = 80;

const Chart = ({ container, data }) => {
  const [timeframe, setTimeframe] = useTimeframe();
  const [inFocus] = useInFocus();
  const { width } = container;
  const brushRef = useRef();
  const xRange = [margin.left, width - margin.right];
  const filterUnfocusedData = data.map(g => ({
    ...g,
    groups: g.groups.filter(i => (inFocus ? i.key === inFocus : true)),
  }));
  const withoutEmptyGroups = filterUnfocusedData.filter(g => g.groups.length > 0);
  const [xScale, colorScale] = getScales(filterUnfocusedData, xRange);

  useEffect(() => {
    const brushGroup = brushRef.current;
    if (!brushGroup) {
      return;
    }

    const onBrush = throttle(({ selection }) => {
      if (!selection) return;
      const [start, end] = selection;
      setTimeframe({ start: xScale.invert(start), end: xScale.invert(end) });
    }, 50);

    const brush = brushX()
      .extent([
        [margin.left, margin.top - 1],
        [width - margin.right, height - margin.bottom + 1],
      ])
      .on('brush end', onBrush);

    const brushSelection = select(brushGroup).call(brush);
    brushSelection.select('rect.selection').attr('fill', 'none').attr('stroke', '#000').attr('stroke-width', '3px');
    brush.move(brushSelection, [xScale(timeframe.start), xScale(timeframe.end)]);
  }, [brushRef, width]); // eslint-disable-line

  const barWidth = getDurationLength(xScale, { days: 1 });

  return (
    <svg width={width} height={height}>
      <text
        x={Math.max(xScale(timeframe.start) - timeframeBoundaryTextWidth + 10, margin.left)}
        y="15"
        className="timeframe-boundary start"
      >
        {format(timeframe.start, 'dd MMM yyyy')} ⇤
      </text>
      <text
        x={Math.min(xScale(timeframe.end) - 10, width - timeframeBoundaryTextWidth - margin.right)}
        y={height - margin.bottom}
        dy="13"
        className="timeframe-boundary end"
      >
        ⇥ {format(timeframe.end, 'dd MMM yyyy')}
      </text>
      <Axis position="bottom" scale={xScale} offset={{ top: height - 21 }} />
      <rect
        x={margin.left}
        y={margin.top}
        width={width - margin.left - margin.right}
        height={height - margin.top - margin.bottom}
        fill={colorScale(0)}
      />
      {withoutEmptyGroups.map(d => (
        <rect
          key={d.key}
          x={xScale(d.date)}
          y={margin.top}
          width={barWidth}
          height={height - margin.top - margin.bottom}
          fill={colorScale(d.value + 10)}
        />
      ))}
      <g ref={brushRef} className="brush" />
    </svg>
  );
};

const DataHeatmap = ({ data }) => {
  return (
    <section className="data-heatmap">
      <header>
        <h4>Data distribution</h4>
        <p className="m-0">
          Shows data distribution over the whole time period. More saturated lines indicate days with bigger amount of
          data.
        </p>
        <p>
          Also, this chart allows you to select a time frame. All other charts will display information according to
          data within selected time frame.
        </p>
      </header>
      <main>
        <ResponsiveContainer>
          <Chart data={data} />
        </ResponsiveContainer>
      </main>
    </section>
  );
};

export default DataHeatmap;
