import snakeCase from 'lodash/snakeCase';

const ReportSection = ({ title = '', id, className = '', children }) => {
  return (
    <section id={id || snakeCase(title)} className={'section ' + className}>
      {title.length > 0 && <h3>{title}</h3>}
      {children}
    </section>
  );
};

export default ReportSection;
