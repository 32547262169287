import { Outlet, Navigate } from 'react-router-dom';
import GoogleAnalytics from './googleAnalytics/GoogleAnalytics';

export const analyticsRoutes = [
  {
    index: true,
    element: <Navigate to="/" />,
  },
  {
    path: ':analyticsApp',
    element: <GoogleAnalytics />,
  },
];

const Analytics = () => <Outlet />;

export default Analytics;
