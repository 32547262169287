import { scaleTime, scaleLinear } from 'd3-scale';
import max from 'lodash/max';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { differenceInDays } from 'date-fns';

const MIN_MAX_VALUE = 10;

export const getScales = (data, xDomain, xRange, yRange) => {
  const values = data.flatMap(i => i.groups.map(g => g.value));
  const yDomain = [0, Math.max(MIN_MAX_VALUE, max(values) + 1)];

  return [scaleTime(xDomain, xRange), scaleLinear(yDomain, yRange)];
};

export const getVirusesList = data => {
  const names = data.flatMap(i => i.groups.map(d => d.key));

  return uniq(names);
};

export const groupByVirusName = data => {
  const flattenData = data.flatMap(i =>
    i.groups.map(g => ({
      timestamp: i.key,
      date: i.date,
      dateStr: i.dateStr,
      name: g.key,
      value: g.value,
    }))
  );

  return Object.entries(groupBy(flattenData, 'name'));
};

export const isPointDefined = (point, index, points) => {
  const currentPointDate = point.date;
  const nextPointDate = points[index + 1]?.date;

  if (!nextPointDate) return false;

  const diff = differenceInDays(nextPointDate, currentPointDate);

  if (diff > 1) return false;

  return true;
};
