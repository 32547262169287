import { fromUnixTime, format, isWithinInterval, addDays, add } from 'date-fns';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

export const prepareData = (report = []) =>
  report.map(r => {
    const date = fromUnixTime(r.key / 1000);
    const dateStr = format(date, 'yyyy-MM-dd');
    const value = r.groups.reduce((s, i) => s + i.value, 0);

    return { ...r, date, dateStr, value };
  });

const inFrame = (date, frame) => isWithinInterval(date, frame);

export const getDataInFrame = (data, timeframe) => {
  if (!timeframe) return data;

  const extendedTimeframe = {
    start: addDays(timeframe.start, -2),
    end: addDays(timeframe.end, 2),
  };

  return data.filter(i => inFrame(i.date, extendedTimeframe));
};

/**
 * Returns length of specified time duration in context of a given scale.
 *
 * @param {callback} scale - A D3's scale function.
 * @see https://github.com/d3/d3-scale
 *
 * @param {Object} interval - A date-fns' duration object.
 * @see https://date-fns.org/docs/Duration
 *
 * @returns {number} Duration length.
 */
export const getDurationLength = (scale, interval) => {
  const start = new Date();
  const end = add(start, interval);
  const width = scale(end) - scale(start);

  return width;
};

export const getDataTimePeriod = data => {
  const periodStart = minBy(data, 'key').date;
  const periodEnd = maxBy(data, 'key').date;

  return { start: periodStart, end: periodEnd };
};
