import { getDownloadToken, getPreviewLink } from 'healthDrive/api';

const PreviewFile = ({ path, className = '' }) => {
  const onButtonClick = e => {
    e.preventDefault();
    getDownloadToken(path)
      .then(res => {
        window.open(getPreviewLink(res.data.token), '_blank').focus();
      })
      .catch(err => {
        console.dir(err);
      });
  };

  return (
    <button onClick={onButtonClick} className={className}>
      <i className="fas fa-eye" /> Preview
    </button>
  );
};

export default PreviewFile;
