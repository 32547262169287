import { useState } from 'react';
import kebabCase from 'lodash/kebabCase';
import classnames from 'classnames';
import { TextField } from '../../fields/TextField';
import { TextAreaField } from '../../fields/TextAreaField';
import { CheckboxField } from '../../fields/CheckboxField';
import { DateField } from '../../fields/DateField';
import './DataValidationFeedback.scss';

const IC_SECTIONS = [
  'Algorithms',
  'Chemistry & Hematology',
  'Ward migrations',
  'Actions',
  'Medication',
  'Microbiology',
];

const SectionErrorFieldGroup = ({ sectionName }) => {
  const [hasError, setHasError] = useState(false);
  const groupClassNames = classnames(`section-error-field-group section-${kebabCase(sectionName)}`, {
    'has-error': hasError,
  });

  return (
    <div className={groupClassNames}>
      <CheckboxField
        id={`${sectionName}_has_error`}
        label={`Error in section "${sectionName}"`}
        className="field-section-has-error"
        checked={hasError}
        onChange={() => {
          setHasError(v => !v);
        }}
      />
      <DateField
        id={`${sectionName}_error_date`}
        label="Error date"
        className="field-error-date"
        required={hasError}
        description="Specify date with invalid data."
      />
      <TextAreaField
        label="Error description"
        id={`${sectionName}_error_description`}
        className="field-error-description"
        required={hasError}
        description="Briefly describe what is wrong with data or its presentation."
      />
    </div>
  );
};

export const DataValidationFeedback = ({ onFormSubmit, onFormCancel }) => {
  const onSubmit = ev => {
    ev.preventDefault();
    const data = {
      sections: IC_SECTIONS.map(sectionName => {
        const sectionHasError = ev.target[`${sectionName}_has_error`].checked;
        const errorDescription = sectionHasError ? ev.target[`${sectionName}_error_description`].value : '';
        const errorDate = sectionHasError ? ev.target[`${sectionName}_error_date`].value : '';
        return { sectionName, hasError: sectionHasError, description: errorDescription, date: errorDate };
      }),
      message: ev.target.message.value,
      patient_id: ev.target.patient_id.value,
      dataset_id: ev.target.dataset_id.value,
    };
    onFormSubmit({
      message: JSON.stringify(data),
      extra_data: { message_type: 'template', template_name: 'ic_data_validation' },
    });
  };

  return (
    <form onSubmit={onSubmit} className="data-validation-feedback-form">
      <header>
        <h5>Data validation feedback</h5>
        <hr />
      </header>
      <TextField
        label="Patient ID"
        id="patient_id"
        className="field-patient-id"
        description="An ID of a patient with invalid data."
        required
      />
      <TextField
        label="Dataset ID"
        id="dataset_id"
        className="field-data-id"
        description="An ID of a dataset with invalid data."
        required
      />
      <div className="sections">
        {IC_SECTIONS.map(s => (
          <SectionErrorFieldGroup sectionName={s} key={s} />
        ))}
      </div>
      <TextAreaField label="Additional message" id="message" />
      <div className="form-actions">
        <button type="button" onClick={onFormCancel} className="button button-cancel">
          Cancel
        </button>
        <button type="submit" className="button button-submit">
          Send
        </button>
      </div>
    </form>
  );
};
