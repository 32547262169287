import { scaleLinear } from 'd3-scale';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import { nodesColors } from './utils';

const height = 80;
const barHeight = 20;
const margin = {
  top: 40,
  right: 0,
  bottom: 0,
  left: 0,
};

const EmptyChart = ({ width }) => (
  <svg width={width} height={height} className="unique-included-patients">
    <rect
      x={margin.left}
      y={margin.top}
      width={width}
      height={barHeight}
      style={{ fill: '#ccc' }}
      className="bar bar-all-patients"
    />
    <text x={width / 2} y={margin.top + barHeight / 2} style={{ textAnchor: 'middle', dominantBaseline: 'middle' }}>
      No data
    </text>
  </svg>
);

const Chart = ({ container, data }) => {
  const { width } = container;
  const xScale = scaleLinear()
    .domain([0, data.included])
    .range([margin.left, width - margin.right]);
  const [allStartX, allEndX] = xScale.range();
  const uniquePatientsX = xScale(data.unique_included);

  if (!data.all_patients) {
    return <EmptyChart width={allEndX} />;
  }

  return (
    <svg width={width} height={height} className="unique-included-patients">
      <rect
        x={allStartX}
        y={margin.top}
        width={allEndX}
        height={barHeight}
        style={{ fill: nodesColors.included }}
        className="bar bar-all-patients"
      />
      <rect
        x={allStartX}
        y={margin.top}
        width={uniquePatientsX}
        height={barHeight}
        style={{ fill: '#28AC00' }}
        className="bar bar-unique-patients"
      />
      <line x1={allStartX} x2={uniquePatientsX / 2 - 45} y1={21} y2={21} stroke="black" />
      <line x1={uniquePatientsX / 2 + 45} x2={uniquePatientsX} y1={21} y2={21} stroke="black" />
      <line x1={allStartX} x2={allStartX} y1={21} y2={margin.top} stroke="black" />
      <line x1={uniquePatientsX} x2={uniquePatientsX} y1={21} y2={margin.top} stroke="black" />
      <text x={uniquePatientsX / 2} y={20} className="label">
        Unique patients
      </text>
      <text x={uniquePatientsX / 2} y={35} className="label">
        {((data.unique_included * 100) / data.included).toFixed(1)}%
      </text>
      <text x={uniquePatientsX / 2} y={margin.top + barHeight / 2 + 1} className="label label-unique-patients">
        {data.unique_included}
      </text>
      <text x={allEndX / 2} y={margin.top + barHeight + 5} className="label label-all-patients">
        {data.included}
      </text>
    </svg>
  );
};

const UniquePatients = ({ data }) => {
  return (
    <ResponsiveContainer>
      <Chart data={data} />
    </ResponsiveContainer>
  );
};

export default UniquePatients;
