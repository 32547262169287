import { v4 as uuid } from 'uuid';
import { Navigate } from 'react-router-dom';
import { getAccessToken } from './context/Tokens';
import { getAuthorizationUrl } from './api';
import Footer from 'layout/components/footer/Footer';
import idmLogo from 'img/idmc_logo.svg';

const onLoginButtonClick = e => {
  e.preventDefault();
  const state = uuid();
  const url = getAuthorizationUrl(state);
  window.location.replace(url);
};

export const LoginButton = ({ text = 'Log in', className = 'btn btn-link' }) => {
  const token = getAccessToken();

  if (token) return null;

  return (
    <button onClick={onLoginButtonClick} className={className}>
      {text}
    </button>
  );
};

const UserLogin = () => {
  const token = getAccessToken();

  if (token) return <Navigate to="/" />;

  return (
    <div className="user-login-page">
      <div className="header">
        <div className="brand">
          <img className="brand__logo" src={idmLogo} alt="IDM Logo" />
          <h1 className="brand__name">Health-Alert</h1>
        </div>
      </div>
      <div className="main">
        <div className="login-block">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="login-message">
                  <h2>Welcome to the Health-Alert</h2>
                  <p>In order to proceed, you need to log in.</p>
                  <LoginButton className="btn btn-primary btn-lg d-block w-100 my-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Footer />
      </div>
    </div>
  );
};

export default UserLogin;
