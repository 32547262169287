import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import toastr from 'toastr';
import Modal from 'react-modal';
import { LoggerProvider, useLogger } from 'logger/LoggerProvider';
import UnexpectedErrorFallback from 'sharedComponents/unexpectedErrorFallback/UnexpctedErrorFallback';
import RootRoutes from './routes/RootRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'toastr/build/toastr.min.css';
import 'toastr/build/toastr.min';
import './index.scss';

import { SessionProvider } from 'user/context/Session';
import ReactQueryProvider from 'api/ReactQueryProvider';

toastr.options.closeButton = true;
toastr.options.progressBar = true;
toastr.options.positionClass = 'toast-bottom-right';

Modal.setAppElement('#root');

const App = () => {
  const logger = useLogger()('portal_login');
  const errorHandler = error => {
    if (error.isAxiosError) {
      const err = error.toJSON();
      logger.error(`${err.name}: ${err.message}. BaseURL: ${err.config.baseURL}. URL: ${err.config.url}.`);
    } else {
      logger.error(error.toString());
    }
  };

  return (
    <ErrorBoundary FallbackComponent={UnexpectedErrorFallback} onError={errorHandler}>
      <SessionProvider>
        <ReactQueryProvider>
          <RootRoutes />
        </ReactQueryProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <LoggerProvider>
    <App />
  </LoggerProvider>
);
