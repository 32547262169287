import { Link } from 'react-router-dom';
import './NotFound.scss';

export const notFoundConfig = {
  PATH: '/404',
};

const NotFound = () => {
  return (
    <div className="page-not-found">
      <div className="page-not-found__status-code">404</div>
      <div className="page-not-found__message message">
        <h2 className="message__title">Page not found!</h2>
        <p className="message__description">
          Sorry, but we cannot find the requested page. <br />
          Make sure that you use correct page address or try to <Link to="/">start from home</Link>.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
