import { scaleSequential } from 'd3-scale';
import { interpolateRainbow } from 'd3-scale-chromatic';
import orderBy from 'lodash/orderBy';
import { useTimeframeContext } from '../TimeframeContext';

export const getPercent = (value, total, toFixed = 1) => (total > 0 ? ((value * 100) / total).toFixed(toFixed) : 0);

export const useIncludedPatientsData = () => {
  const { data } = useTimeframeContext();
  const total = Object.entries(data.included).reduce((sum, [, patients]) => sum + patients, 0);
  const unique = data.unique_included.length;
  const unique_percents = getPercent(unique, total);

  return { total, unique, unique_percents };
};

export const useIncludedSpecialtiesData = () => {
  const { data } = useTimeframeContext();
  const specialties = Object.entries(data.included_specialties).map(([specialty, dates]) => [
    specialty,
    Object.entries(dates).reduce((sum, [, value]) => sum + value, 0),
  ]);
  const total = specialties.reduce((sum, [, value]) => sum + value, 0);
  const specialtiesWithPercents = specialties.map((s, i) => [
    ...s,
    s[1] > 0 ? Number(((s[1] * 100) / total).toPrecision(3)).toFixed(2) : 0,
  ]);
  const orderedByValue = orderBy(specialtiesWithPercents, ['1', '0'], ['desc', 'asc']);
  const colorDomain = [0, orderedByValue.length];
  const colorScale = scaleSequential(interpolateRainbow).domain(colorDomain);
  const withColor = orderedByValue.map((s, i) => [...s, colorScale(i)]);

  return { specialties: withColor, total_patients: total };
};
