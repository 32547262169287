import React from 'react';
import MessageBox from 'sharedComponents/messageBox/MessageBox';
import Spinner from 'sharedComponents/bootstrap/Spinner';
import './IndicatorWithText.scss';

const IndicatorWithText = ({ text, color = 'primary', type = 'grow' }) => {
  return (
    <MessageBox>
      <div className="indicator-wrapper">
        <div className="indicator-symbol">
          <Spinner color={color} type={type} />
        </div>
        <span className="indicator-text">{text}</span>
      </div>
    </MessageBox>
  );
};

export default IndicatorWithText;
