import { useTimeframeContext } from '../TimeframeContext';
import { formatDate } from './utils';

const PeriodLabel = () => {
  const { timeframe } = useTimeframeContext();

  return `${formatDate(timeframe.start)} - ${formatDate(timeframe.end)}`;
};

export default PeriodLabel;
