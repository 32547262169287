import { nodesColors, calculateStepsColumnLayout } from './utils';

const NodeBadge = ({ id, children }) => {
  return (
    <span className={`node-badge badge-${id}`}>
      <span className="color-mark" style={{ backgroundColor: nodesColors[id] }}></span>
      <span>{children}</span>
    </span>
  );
};

const Steps = () => {
  const steps = [
    {
      title: 'Step 1',
      text: (
        <div>
          On this step <NodeBadge id="all_patients">All patients</NodeBadge> without long enough admission period are
          filtered out. They go to <NodeBadge id="excluded">Excluded</NodeBadge> group and do not count at later steps.
        </div>
      ),
    },
    {
      title: 'Step 2',
      text: (
        <div>
          Next, the IDM Alert takes all <NodeBadge id="included">Included</NodeBadge> patients, runs special algorithms
          and detects <NodeBadge id="suspicious">Suspicious</NodeBadge> patients. Other patients go to{' '}
          <NodeBadge id="not_suspicious">Not suspicious</NodeBadge> group and do not count at later steps.
        </div>
      ),
    },
    {
      title: 'Step 3',
      text: (
        <div>
          On this step all <NodeBadge id="suspicious">Suspicious</NodeBadge> patients go through process of manual
          review by specialists. As a result, treatment strategy can be changed for some patients. These patients go to
          <NodeBadge id="changed_strategy">Changed strategy</NodeBadge> group. Other patients go to{' '}
          <NodeBadge id="not_changed_strategy">Not changed strategy</NodeBadge> group.
        </div>
      ),
    },
  ];

  const layout = calculateStepsColumnLayout();

  return (
    <div className="column steps">
      {steps.map((s, i) => (
        <div className={`step step-${i + 1}`} key={s.title} style={{ top: layout[i].offset, height: layout[i].height }}>
          <h5 className="title">{s.title}</h5>
          {s.text}
        </div>
      ))}
    </div>
  );
};

export default Steps;
