import React from 'react';
import packageJson from '../../../../package.json';
import './Footer.scss';

const Footer = () => {
  return (
    <div className="row">
      <div className="col footer">
        <div>Health-Alert v{packageJson.version}</div>
        <div>Copyright VMC b.v. © {new Date().getFullYear()}. All rights reserved.</div>
      </div>
    </div>
  );
};

export default Footer;
