import { createContext, useContext, useState } from 'react';

const InFocusContext = createContext();

export const useInFocus = () => {
  const context = useContext(InFocusContext);
  if (context === undefined) {
    throw new Error('useDataInFrame can be used only within InFocusProvider.');
  }
  return context;
};

const InFocusProvider = ({ children }) => {
  const [inFocus, setFocus] = useState('');

  return <InFocusContext.Provider value={[inFocus, setFocus]}>{children}</InFocusContext.Provider>;
};

export default InFocusProvider;
