import { createContext } from 'react';
import { createUseContextHook } from 'utility/utils';
import { useParams, useLocation } from 'react-router-dom';
import snakeCase from 'lodash/snakeCase';
import { useApplication, useHasAccess } from 'user/context/Session';
import { getTimeRange } from './utils';
import Options from './Options';
import Charts from './charts/Charts';
import './GoogleAnalytics.scss';
import LegacyAnalyticsBanner from "./LegacyAnalyticsBanner";

const DateRangeErrors = ({ errors }) => {
  if (errors?.length < 1) return null;

  return (
    <div className="row">
      {errors.map(err => (
        <div className="col-12" key={snakeCase(err.title)}>
          <div className="alert alert-warning" role="alert">
            <h5 className="alert-heading">{err.title}</h5>
            <p className="mb-0">{err.message}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const GoogleAnalyticsContext = createContext();

export const useGoogleAnalyticsSettings = createUseContextHook(
  GoogleAnalyticsContext,
  'useGoogleAnalyticsContext can be used only within GoogleAnalyticsProvider.'
);

const GoogleAnalytics = () => {
  const { analyticsApp } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [dateRange, dateRangeErrors] = getTimeRange(params);
  const applications = useApplication();
  const applicationName = `google_${snakeCase(analyticsApp)}`;
  const access = useHasAccess(applicationName);

  if (!access) {
    throw new Response('Not found', { status: 404 });
  }

  const { title, property_id, domain } = applications[applicationName];
  const contextValue = { propertyId: property_id, dateRange, domain };

  return (
    <GoogleAnalyticsContext.Provider value={contextValue}>
      <div className="row">
        <div className="col">
          <h2>{title}</h2>
          <LegacyAnalyticsBanner domain={domain} />
        </div>
      </div>

      <DateRangeErrors errors={dateRangeErrors} />

      <div className="options-group row">
        <div className="col-12 col-lg-6">
          <Options />
        </div>
        <div className="col-12 col-lg-6 text-lg-right m-0 d-flex justify-content-lg-end align-content-lg-around mt-3 mt-lg-0 align-items-lg-end">
          <h4 className="m-0">{domain.split(',').shift().trim()}</h4>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Charts />
        </div>
      </div>
    </GoogleAnalyticsContext.Provider>
  );
};

export default GoogleAnalytics;
