const Banner = ({ domain }) => {
  const domains = domain.split(', ');

  return [
    <div className='alert alert-primary'>
      <p className='m-0'>
        From May 25, 2023, we switched to the new Google Analytics. If you need data before May 25, 2023, you can
        click <b><a href={'https://' + domains[0] + '/legacy-analytics'} target='_blank'
                    rel="noreferrer">HERE</a></b> and download the old data.</p>
    </div>
  ];
}

export default Banner;
