import { useState, useEffect } from 'react';

const defaultOptions = { execute: true, initialLoading: false };

export const useRequest = (apiCall, apiParams = [], options = {}) => {
  const opts = { ...defaultOptions, ...options };
  const [state, setState] = useState({ isLoading: opts.initialLoading, error: null, data: null });

  useEffect(() => {
    if (!opts.execute) return;

    if (!opts.initialLoading) {
      setState({ isLoading: true, error: null, data: null });
    }

    apiCall(...apiParams)
      .then(res => {
        setState({ isLoading: false, error: null, data: res.data });
      })
      .catch(err => {
        setState({ isLoading: false, error: err, data: null });
      });
  }, [...apiParams]); //eslint-disable-line

  return state;
};
