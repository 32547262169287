import React from 'react';
import Logo from './components/Logo';
import UserMenu from 'user/userMenu/UserMenu';
import ApplicationSelectButton from 'applications/ApplicationSelectButton';
import './header.scss';

const Header = () => (
  <div className="portal__header">
    <Logo />
    <UserMenu />
    <ApplicationSelectButton />
  </div>
);

export default Header;
