import { format } from 'date-fns';
import { format as d3Format } from 'd3-format';

export const timestampToFormatedDate = timestamp => {
  const date = new Date(timestamp * 1000);
  return format(date, 'dd MMM yyyy HH:mm');
};

export const formatFileSize = (bytes = 0) => {
  const fmt = d3Format('.3~s');
  return `${fmt(bytes)}B`;
};
