import { createContext } from 'react';
import { createUseContextHook } from 'utility/utils';
import { getAccessToken } from 'user/context/Session';
import { useRequest } from 'utility/hooks';
import { getMySpaces } from 'user/api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const MySpacesContext = createContext();

export const useMySpaces = createUseContextHook(
  MySpacesContext,
  'useMySpaces can be used only within MySpacesProvider.'
);

export const MySpacesProvider = ({ children }) => {
  const token = getAccessToken();
  const { isLoading, data } = useRequest(getMySpaces, [], { execute: !!token, initialLoading: !!token });

  if (isLoading) return <IndicatorWithText text="Loading my available spaces..." />;

  return <MySpacesContext.Provider value={data}>{children}</MySpacesContext.Provider>;
};
