import { parse, isWithinInterval } from 'date-fns';

const isInTimeframe =
  timeframe =>
  ([date]) => {
    const datasetDate = parse(date, 'yyyy-MM-dd', new Date());
    return isWithinInterval(datasetDate, timeframe);
  };

const filterItems = (data, timeframe) => Object.fromEntries(Object.entries(data).filter(isInTimeframe(timeframe)));

export const filterDataByDate = (data, timeframe) => {
  if (!timeframe.start || !timeframe.end) {
    return data;
  }

  let unique_included = {};
  for (const date in data.unique_included) {
    if (isInTimeframe(timeframe)([date])) {
      unique_included = { ...unique_included, ...data.unique_included[date] };
    }
  }

  return {
    all_patients: filterItems(data.all_patients, timeframe),
    included: filterItems(data.included, timeframe),
    unique_included: Object.keys(unique_included),
    excluded: filterItems(data.excluded, timeframe),
    not_suspicious: filterItems(data.not_suspicious, timeframe),
    suspicious: filterItems(data.suspicious, timeframe),
    not_changed_strategy: filterItems(data.not_changed_strategy, timeframe),
    changed_strategy: filterItems(data.changed_strategy, timeframe),
    types_of_alerts: Object.fromEntries(
      Object.entries(data.types_of_alerts).map(([key, values]) => [key, filterItems(values, timeframe)])
    ),
    types_of_adjustment: Object.fromEntries(
      Object.entries(data.types_of_adjustment).map(([key, values]) => [key, filterItems(values, timeframe)])
    ),
    included_specialties: Object.fromEntries(
      Object.entries(data.included_specialties).map(([key, values]) => [key, filterItems(values, timeframe)])
    ),
  };
};

const sumValues = (sum, [_, value]) => sum + value;

export const getReducedData = data => {
  return {
    all_patients: Object.entries(data.all_patients).reduce(sumValues, 0),
    included: Object.entries(data.included).reduce(sumValues, 0),
    unique_included: data.unique_included.length,
    excluded: Object.entries(data.excluded).reduce(sumValues, 0),
    not_suspicious: Object.entries(data.not_suspicious).reduce(sumValues, 0),
    suspicious: Object.entries(data.suspicious).reduce(sumValues, 0),
    not_changed_strategy: Object.entries(data.not_changed_strategy).reduce(sumValues, 0),
    changed_strategy: Object.entries(data.changed_strategy).reduce(sumValues, 0),
    types_of_alerts: Object.fromEntries(
      Object.entries(data.types_of_alerts).map(([key, values]) => [key, Object.entries(values).reduce(sumValues, 0)])
    ),
    types_of_adjustment: Object.fromEntries(
      Object.entries(data.types_of_adjustment).map(([key, values]) => [
        key,
        Object.entries(values).reduce(sumValues, 0),
      ])
    ),
  };
};
