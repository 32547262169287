import { useUser } from 'user/context/Session';
import Dropdown from './Dropdown';
import './UserMenu.scss';

const UserMenu = () => {
  const { name } = useUser();

  if (!name) return null;

  return (
    <div className="portal__header__user-menu">
      <Dropdown username={name} />
    </div>
  );
};

export default UserMenu;
