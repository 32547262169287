import { createContext } from 'react';
import { createUseContextHook } from 'utility/utils';
import { getAccessToken } from 'user/context/Session';
import { useRequest } from 'utility/hooks';
import { getApplicationContext } from 'user/api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const ApplicationContext = createContext();

export const useApplication = createUseContextHook(
  ApplicationContext,
  'useApplication can be used only within ApplicationProvider.'
);

export const ApplicationProvider = ({ children }) => {
  const token = getAccessToken();
  const { isLoading, data } = useRequest(getApplicationContext, [], { execute: !!token, initialLoading: !!token });

  if (isLoading) return <IndicatorWithText text="Loading applications settings..." />;

  return <ApplicationContext.Provider value={data}>{children}</ApplicationContext.Provider>;
};
