import { TokensProvider } from './Tokens';
import { UserProvider } from './User';
import { SpacesProvider } from './spaces/Spaces';

export const SessionProvider = ({ children }) => (
  <TokensProvider>
    <UserProvider>
      <SpacesProvider>{children}</SpacesProvider>
    </UserProvider>
  </TokensProvider>
);

export { getAccessToken } from './Tokens';
export {
  useUser,
  useHasAccess,
  useIsAnonymous,
  useRoutesFilterByAccess,
  useAppPermissions,
  useHasPermission,
} from './User';
export { useActiveSpace, useActiveSpaceInfo, useSpaceAppearance, useApplication, useMySpaces } from './spaces/Spaces';
