import min from 'lodash/min';
import { parse, format, addDays } from 'date-fns';

export const inputDateFormat = 'yyyy-MM-dd';
export const outputDateFormat = 'd MMM yyyy';

export const parseDate = dateStr => parse(dateStr, inputDateFormat, new Date());

export const formatDate = date => format(date, outputDateFormat);

export const getTimeframeDates = data => {
  const dates = Object.keys(data.all_patients);
  const start = parseDate(min(dates));
  return {
    domain: [addDays(start, -5), new Date()],
    dates,
  };
};
