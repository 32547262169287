import { createContext, useContext, useState } from 'react';
import { getInitialTimeframe } from './calculations';

const TimeframeContext = createContext();

export const useTimeframe = () => {
  const context = useContext(TimeframeContext);
  if (context === undefined) {
    throw new Error('useTimeframe can be used only within TimeframeProvider.');
  }
  return context;
};

const TimeframeProvider = ({ dataTimePeriod, children }) => {
  const [timeframe, setTimeframe] = useState(() => getInitialTimeframe(dataTimePeriod));

  return <TimeframeContext.Provider value={[timeframe, setTimeframe]}>{children}</TimeframeContext.Provider>;
};

export default TimeframeProvider;
