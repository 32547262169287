import { scaleTime, scaleLinear } from 'd3-scale';
import maxBy from 'lodash/maxBy';

const MIN_MAX_VALUE = 10;

export const getScales = (data, xDomain, xRange, yRange) => {
  const maxValue = maxBy(data, 'value')?.value;
  const yDomain = [0, Math.max(MIN_MAX_VALUE, maxValue + 1)];

  return [scaleTime(xDomain, xRange), scaleLinear(yDomain, yRange)];
};
