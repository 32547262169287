import { scaleLinear } from 'd3-scale';

const margin = {
  top: 40,
  right: 15,
  bottom: 0,
  left: 15,
};

const numberOfSteps = 3;
const stepHeight = 170;
const nodeHeight = 40;
const nodePadding = 8;

export const nodesColors = {
  all_patients: '#199BC3',
  excluded: '#1BBE9E',
  included: '#40CB16',
  suspicious: '#E89800',
  not_suspicious: '#E6D60F',
  not_changed_strategy: '#FFE200',
  changed_strategy: '#DE3232',
};

const nodes = [
  [{ id: 'all_patients', title: 'All patients', value: 0 }],
  [
    { id: 'excluded', title: 'Excluded', value: 0 },
    { id: 'included', title: 'Included', value: 0 },
  ],
  [
    { id: 'not_suspicious', title: 'Not suspicious', value: 0 },
    { id: 'suspicious', title: 'Suspicious', value: 0 },
  ],
  [
    { id: 'not_changed_strategy', title: 'Not changed strategy', value: 0 },
    { id: 'changed_strategy', title: 'Changed strategy', value: 0 },
  ],
];

const links = [
  { source: 'all_patients', target: 'excluded' },
  { source: 'all_patients', target: 'included' },
  { source: 'included', target: 'not_suspicious' },
  { source: 'included', target: 'suspicious' },
  { source: 'suspicious', target: 'not_changed_strategy' },
  { source: 'suspicious', target: 'changed_strategy' },
];

const setNodesValues = (nodes, data) => nodes.map(g => g.map(n => ({ ...n, value: data[n.id] })));

const calculateNodes = (data = {}, xScale) => {
  const [, end] = xScale.range();
  const nodesWithData = setNodesValues(nodes, data);
  const nodesWithLayout = nodesWithData.map((step, stepIndex) =>
    step
      .filter(node => node.value > 0)
      .map(node => ({
        ...node,
        y0: margin.top + stepHeight * stepIndex,
        y1: margin.top + stepHeight * stepIndex + nodeHeight,
        width: xScale(node.value) - margin.right,
        color: nodesColors[node.id],
      }))
      .reverse()
      .map((node, nodeIndex, nodes) => {
        return {
          ...node,
          x0: nodeIndex === 0 ? end - node.width : end - nodes[nodeIndex - 1].width - node.width - nodePadding,
        };
      })
      .map(node => ({
        ...node,
        x1: node.x0 + node.width,
      }))
      .map(node => ({
        ...node,
        midX: node.x0 + node.width / 2,
        midY: node.y0 + (node.y1 - node.y0) / 2,
      }))
  );

  return nodesWithLayout.flatMap(s => s.map(n => n));
};

const getLinkGeometry = (source, target) => {
  const p1 = [target.x0 <= source.x0 ? source.x0 : target.x0, source.y1];
  const p2 = [target.x1 < source.x1 ? target.x1 + nodePadding : target.x1, source.y1];
  const p3 = [target.x1, target.y0];
  const p4 = [target.x0, target.y0];
  const c1x = p2[0];
  const c1y = p2[1] + (p3[1] - p2[1]) / 2;
  const c2x = p3[0];
  const c2y = p2[1] + (p3[1] - p2[1]) / 2;
  const c3x = p4[0];
  const c3y = p1[1] + (p4[1] - p1[1]) / 2;
  const c4x = p1[0];
  const c4y = p1[1] + (p4[1] - p1[1]) / 2;

  return {
    path: `M ${p1.join(' ')} L ${p2.join(' ')} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${p3.join(' ')} L ${p4.join(
      ' '
    )} C ${c3x} ${c3y}, ${c4x} ${c4y}, ${p1.join(' ')}`,
    sourceMidX: p1[0] + (p2[0] - p1[0]) / 2,
    sourceWidth: p2[0] - p1[0],
  };
};

const calculateLinks = nodes => {
  const linksResult = links
    .map(link => {
      const source = nodes.find(node => node.id === link.source);
      const target = nodes.find(node => node.id === link.target);
      return { ...link, source, target };
    })
    .filter(l => l.source && l.target)
    .map(l => {
      return { ...l, id: `${l.source.id}_to_${l.target.id}`, geometry: getLinkGeometry(l.source, l.target) };
    });

  return linksResult;
};

const calculateStepLines = width =>
  Array(numberOfSteps)
    .fill(1)
    .map((_, i) => ({
      x1: 0,
      x2: width,
      y1: margin.top + nodeHeight + stepHeight * i + 0.5,
      y2: margin.top + nodeHeight + stepHeight * i + 0.5,
    }));

export const calculateWaterfallLayout = (data, width) => {
  const xScale = scaleLinear()
    .domain([0, data.all_patients])
    .range([margin.left, width - margin.right]);

  const height = stepHeight * numberOfSteps + nodeHeight + margin.top + margin.bottom;
  const nodes = calculateNodes(data, xScale);
  const links = calculateLinks(nodes);
  const lines = calculateStepLines(width);

  return { height, nodes, links, lines };
};

export const calculateStepsColumnLayout = () => {
  const layout = Array(numberOfSteps)
    .fill(1)
    .map((_, i) => ({ offset: margin.top + nodeHeight + stepHeight * i, height: stepHeight }));

  return layout;
};

export const getGradientSettings = () => {
  return [
    {
      id: 'all_patients_to_excluded',
      from: nodesColors.all_patients,
      to: nodesColors.excluded,
    },
    {
      id: 'all_patients_to_included',
      from: nodesColors.all_patients,
      to: nodesColors.included,
    },
    {
      id: 'included_to_suspicious',
      from: nodesColors.included,
      to: nodesColors.suspicious,
    },
    {
      id: 'included_to_not_suspicious',
      from: nodesColors.included,
      to: nodesColors.not_suspicious,
    },
    {
      id: 'suspicious_to_not_changed_strategy',
      from: nodesColors.suspicious,
      to: nodesColors.not_changed_strategy,
    },
    {
      id: 'suspicious_to_changed_strategy',
      from: nodesColors.suspicious,
      to: nodesColors.changed_strategy,
    },
  ];
};
