import Datepickers from './Datepickers';
import QuickPeriods from './QuickPeriods';

// To be used later. Need to polish it.
// eslint-disable-next-line
const AdvancedPeriods = () => (
  <details>
    <summary>Advanced date selection</summary>
    <hr className="mt-0 mb-2" />
    <div className="selectors advanced-selectors">
      <Datepickers />
    </div>
    <hr className="mt-2 mb-0" />
  </details>
);

const PeriodSelector = () => {
  return (
    <div className="period-selectors">
      <div className="selectors simple-selectors">
        <QuickPeriods />
      </div>
    </div>
  );
};

export default PeriodSelector;
