import { dataOrPlaceholder } from 'orders/utils';

const PersonalInfo = ({ data }) => {
  if (!data) return null;

  const { name, date_of_birth, gender, initials, children_name, spouse } = data;

  return (
    <table className="table table-bordered caption-top">
      <caption>Personal information of patient</caption>
      <thead>
        <tr>
          <th>Name</th>
          <th>Date of birth</th>
          <th>Gender</th>
          <th>Initials</th>
          <th>First name</th>
          <th>Married name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dataOrPlaceholder(name)}</td>
          <td>{dataOrPlaceholder(date_of_birth)}</td>
          <td>{dataOrPlaceholder(gender)}</td>
          <td>{dataOrPlaceholder(initials)}</td>
          <td>{dataOrPlaceholder(children_name)}</td>
          <td>{dataOrPlaceholder(spouse)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PersonalInfo;
