import axios from 'axios';

const { REACT_APP_OAUTH_API, REACT_APP_PORTAL_BACKEND_API, REACT_APP_HEALTH_DRIVE_API } = process.env;

export const oauth = axios.create({
  baseURL: REACT_APP_OAUTH_API,
});

export const backend = axios.create({
  baseURL: REACT_APP_PORTAL_BACKEND_API,
});

export const healthDrive = axios.create({
  baseURL: REACT_APP_HEALTH_DRIVE_API,
});
