const TopTable = ({ rows = [] }) => (
  <div className="top-items">
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Views</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.title}</td>
            <td>{item.views}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default TopTable;
