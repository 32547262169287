import { Link } from 'react-router-dom';
import { useIsAnonymous } from 'user/context/Session';

const ApplicationSelectButton = () => {
  const isAnonymous = useIsAnonymous();

  if (isAnonymous) return null;

  const size = { width: 30, height: 30 };
  const circleRadius = 3;

  return (
    <div className="applications-button">
      <Link to="/applications" className="apps-link">
        <svg className="apps-icon" width={size.width} height={size.height} viewBox={`0 0 ${size.width} ${size.height}`}>
          <circle cx="5" cy="5" r={circleRadius} />
          <circle cx="15" cy="5" r={circleRadius} />
          <circle cx="25" cy="5" r={circleRadius} />
          <circle cx="5" cy="15" r={circleRadius} />
          <circle cx="15" cy="15" r={circleRadius} />
          <circle cx="25" cy="15" r={circleRadius} />
          <circle cx="5" cy="25" r={circleRadius} />
          <circle cx="15" cy="25" r={circleRadius} />
          <circle cx="25" cy="25" r={circleRadius} />
        </svg>
      </Link>
    </div>
  );
};

export default ApplicationSelectButton;
