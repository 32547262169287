import toastr from 'toastr';
import { getDownloadLink } from 'healthDrive/api';

const CopyPermanentLink = ({ item, text = '', title = '', className = '' }) => {
  const { downloadToken } = item;

  const onButtonClick = e => {
    e.preventDefault();
    const link = getDownloadLink(downloadToken);
    navigator.clipboard.writeText(link).then(
      () => {
        toastr.success('Link copied to clipboard.');
      },
      () => {
        toastr.error('Link can not be copied to clipboard.');
      }
    );
  };

  return (
    <button onClick={onButtonClick} title={title} className={className}>
      <i className="fas fa-copy" /> {text}
    </button>
  );
};

export default CopyPermanentLink;
