import { Link, useRouteError } from 'react-router-dom';
import './RouteError.scss';

const errors = {
  404: {
    title: 'Page not found',
    description: (
      <>
        Oops! Looks like the page you requested is not found. Do not worry, it happens sometimes. Make sure you use a
        correct URL address and try again.
      </>
    ),
  },
  403: {
    title: 'Access denied',
    description: (
      <>
        Oops! Looks like you have no access to the requested page. If you are sure that you should have access to the
        page, contact with us by email <a href="mailto:support@idmalert.com">support@idmalert.com</a>.
      </>
    ),
  },
};

const RouteError = () => {
  const errorData = useRouteError();

  if (!(errorData.status in errors)) {
    return (
      <div className="route-error-wrapper">
        <section className="route-error">
          <h1>Oops!</h1>
          <div>Sorry, an unexpected error has occurred.</div>
          <div className="status-text">{errorData.statusText}</div>
          <footer>
            <Link to="..">Go back</Link> | <Link to="/">Go home</Link>
          </footer>
        </section>
      </div>
    );
  }

  const error = errors[errorData.status];

  return (
    <div className="route-error-wrapper">
      <section className="route-error">
        <h1>{error.title}</h1>
        <div>{error.description}</div>
        <footer>
          <Link to=".." relative="path">
            Go back
          </Link>{' '}
          | <Link to="/">Go home</Link>
        </footer>
      </section>
    </div>
  );
};

export default RouteError;
