import { healthDrive } from 'api/clients';
import { objectToFormData } from 'utility/utils';

export const listDirectory = path => {
  return healthDrive.get(`?type=list&path=${path}`);
};

export const createDirectory = (path, name) => {
  const [data, contentTypeHeader] = objectToFormData({ path, name });

  return healthDrive.post('/', data, { headers: { ...contentTypeHeader } });
};

export const removeDirectory = path => healthDrive.delete(`?path=${path}`);

export const uploadFile = (file, path, onUploadProgress = () => {}) => {
  const [data, contentTypeHeader] = objectToFormData({ path, file });

  return healthDrive.post('/', data, { headers: { ...contentTypeHeader }, onUploadProgress });
};

export const deleteFile = path => {
  return healthDrive.delete(`?path=${path}`);
};

export const getDownloadToken = (path, isPermanent = 0) =>
  healthDrive.get(`?type=link&path=${path}&isPermanent=${isPermanent}`);

export const deleteDownloadToken = token => healthDrive.delete(`?token=${token}`);

export const getDownloadLink = token => `${process.env.REACT_APP_HEALTH_DRIVE_API}?type=download&token=${token}`;

export const getPreviewLink = token => `${process.env.REACT_APP_HEALTH_DRIVE_API}?type=preview&token=${token}`;

export const getDownloadTokens = () =>
  healthDrive.get(`${process.env.REACT_APP_HEALTH_DRIVE_API}?type=list-download-tokens`);
