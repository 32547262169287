import { Navigate } from 'react-router-dom';
import { createContext, useState } from 'react';
import { createUseContextHook } from 'utility/utils';
import { useIsAnonymous } from 'user/context/Session';
import { useRequest } from 'utility/hooks';
import { getApplications } from './api';
import SpaceList from './SpaceList';
import Sidebar from './Sidebar';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';
import ErrorMessage from 'sharedComponents/errorMessage/ErrorMessage';
import './applications.scss';

const ApplicationFocusContext = createContext();

export const useApplicationFocus = createUseContextHook(
  ApplicationFocusContext,
  'useApplicationFocus can be used only within ApplicationFocusProvider.'
);

const Applications = () => {
  const isAnonymous = useIsAnonymous();
  const { isLoading, error, data: spaces } = useRequest(getApplications, [], { execute: !isAnonymous });
  const [applicationInFocus, setApplicationFocus] = useState(null);
  const [isSpaceSwitching, setSpaceSwitching] = useState(false);

  if (isLoading) {
    return <IndicatorWithText text="Loading applications..." />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (isAnonymous) return <Navigate to="/" />;

  const alteredSpaces = spaces?.map(space => ({
    ...space,
    applications: space.applications.map(application => ({
      ...application,
      spaceId: space.id,
      spaceUuid: space.uuid,
    })),
  }));

  const contextValue = { applicationInFocus, setApplicationFocus, isSpaceSwitching, setSpaceSwitching };

  if (isSpaceSwitching) return <IndicatorWithText text="Preparing an application..." />;

  return (
    <ApplicationFocusContext.Provider value={contextValue}>
      <div className="applications">
        <div className="main--wrapper flex-grow-1">
          <SpaceList spaces={alteredSpaces || []} />
          <Sidebar />
        </div>
      </div>
    </ApplicationFocusContext.Provider>
  );
};

export default Applications;
