import { useState } from 'react';
import { useSendFeedback } from '../hooks';
import { FormSelector } from './FormSelector';

const SendingOverlay = ({ isSending }) =>
  isSending ? (
    <div className="sending-overlay">
      <span>Sending...</span>
    </div>
  ) : null;

const SuccessMessage = () => (
  <div className="feedback-form-wrapper">
    <div className="alert alert-success mb-0">Feedback has been sent.</div>
  </div>
);

const ErrorMessage = ({ error = '', setError }) =>
  error ? (
    <div className="feedback-form-wrapper mt-3">
      <div className="alert alert-danger">
        Something went wrong. We could not send your feedback.
        <button
          className="btn btn-danger"
          onClick={() => {
            setError(null);
          }}
        >
          &times;
        </button>
      </div>
    </div>
  ) : null;

export const FormController = () => {
  const feedback = useSendFeedback();
  const [formConfig, setFormConfig] = useState(null);

  if (!formConfig) {
    return <FormSelector setForm={setFormConfig} />;
  }

  if (feedback.isSuccess) {
    return <SuccessMessage />;
  }

  const onFormSubmit = data => {
    feedback.send(data);
  };

  const onFormCancel = () => {
    setFormConfig(null);
  };

  return (
    <main className="feedback-form-wrapper">
      <SendingOverlay isSending={feedback.isSending} />
      <formConfig.component onFormSubmit={onFormSubmit} onFormCancel={onFormCancel} />
      <ErrorMessage error={feedback.error} setError={feedback.setError} />
    </main>
  );
};
