import { Navigate, Outlet } from 'react-router-dom';
import UserToken from './UserToken';
import './user.scss';

export const userRoutes = [
  {
    index: true,
    element: <Navigate to="/" />,
  },
  {
    path: 'token',
    element: <UserToken />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const User = () => <Outlet />;

export default User;
