import { createContext } from 'react';
import { useLogger } from 'logger/LoggerProvider';
import { createUseContextHook } from 'utility/utils';
import { getAccessToken, resetTokens } from './Tokens';
import { useRequest } from 'utility/hooks';
import { getUserInfo } from '../api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const anonymous = {
  sub: 0,
  name: 'Anonymous',
  preferred_username: 'Anonymous',
  zoneinfo: 'Europe/Amsterdam',
  uuid: '00000000-0000-0000-0000-000000000000',
  my_groups: {
    space: null,
    teams: [],
  },
  access: {},
  guidelines_login: [],
  data: {
    spaces: {},
    active_space: {
      info: { name: '', uuid: '00000000-0000-0000-0000-000000000000' },
      teams: [],
      access: {},
    },
  },
};

const UserContext = createContext();

export const useUser = createUseContextHook(UserContext, 'useUser can be used only within UserProvider.');

export const useHasAccess = accessName => {
  const user = useUser();
  const access = user.data?.active_space?.access;
  if (!access) return false;
  return accessName in access;
};

export const useAppPermissions = appName => {
  const user = useUser();
  const access = user.data?.active_space?.access;
  if (!access) return false;
  if (!(appName in access)) return false;

  return access[appName];
};

export const useHasPermission = (appName, permission) => {
  const user = useUser();
  const access = user.data?.active_space?.access;
  if (!access) return false;
  if (!(appName in access)) return false;
  const appPermissions = access[appName];
  return permission in appPermissions;
};

export const useIsAnonymous = () => {
  const user = useUser();
  return user.sub < 1;
};

export const useRoutesFilterByAccess = routes => {
  const user = useUser();
  const access = user.data?.active_space?.access;

  if (!access) return routes;

  return routes.filter(route => {
    if (!route.access?.length) return true;
    return route.access.some(acc => acc in access);
  });
};

export const UserProvider = ({ children }) => {
  const logger = useLogger()('portal_login');
  const token = getAccessToken();
  const { isLoading, error, data } = useRequest(getUserInfo, [], { execute: !!token, initialLoading: !!token });

  let contextValue = data || anonymous;

  if (token && isLoading) return <IndicatorWithText text="Loading user data..." />;

  if (error) {
    const { response } = error;

    if (response.data.error === 'invalid_token') {
      logger.info(
        `Invalid access token was used to access "${response.config.url}" endpoint.
         User session on the Health-Alert was reseted.
         No extra actions required.`
      );
    } else {
      logger.error(
        `UserProvider.
         Code: ${response.status},
         message: ${response.statusText},
         url: ${response.config.baseURL}${response.config.url}.`
      );
    }

    resetTokens();
    contextValue = anonymous;
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
