import { Link, useParams } from 'react-router-dom';
import { useHasPermission } from 'user/context/Session';
import { useFeedbackItem } from './hooks';
import FeedbackFieldStatus from './FeedbackFieldStatus';
import FeedbackMessage from './FeedbackMessage';
import FeedbackItemImage from './FeedbackItemImage';

const FeedbackItem = () => {
  const isUserHasPermission = useHasPermission('feedback', 'manager');

  if (!isUserHasPermission) {
    throw new Response('Forbidden', { status: 403, statusText: 'Forbidden' });
  }

  const { feedbackId } = useParams();
  const { isLoading, error, feedback } = useFeedbackItem(feedbackId);

  if (isLoading) {
    return (
      <div className="feedback-item">
        <Link to="..">{'<'} Back to list</Link>
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="feedback-item">
        <Link to="..">{'<'} Back to list</Link>
        <div>Error.</div>
      </div>
    );
  }

  return (
    <div className="feedback-item">
      <div className="row">
        <div className="col">
          <Link to="..">{'<'} Back to list</Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>
            #{feedback.id[0].value} - {feedback.user[0].value}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <FeedbackFieldStatus status={feedback.status[0].value} />
          <div>
            <strong>Created</strong>: {feedback.created[0].value}
          </div>
          {feedback.changed[0].value !== feedback.created[0].value && (
            <div>
              <strong>Changed</strong>: {feedback.changed[0].value}
            </div>
          )}
          <div>
            <strong>Sent from URL</strong>: {feedback.url[0].value}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <FeedbackMessage
          message={feedback.message[0].value}
          extra_data={feedback.extra_data[0]?.value ? JSON.parse(feedback.extra_data[0]?.value) : {}}
        />
      </div>
      {feedback.attachments.length > 0 && (
        <div className="row mt-3">
          <div className="col-12">
            <h3>Attachments</h3>
          </div>
          <div className="col-12">
            {feedback.attachments.map(image => (
              <FeedbackItemImage image={image} key={image.target_uuid} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackItem;
