import { useState, useEffect } from 'react';
import { oauth, backend, healthDrive } from 'api/clients';
import { refreshToken } from '../api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const TOKENS_STORAGE_KEY = 'tokens';
const TOKENS_EXPIRES_SHIFT_MS = 60000;
const DEFAULT_TOKENS = {
  access_token: '',
  expires_in: 0,
  token_type: '',
  scope: '',
  refresh_token: '',
  id_token: '',
  iat: 0,
  exp: 0,
};

const SKIP_ROUTES_AUTHORIZATION = ['/api/v1/analytics', '/api/v1/token'];

export const getNowTimestamp = () => Math.floor(Date.now() / 1000);

export const resetTokens = () => {
  localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(DEFAULT_TOKENS));
};

export const saveTokens = tokens => {
  localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(tokens));
};

export const getAccessToken = () => {
  const tokens = JSON.parse(localStorage.getItem(TOKENS_STORAGE_KEY));
  return tokens.access_token;
};

export const getRefreshToken = () => {
  const tokens = JSON.parse(localStorage.getItem(TOKENS_STORAGE_KEY));
  return tokens.refresh_token;
};

export const addTimestamps = tokens => {
  const iat = getNowTimestamp();
  const exp = iat + tokens.expires_in;

  return { ...tokens, iat, exp };
};

export const isTokensExpired = tokens => {
  const now = getNowTimestamp();
  const exp = tokens.exp || tokens.iat + tokens.expires_in;

  return now > exp;
};

const refreshTokensHandler = () => {
  const token = getRefreshToken();
  if (!token) {
    resetTokens();
    window.location.reload();
  }

  refreshToken(token)
    .then(res => {
      const tokens = addTimestamps(res.data);
      localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(tokens));
    })
    .catch(e => {
      resetTokens();
      window.location.reload();
    });
};

const setTokensWatcher = tokens => {
  const now = getNowTimestamp();
  const firstRefreshMs = (tokens.exp - now) * 1000 - TOKENS_EXPIRES_SHIFT_MS;
  const refreshIntervalMs = tokens.expires_in * 1000 - TOKENS_EXPIRES_SHIFT_MS;

  let tokensRefreshInterval = null;
  const firstRefreshTimeout = setTimeout(() => {
    refreshTokensHandler();
    tokensRefreshInterval = setInterval(refreshTokensHandler, refreshIntervalMs);
  }, firstRefreshMs);

  return { firstRefreshTimeout, tokensRefreshInterval };
};

const axiosRequestInterceptorHandler = config => {
  const token = getAccessToken();
  if (!token) return config;
  if (SKIP_ROUTES_AUTHORIZATION.includes(config.url)) return config;
  return {
    ...config,
    headers: { ...config.headers, common: { ...config.headers.common, Authorization: `Bearer ${token}` } },
  };
};

const axiosResponseRejectedInterceptor = error => {
  const statusCodesToCheck = [401, 403];
  const tokens = JSON.parse(localStorage.getItem(TOKENS_STORAGE_KEY));

  if (!statusCodesToCheck.includes(error.response.status)) {
    return Promise.reject(error);
  }

  if (!tokens.access_token) {
    return Promise.reject(error);
  }

  const isExpired = isTokensExpired(tokens);

  if (!isExpired) {
    return Promise.reject(error);
  }

  if (!tokens.refresh_token) {
    resetTokens();
  }

  document.location.reload();
  return Promise.reject(error);
};

export const TokensProvider = ({ children }) => {
  const [isInitiated, setInitiated] = useState(false);
  const [isRefreshingTokens, setRefreshingTokens] = useState(false);

  useEffect(() => {
    if (isRefreshingTokens) return;

    const persistedTokens = JSON.parse(localStorage.getItem(TOKENS_STORAGE_KEY));

    if (!persistedTokens?.access_token) {
      resetTokens();
      setInitiated(true);
      return;
    }

    const isExpired = isTokensExpired(persistedTokens);

    if (isExpired) {
      const refreshToken = persistedTokens.refresh_token;
      if (!refreshToken) {
        resetTokens();
        setInitiated(true);
        return;
      }

      setRefreshingTokens(true);
      return;
    }

    const { firstRefreshTimeout, tokensRefreshInterval } = setTokensWatcher(persistedTokens);
    const oauthRequestInterceptor = oauth.interceptors.request.use(axiosRequestInterceptorHandler);
    const backendRequestInterceptor = backend.interceptors.request.use(axiosRequestInterceptorHandler);
    const healthDriveRequestInterceptor = healthDrive.interceptors.request.use(axiosRequestInterceptorHandler);
    const oauthResponseInterceptor = oauth.interceptors.response.use(res => res, axiosResponseRejectedInterceptor);
    const backendResponseInterceptor = backend.interceptors.response.use(res => res, axiosResponseRejectedInterceptor);
    const healthDriveResponseInterceptor = healthDrive.interceptors.response.use(
      res => res,
      axiosResponseRejectedInterceptor
    );
    setInitiated(true);

    return () => {
      oauth.interceptors.request.eject(oauthRequestInterceptor);
      backend.interceptors.request.eject(backendRequestInterceptor);
      healthDrive.interceptors.request.eject(healthDriveRequestInterceptor);
      oauth.interceptors.response.eject(oauthResponseInterceptor);
      backend.interceptors.response.eject(backendResponseInterceptor);
      healthDrive.interceptors.response.eject(healthDriveResponseInterceptor);
      clearTimeout(firstRefreshTimeout);
      clearInterval(tokensRefreshInterval);
    };
  }, [isRefreshingTokens]);

  useEffect(() => {
    if (!isRefreshingTokens) return;

    const persistedTokens = JSON.parse(localStorage.getItem(TOKENS_STORAGE_KEY));

    refreshToken(persistedTokens.refresh_token)
      .then(res => {
        const tokens = addTimestamps(res.data);
        localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(tokens));
        setRefreshingTokens(false);
      })
      .catch(e => {
        resetTokens();
        setRefreshingTokens(false);
      });
  }, [isRefreshingTokens]);

  if (isRefreshingTokens) return <IndicatorWithText text="Refreshing tokens..." />;
  if (!isInitiated) return <IndicatorWithText text="Initiating..." />;

  return children;
};
