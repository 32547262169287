import { useFeedbackImage } from './hooks';

const FeedbackItemImage = ({ image }) => {
  const { isLoading, error, data } = useFeedbackImage(image.url);

  if (isLoading) {
    return (
      <div className="feedback-image-wrapper">
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="feedback-image-wrapper">
        <div>Error.</div>
      </div>
    );
  }

  return (
    <div className="feedback-image-wrapper">
      <img src={URL.createObjectURL(data.data)} alt={image.name} className="img-thumbnail" />
    </div>
  );
};

export default FeedbackItemImage;
