import groupBy from 'lodash/groupBy';
import ChartContainer from './ChartContainer';
import { getMostViewedInCategories } from 'analytics/googleAnalytics/api';

const prepareData = data => {
  const rows = data.rows;
  const grouped = groupBy(rows, 'dimensionValues.1.value');

  return Object.values(grouped).map(c => ({
    category: c[0].dimensionValues[1].value,
    title: c[0].dimensionValues[2].value,
    views: c[0].metricValues[0].value,
  }));
};

const Chart = ({ data }) => {
  return (
    <div className="top-one-in-category">
      {data.map((item, index) => (
        <div className="category-group" key={index}>
          <div className="category-title">Top in {item.category}:</div>
          <div>
            <span className="views-count badge bg-warning">{item.views}</span> views -
            <span className="item-name"> {item.title}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const MostViewedInCategories = () => {
  return (
    <ChartContainer title="Most viewed in categories" api={getMostViewedInCategories} dataAlter={prepareData}>
      <Chart />
    </ChartContainer>
  );
};

export default MostViewedInCategories;
