import UniquePatients from './UniquePatients';
import AlertTypes from './AlertTypes';
import TreatmentChangesTypes from './TreatmentChangesTypes';
import { calculateStepsColumnLayout } from './utils';

const StepDetails = ({ data }) => {
  const layout = calculateStepsColumnLayout();

  return (
    <div className="column step-details">
      <h5 className="column-title" style={{ position: 'absolute', top: '50px', left: '15px' }}>
        Step details
      </h5>
      <div className="details details-1" style={{ top: layout[0].offset, height: layout[0].height }}>
        <div style={{ overflowY: 'auto', maxHeight: '65px' }}>
          A patient can be therefore selected more than one time. IDM Alert is able to distinguish between number of
          patients included and the number of unique patients included.
        </div>
        <UniquePatients data={data} />
      </div>
      <div className="details details-2" style={{ top: layout[1].offset, height: layout[1].height }}>
        <AlertTypes data={data.types_of_alerts} />
      </div>
      <div className="details details-3" style={{ top: layout[2].offset, height: layout[2].height }}>
        <TreatmentChangesTypes data={data.types_of_adjustment} />
      </div>
    </div>
  );
};

export default StepDetails;
