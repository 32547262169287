import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getFeedbackItems, getFeedbackItem, getFeedbackImage, changeFeedbackStatus } from './api';

export const useFeedbackItems = () => {
  const query = useQuery({
    queryKey: ['feedbackItems'],
    queryFn: () => getFeedbackItems(),
  });
  return { ...query, data: query.data?.data ?? [] };
};

export const useFeedbackItem = id => {
  const query = useQuery({
    queryKey: ['feedbackItem', id],
    queryFn: () => getFeedbackItem(id),
  });
  return { ...query, feedback: query.data?.data };
};

export const useFeedbackImage = url => {
  const query = useQuery({
    queryKey: ['feedbackImage', url],
    queryFn: () => getFeedbackImage(url),
  });

  return query;
};

export const useStatusMutation = (id, status) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: data => changeFeedbackStatus(data.id, data.status),
    onSuccess: (_, vars) => {
      client.invalidateQueries({ queryKey: ['feedbackItem', vars.id] });
    },
  });

  return mutation;
};
