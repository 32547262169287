const PlainTextMessage = ({ message = '' }) => (
  <>
    <div className="col-12">
      <h3>Message</h3>
    </div>
    <div className="col-6">
      <div className="feedback-message">{message}</div>
    </div>
  </>
);

const DataValidationMessage = ({ message }) => {
  const feedback = JSON.parse(message);

  return (
    <>
      <div className="col-12">
        <h3>Data validation feedback</h3>
      </div>
      <div className="col-12">
        <div className="feedback-message">
          <div className="patient-id">
            Patient ID: <strong>{feedback.patient_id}</strong>
          </div>
          <div className="dataset-id">
            Dataset ID: <strong>{feedback.dataset_id}</strong>
          </div>
          <div className="sections">
            <table className="table table-striped table-hover w-auto">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Section</th>
                  <th>Date</th>
                  <th>Error description</th>
                </tr>
              </thead>
              <tbody>
                {feedback.sections.map(section => (
                  <tr key={section.sectionName}>
                    <td>
                      {section.hasError ? (
                        <i className="text-danger fas fa-times-circle" />
                      ) : (
                        <i className="text-success fas fa-check-circle" />
                      )}
                    </td>
                    <td>
                      <strong>{section.sectionName}</strong>
                    </td>
                    <td>{section.date}</td>
                    <td>{section.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {feedback.message.length > 0 && (
            <div className="message">
              <h5>Message</h5>
              <div>{feedback.message}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const FeedbackMessage = ({ message = '', extra_data = {} }) => {
  const messageType = extra_data.message_type || 'plain_text';

  if (messageType === 'template') {
    switch (extra_data.template_name) {
      case 'ic_data_validation':
        return <DataValidationMessage message={message} />;
      default:
        return <PlainTextMessage message={message} />;
    }
  }

  return <PlainTextMessage message={message} />;
};

export default FeedbackMessage;
