import { Fragment, useEffect, useRef } from 'react';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import { select } from 'd3-selection';
import { scaleLinear, scaleBand } from 'd3-scale';
import { axisLeft, axisBottom } from 'd3-axis';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import { nodesColors } from './utils';

const margin = {
  top: 20,
  right: 25,
  bottom: 20,
  left: 130,
};

const getScales = (data, width, height) => {
  const xDomain = [minBy(data, '1')[1], maxBy(data, '1')[1]];
  const xRange = [margin.left, width - margin.right];
  const xScale = scaleLinear(xDomain, xRange).nice();

  const yDomain = orderBy(data, '1', 'desc').map(i => i[0]);
  const yRange = [margin.top, height - margin.bottom];
  const yScale = scaleBand(yDomain, yRange).padding(0.3);

  return { xScale, yScale };
};

const XAxis = ({ scale, offsetTop = 0 }) => {
  const gRef = useRef();

  useEffect(() => {
    if (!gRef) return;
    const axis = axisBottom(scale).ticks(5);
    select(gRef.current).call(axis).attr('transform', `translate(0 ${offsetTop})`);
  }, [gRef, offsetTop, scale]);

  return <g className="x-axis" ref={gRef} />;
};

const YAxis = ({ scale, offsetLeft = 0 }) => {
  const gRef = useRef();

  useEffect(() => {
    if (!gRef) return;
    select(gRef.current).call(axisLeft(scale)).attr('transform', `translate(${offsetLeft}, 0)`);
  }, [gRef, offsetLeft, scale]);

  return <g className="y-axis" ref={gRef} />;
};

const ChartBase = ({ width, height, title, children, isNoData = true }) => {
  return (
    <svg width={width} height={height}>
      <text
        x={width / 2}
        y={0}
        style={{ textAnchor: 'middle', dominantBaseline: 'hanging', fontSize: '12px', fontWeight: 'bold' }}
      >
        {title}
      </text>
      {isNoData ? (
        <text
          x={width / 2}
          y={height / 2}
          style={{ textAnchor: 'middle', dominantBaseline: 'hanging', fontSize: '12px' }}
        >
          No data
        </text>
      ) : (
        <>{children}</>
      )}
    </svg>
  );
};

const Chart = ({ container, data }) => {
  const { width, height } = container;
  const types = Object.entries(data);
  const { xScale, yScale } = getScales(types, width, height);

  return (
    <ChartBase
      width={width}
      height={height}
      title="Types of alerts that cause suspicions"
      isNoData={types.every(([_, value]) => value === 0)}
    >
      <XAxis scale={xScale} offsetTop={height - margin.bottom} />
      <YAxis scale={yScale} offsetLeft={margin.left} />
      <g className="bars">
        {types.map(([type, value]) => (
          <Fragment key={type}>
            <rect
              x={margin.left + 2}
              y={yScale(type)}
              width={xScale(value) - margin.left - 2}
              height={yScale.bandwidth()}
              style={{ fill: nodesColors.suspicious }}
            >
              <title>
                {type} - {value}
              </title>
            </rect>
            <text
              x={xScale(value) + 3}
              y={yScale(type) + yScale.bandwidth() / 2}
              style={{ dominantBaseline: 'middle', fontSize: `${yScale.bandwidth()}px`, fontWeight: 'bold' }}
            >
              {value}
            </text>
          </Fragment>
        ))}
      </g>
    </ChartBase>
  );
};

const AlertTypes = ({ data }) => {
  return (
    <ResponsiveContainer>
      <Chart data={data} />
    </ResponsiveContainer>
  );
};

export default AlertTypes;
