import React from 'react';
import './MessageBox.scss';

const MessageBox = ({ children, className }) => {
  const classes = ['message-box-container'];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <div className="message-box-content">{children}</div>
    </div>
  );
};

export default MessageBox;
