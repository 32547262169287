import { createContext } from 'react';
import { createUseContextHook } from 'utility/utils';
import { getAccessToken } from 'user/context/Session';
import { useRequest } from 'utility/hooks';
import { getSpaceAppearance } from 'user/api';
import IndicatorWithText from 'sharedComponents/indicatorWithText/IndicatorWithText';

const SpaceAppearanceContext = createContext();

export const useSpaceAppearance = createUseContextHook(
  SpaceAppearanceContext,
  'useSpaceAppearance can be used only within SpaceAppearanceProvider.'
);

export const SpaceAppearanceProvider = ({ children }) => {
  const token = getAccessToken();
  const { isLoading, data } = useRequest(getSpaceAppearance, [], { execute: !!token, initialLoading: !!token });

  if (isLoading) return <IndicatorWithText text="Loading space appearance..." />;

  return <SpaceAppearanceContext.Provider value={data}>{children}</SpaceAppearanceContext.Provider>;
};
