import { dataOrPlaceholder } from 'orders/utils';

const Location = ({ data }) => {
  if (!data) return null;

  const { address, residence, zip_code, csn } = data;

  return (
    <table className="table table-bordered caption-top">
      <caption>Location information</caption>
      <thead>
        <tr>
          <th>Address</th>
          <th>Residence</th>
          <th>Post code</th>
          <th>CSN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dataOrPlaceholder(address)}</td>
          <td>{dataOrPlaceholder(residence)}</td>
          <td>{dataOrPlaceholder(zip_code)}</td>
          <td>{dataOrPlaceholder(csn)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Location;
