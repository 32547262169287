import { line, curveMonotoneX } from 'd3-shape';
import { format } from 'date-fns';
import ChartContainer from '../ChartContainer';
import ResponsiveContainer from 'charts/responsiveContainer/ResponsiveContainer';
import { getTotalViewsDynamic } from 'analytics/googleAnalytics/api';
import { parseData, getScales } from './calculations';
import Axis from 'charts/axis/Axis';
import './viewsDynamics.scss';

const height = 200;
const margin = {
  top: 10,
  rigth: 30,
  bottom: 20,
  left: 40,
};
const pointRadius = 2;

const XGrid = ({ scale }) => (
  <g className="x-grid">
    {scale.ticks().map((tick, index, ticks) => {
      const markWidth = index === ticks.length - 1 ? scale.range()[1] - scale(tick) : scale(ticks[1]) - scale(ticks[0]);
      return (
        <rect
          className="x-mark"
          x={scale(tick)}
          y={margin.top}
          width={markWidth}
          height={height - margin.bottom - margin.top}
          key={tick}
        />
      );
    })}
  </g>
);

const YGrid = ({ scale, width }) => (
  <g className="y-grid">
    {scale.ticks().map(tick => (
      <rect className="y-mark" x={margin.left} y={scale(tick)} width={width - margin.left} height="1" key={tick} />
    ))}
  </g>
);

const Axes = ({ xScale, yScale }) => {
  return (
    <g className="axes">
      <g className="grid">
        <XGrid scale={xScale} />
        <YGrid scale={yScale} width={xScale.range()[1]} />
      </g>
      <Axis position="left" scale={yScale} offset={{ left: margin.left - 3 }} />
      <Axis position="bottom" scale={xScale} offset={{ top: height - margin.bottom }} />
    </g>
  );
};

const Line = ({ views, xScale, yScale }) => {
  const lineGenerator = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.value))
    .curve(curveMonotoneX);

  return <path className="line" d={lineGenerator(views)} />;
};

const Points = ({ views, xScale, yScale }) => (
  <g className="points">
    {views.map(p => (
      <circle className="point" cx={xScale(p.date)} cy={yScale(p.value)} r={pointRadius} key={p.dateStr}>
        <title>
          Date: {format(p.date, 'yyyy-MM-dd')}, views: {p.value}
        </title>
      </circle>
    ))}
  </g>
);

/**
 * Draws SVG with all chart's components.
 */
const Chart = ({ container, data }) => {
  const { width } = container;
  const xRange = [margin.left, width - margin.rigth];
  const yRange = [height - margin.bottom, margin.top];
  const views = parseData(data);
  const [xScale, yScale] = getScales(views, xRange, yRange);

  return (
    <svg className="chart chart-views-dynamics" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <Axes xScale={xScale} yScale={yScale} />
      <g className="content">
        <Line views={views} xScale={xScale} yScale={yScale} />
        <Points views={views} xScale={xScale} yScale={yScale} />
      </g>
    </svg>
  );
};

/**
 * Loads data and wraps chart into ResponsiveContainer to track sizes.
 */
export const ViewsDynamics = () => {
  return (
    <ChartContainer title="Site views dynamic" api={getTotalViewsDynamic}>
      <ResponsiveContainer>
        <Chart />
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default ViewsDynamics;
