import { getDownloadToken, getDownloadLink } from 'healthDrive/api';

const DownloadFile = ({ path, className = '' }) => {
  const onButtonClick = e => {
    e.preventDefault();
    getDownloadToken(path)
      .then(res => {
        document.location.href = getDownloadLink(res.data.token);
      })
      .catch(err => {
        console.dir(err);
      });
  };

  return (
    <button onClick={onButtonClick} className={className}>
      <i className="fas fa-download" /> Download
    </button>
  );
};

export default DownloadFile;
