import { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import './tooltip.scss';

const TooltipContext = createContext();

export const useTooltip = () => {
  const context = useContext(TooltipContext);
  return context;
};

const offset = { x: 5, y: 15 };

const TooltipWrapper = ({ content, coords: { x, y } }) =>
  ReactDOM.createPortal(
    <section className="tooltip-wrapper" style={{ top: y + offset.y, left: x + offset.x }}>
      {content}
    </section>,
    document.getElementById('tooltip')
  );

const TooltipProvider = ({ children }) => {
  const [content, setContent] = useState('Hello there!');
  const [isVisible, setVisibility] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  return (
    <TooltipContext.Provider value={{ setContent, setVisibility, setCoords }}>
      {children}
      {isVisible ? <TooltipWrapper content={content} coords={coords} /> : null}
    </TooltipContext.Provider>
  );
};

export default TooltipProvider;
