import { Children, cloneElement } from 'react';
import kebabCase from 'lodash/kebabCase';
import { useRequest } from 'utility/hooks';
import { useGoogleAnalyticsSettings } from 'analytics/googleAnalytics/GoogleAnalytics';
import NoDataMessage from './NoDataMessage';

const Card = ({ title = '', children }) => (
  <div className={`card h-100 ${kebabCase(title)}`}>
    {title && (
      <div className="card-header">
        <h5 className="m-0">{title}</h5>
      </div>
    )}
    <div className="card-body">{children}</div>
  </div>
);

const ChartContainer = ({
  title = '',
  api = Promise.resolve(),
  parameters = [],
  dataAlter = data => data,
  children,
}) => {
  const { propertyId, dateRange, domain } = useGoogleAnalyticsSettings();
  const { isLoading, error, data } = useRequest(api, [propertyId, domain, dateRange, ...parameters]);

  if (isLoading)
    return (
      <Card title={title}>
        <div className="d-flex justify-content-center align-items-center h-100 my-3">
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Card>
    );

  if (error) return <Card title={title}>Error!</Card>;

  if (!data || !data.rows)
    return (
      <Card title={title}>
        <NoDataMessage />
      </Card>
    );

  return <Card title={title}>{Children.map(children, c => cloneElement(c, { data: dataAlter(data) }))}</Card>;
};

export default ChartContainer;
