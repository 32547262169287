import { createContext, useContext } from 'react';
import { useTimeframe } from './timeframe/TimeframeProvider';
import { getDataInFrame } from './calculations';

const DataInFrameContext = createContext();

export const useDataInFrame = () => {
  const context = useContext(DataInFrameContext);
  if (context === undefined) {
    throw new Error('useDataInFrame can be used only within DataInFrameProvider.');
  }
  return context;
};

const DataInFrameProvider = ({ data, children }) => {
  const [timeframe] = useTimeframe();
  const dataInFrame = getDataInFrame(data, timeframe);

  return <DataInFrameContext.Provider value={dataInFrame}>{children}</DataInFrameContext.Provider>;
};

export default DataInFrameProvider;
