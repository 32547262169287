import { format, sub, parseISO, isValid, isAfter } from 'date-fns';

export const sumAllValues = (sum, cur) => sum + Number(cur.metricValues[0].value);

export const getMetricsSum = items => items.reduce(sumAllValues, 0);

export const formatDate = date => format(date, 'yyyy-MM-dd');

export const getTimeRange = params => {
  // Get the "start" URL parameter.
  const startDateParam = params.get('start');
  // Get the "end" URL parameter.
  const endDateParam = params.get('end');
  const errors = [];

  const now = new Date();
  let end = parseISO(endDateParam);
  let start = parseISO(startDateParam);

  if (!isValid(end)) {
    end = sub(now, { days: 1 });

    // Show error message only if "end" URL parameter is present but not valid.
    // If case when the parameter is omitted, we do not need to show error message.
    if (endDateParam !== null) {
      errors.push({
        title: 'Invalid "end" date parameter format',
        message: `The "end" URL parameter contains invalid date "${endDateParam}". Date "${formatDate(
          end
        )}" was used instead.`,
      });
    }
  }

  if (isAfter(end, now)) {
    end = sub(now, { days: 1 });
    errors.push({
      title: 'Invalid "end" date parameter',
      message: `The "end" URL parameter contains a date that is in the future.
       The "end" date can not be greater than the current date.
       The "${formatDate(end)}" date was used instead.`,
    });
  }

  if (!isValid(start)) {
    start = sub(end, { weeks: 1 });

    // Same as with the "end" parameter.
    if (startDateParam !== null) {
      errors.push({
        title: 'Invalid "start" parameter format',
        message: `The "start" URL parameter contains invalid date "${startDateParam}". Date "${formatDate(
          start
        )}" was used instead.`,
      });
    }
  }

  if (isAfter(start, end)) {
    errors.push({
      title: 'Incorrect start-end boundaries',
      message: `The "start" URL parameter contains a date that is greater than the "end" date URL parameter.
       They were automaticaly swapped.`,
    });
    [start, end] = [end, start];
  }

  return [{ startDate: start, endDate: end }, errors];
};
