import kebabCase from 'lodash/kebabCase';
import sortBy from 'lodash/sortBy';
import classnames from 'classnames';
import ResponsiveContainer from 'idmReports/chartParts/responsiveContainer/responsiveContainer';
import { useTimeframe } from '../timeframe/TimeframeProvider';
import { useDataInFrame } from '../DataInFrameProvider';
import { useInFocus } from '../InFocusProvider';
import { getScales } from './calculations';
import Axis from 'idmReports/chartParts/axis/Axis';
import Legend from 'idmReports/chartParts/legend/Legend';
import Tooltip from './Tooltip';
import { getDurationLength } from '../calculations';
import { useTooltip } from 'idmReports/tooltip/Tooltip';
import './ordersDynamics.scss';

const height = 300;
const margin = {
  top: 15,
  right: 15,
  bottom: 30,
  left: 30,
};

const Chart = ({ container }) => {
  const [timeframe] = useTimeframe();
  const data = useDataInFrame();
  const [inFocus] = useInFocus();
  const { setContent, setVisibility, setCoords } = useTooltip();
  const { width } = container;
  const xDomain = [timeframe.start, timeframe.end];
  const xRange = [margin.left, width - margin.right];
  const yRange = [height - margin.bottom, margin.top];
  const [xScale, yScale] = getScales(data, xDomain, xRange, yRange);

  const dataWithCoords = data.map(r => {
    const [groups] = r.groups.reduce(
      ([items, offset], item) => [[...items, { ...item, valueOffset: offset }], offset + item.value],
      [[], 0]
    );

    return {
      ...r,
      groups,
    };
  });

  const dayWidth = getDurationLength(xScale, { days: 1 });
  const barWidth = dayWidth - dayWidth * 0.3;

  const mouseOverHandler = d => e => {
    setContent(<Tooltip data={d} />);
    setCoords({ x: e.pageX, y: e.pageY });
    setVisibility(true);
  };

  const mouseMoveHandler = e => {
    setCoords({ x: e.pageX, y: e.pageY });
  };

  const mouseOutHandler = () => {
    setVisibility(false);
  };

  return (
    <svg height={height} width={width} className="chart">
      <Axis position="left" scale={yScale} offset={{ left: margin.left }} />
      <Axis position="bottom" scale={xScale} offset={{ top: height - margin.bottom }} />
      <g className="content" clipPath="url('#chartContent')">
        {dataWithCoords.map(d => (
          <g className="bar" key={d.key}>
            {sortBy(d.groups, 'key').map(g => (
              <rect
                key={g.key}
                x={xScale(d.date) - barWidth / 2}
                y={yScale(g.value + g.valueOffset)}
                width={barWidth}
                height={height - margin.bottom - yScale(g.value)}
                className={classnames(kebabCase(g.key), {
                  'in-focus': inFocus === g.key,
                  'not-in-focus': inFocus && inFocus !== g.key,
                })}
                onMouseOver={mouseOverHandler({ ...g, dateStr: d.dateStr })}
                onMouseMove={mouseMoveHandler}
                onMouseOut={mouseOutHandler}
              />
            ))}
          </g>
        ))}
      </g>
    </svg>
  );
};

const OrdersDynamics = () => {
  return (
    <section className="section orders-dynamics mt-5">
      <header>
        <h4>Orders dynamics</h4>
        <p>Shows orders number dynamics over time.</p>
      </header>
      <main>
        <ResponsiveContainer>
          <Chart />
        </ResponsiveContainer>
        <Legend />
      </main>
    </section>
  );
};

export default OrdersDynamics;
