import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStatusMutation } from './hooks';

const StatusView = ({ value, setEditMode }) => (
  <div onDoubleClick={setEditMode}>
    <strong>Status</strong>: {value}
  </div>
);

const StatusEdit = ({ defaultValue, setViewMode }) => {
  const { feedbackId } = useParams();
  const statusMutation = useStatusMutation();

  const onSubmit = ev => {
    ev.preventDefault();
    statusMutation.mutate({ id: feedbackId, status: ev.target.status.value });
    setViewMode();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="input-group">
        <span className="input-group-text">Status</span>
        <select
          name="status"
          id="status"
          className="form-select"
          defaultValue={defaultValue}
          disabled={statusMutation.isLoading}
        >
          <option value="new">New</option>
          <option value="pending">Pending</option>
          <option value="closed">Closed</option>
        </select>
        <button onClick={setViewMode} type="button" className="btn btn-warning" disabled={statusMutation.isLoading}>
          <i className="fas fa-times" />
        </button>
        <button type="submit" className="btn btn-success" disabled={statusMutation.isLoading}>
          <i className="fas fa-check" />
        </button>
      </div>
    </form>
  );
};

const FeedbackFieldStatus = ({ status }) => {
  const [isEditable, setEditable] = useState(false);

  return isEditable ? (
    <StatusEdit
      setViewMode={() => {
        setEditable(false);
      }}
      defaultValue={status}
    />
  ) : (
    <StatusView
      setEditMode={() => {
        setEditable(true);
      }}
      value={status}
    />
  );
};

export default FeedbackFieldStatus;
