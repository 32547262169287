import { Link } from 'react-router-dom';
import { useSpaceAppearance } from 'user/context/Session';
import DefaultLogo from 'img/idmc_logo.png';

const Logo = () => {
  const spaceAppearance = useSpaceAppearance();
  const logoUrl = spaceAppearance?.logo_path;
  const LOGO_BASE_URL = process.env.REACT_APP_OAUTH_API;

  return (
    <div className="org-logo--wrapper">
      <Link to="/">
        <img src={logoUrl ? `${LOGO_BASE_URL}${logoUrl}` : DefaultLogo} className="org-logo" alt="logo" />
        <span className="site-name">Health-Alert</span>
      </Link>
    </div>
  );
};

export default Logo;
